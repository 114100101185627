<template>
  <div class="pagelist" :style="pagelistStyleObj">
    <Swiper v-if="pagesInSamePageList"
            :centeredSlides="true"
            :initialSlide="swiperOption.initialSlide"
            :threshold="5"
            :style="{height: '100%'}"
            @swiper="onSwiper" @slideChange="cbSlideChange">
      <SwiperSlide v-for="(page, idx) in pagesInSamePageList"
                   :key="'slide-' + idx"
                   :data-hash="'slide'+idx">
        <ViewerSlideshowPage
            :key="'pg'+ page.id"
            :page="page" :pauseAllVideos="pauseAllVideos">
        </ViewerSlideshowPage>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>

    import _ from 'underscore' // findWhere, indexOf, where, debounce
    import ViewerSlideshowPage from "./ViewerSlideshowPage.vue"
    import 'swiper/swiper-bundle.css';
    import { Swiper, SwiperSlide } from 'swiper/vue'

    export default {
      name: 'ViewerSlideshow',
      components: { ViewerSlideshowPage, Swiper, SwiperSlide },
      props: {
        pagelistId: {type: Number, default: null},
        pageId: {type: Number, default: null},
        pauseAllVideos: {type: Boolean, default: false},
        suppressAnalyticsEvents: {type: Boolean, default: false},
      },
      data() {
        return {
          swiperInitialized: true,
          swiperOption: {
            updateOnWindowResize: false,  // buggy in swiper, we handle this manually in watch#viewerDimensions
            centeredSlides: true,
            initialSlide: 0,
            threshold: 5,  // fixes issue where a slight wobble of the mouse when clicking prevent "click" event
            //touchStartPreventDefault: false,  // does not fix
            //shortSwipes: false,  // does not fix, also threshold too high
            //touchEventsTarget: 'wrapper',  // does not fix, does not allow touchstart on hotspot
            //simulateTouch: false,  // true default, if true use mouse events instead of touch
            //cssMode: true,  // does not fix, does not allow touchstart on hotspot
            //preventClicksPropagation: false,  // does not fix, disables propagation during animation
            //preventClicks: false  // sort of fixes, however make another bug where click and swipe both occur!
          },
          backHistoryLengthAtMount: 0,
          swiper: null
        }
      },
      created() {
        //console.log('created', this.pagelistId, this.pageId);
        let idx = this.getIndexFromPageId();
        if (idx > -1) this.swiperOption.initialSlide = idx;
        else this.swiperOption.initialSlide = 0;
      },
      mounted() {
        //console.log('mounted', this.pagelistId, this.pageId, this.$refs);

        this.backHistoryLengthAtMount = this.viewerState.backHistory.length;
        this.cbSlideChange();
      },
      beforeUnmount() {
        this.$swiper = null;  // the component will handle it's own destruction
      },
      methods: {
        onSwiper(swiper) {
          //console.log('onSwiper', swiper)
          this.$swiper = swiper;
        },
        cbSlideChange: function() {
          if (!this.$swiper || !this.page || !this.page.pagelist_id) return;
          let pageIdx = this.$swiper.activeIndex;
          // console.log('cbSlideChange', pageIdx, this.pagesInSamePageList);
          let prevPageId = null;
          let nextPageId = null;
          if ( pageIdx > 0 ) {
            prevPageId = this.pagesInSamePageList[pageIdx - 1].id;
          }
          if ( pageIdx < this.pagesInSamePageList.length -1 ) {
            nextPageId = this.pagesInSamePageList[pageIdx + 1].id;
          }
          let pageId = this.pagesInSamePageList[pageIdx].id;

          this.$store.commit('vwSetPageInSamePagelist', {pageId: pageId});
          this.$store.commit('vwSetNextPrevPages', {nextPageId: nextPageId, prevPageId: prevPageId})
          if (!this.suppressAnalyticsEvents) this.$store.dispatch('vwRecordPageView', {pageId: pageId});
        },
        gotToSlide (idx) {
          this.$swiper.slideTo(idx, 0);
        },
        getIndexFromPageId() {
          let idx = -1;
          let page = _.findWhere(this.pagesInSamePageList, {id: this.pageId});
          if ( page ) {
            idx = _.indexOf(this.pagesInSamePageList, page);
          }
          return idx;
        }
      },

      computed: {
        options() {
          return this.$store.state.vw.options;
        },
        viewerState() {
          return this.$store.state.vw.viewerState;
        },
        scData() {
          return this.$store.state.vw.scData;
        },
        page() {
          let pg = null;
          if (this.scData && this.viewerState && this.pageId) {
            pg = _.findWhere(this.scData.page, {id: this.pageId});
          }
          return pg;
        },
        pagesInSamePageList () {
          let pages = [];
          if (this.page && this.viewerState.adHocPagelist.adHocTagUid) {
            for (let pageId of this.viewerState.adHocPagelist.adHocPageIds) {
              let found = _.findWhere(this.scData.page, {id: pageId});
              if (found) pages.push(found);
            }
          }   else if (this.page) {
            pages = _.where(this.scData.page, {pagelist_id: this.page.pagelist_id});
            pages.sort(function (a, b) {
              let aInt = Number(a.sort_order);
              let bInt = Number(b.sort_order);
              if (aInt < bInt) return -1;
              if (aInt > bInt) return 1;
              return 0;
            });
          }
          return pages;
        },
        pagelistStyleObj() {
          let presentation = this.scData.presentation;
          let scalePx = (d) => {
            return Math.round(d * this.viewerState.presScaleFactor) + 'px';
          };
          return {
            width: scalePx(presentation.width),
            height: '100%',
            position: 'absolute',
            marginLeft: this.viewerState.rootDivLeftMargin + 'px',
            zIndex: ((1000 * this.backHistoryLengthAtMount) -1) + this.options.baseZindexOffset
          }
        },
        viewerDimensions() {
          // join all dimensions togetner into a string so we can 'watch' them
          return [this.viewerState.frameEleWidth, this.viewerState.frameEleHeight, this.viewerState.presScaledWidth,
            this.viewerState.presScaledHeight, this.viewerState.presScaleFactor, this.viewerState.rootDivLeftMargin].join('-')
        }
      },

      watch: {
        viewerDimensions: _.debounce(function() {
          // swiper does not handle resize events very well so we do this ourselves
          //console.log('dims change', this.viewerDimensions, this.$swiper);
          if (this.$swiper && this.$swiper.update) this.$swiper.update();
        }, 250),
        pageId() {
          //console.log('changepageid', this.pageId);
          let idx = this.getIndexFromPageId();
          let swiperIdx = this.$swiper.activeIndex;
          if (idx > -1 && swiperIdx !== idx) this.gotToSlide(idx);
        },
        suppressAnalyticsEvents() {
          if (!this.suppressAnalyticsEvents) this.$store.dispatch('vwRecordPageView', {pageId: this.pageId});
        }
      }

    }

</script>
<style scoped>

  .swiper-slide {
    touch-action: none;
  }

</style>