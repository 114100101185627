<template>
    <teleport to=".sc-modals-anchor">
    <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="modalWasHidden"
            class="modal fade" tabindex="-1">

        <div class="modal-dialog modal-dialog-centered modal-sm" style="min-width: 20rem;">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close position-absolute pt-3 ps-2" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                    <form :autocomplete="'hs-pin-form-'+viewerState.pinModalForHotspotId" id="pin-search-no-auto-fill-please" @submit.prevent>
                        <div class="w-100 text-center">
                            <input type="password" class="form-control form-control-lg text-center m-1 ms-5 pin-size-input d-inline"
                                   ref="inputEnteredPasscode" v-model="enteredPasscode" data-lpignore="true"
                                   @keyup.enter.prevent @keyup="enteredPasscodeKeyUp"
                                   name="pin-digit-pos-1" size="4" maxlength="4"
                                   :style="pinInputStyleObject"
                                   :disabled="passcodeDisabled" placeholder="PIN"
                                   :autocomplete="'hs-pin-'+viewerState.pinModalForHotspotId" />
                            <button class="btn btn-light btn-lg" @click.prevent="backspaceClick()">
                                <ScIcon name="backspace" class="text-muted"/>
                            </button>
                        </div>
                        <div class="w-100 text-center">
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(1)">1</button>
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(2)">2</button>
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(3)">3</button>
                        </div>
                        <div class="w-100 text-center">
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(4)">4</button>
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(5)">5</button>
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(6)">6</button><br/>
                        </div>
                        <div class="w-100 text-center">
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(7)">7</button>
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(8)">8</button>
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(9)">9</button>
                        </div>
                        <div class="w-100 text-center">
                            <button class="btn btn-light btn-lg m-2 pin-size" @click.prevent="pinNumClick(0)">0</button>
                        </div>
                    </form>

                    <div v-if="showTip" class="text-center">
                        You have attempted to enter a<br/>
                        restricted area of this presentation
                    </div>
                    <div v-if="showWrongPin" class="text-center text-danger">
                        <strong>Wrong PIN.  Please try again.</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

import _ from 'underscore'; // findWhere, indexOf
import ScIcon from '../../shared/common/ScIcon.vue';


let _rememberedRealms = [];

export default {
    name: 'ViewerPinModal',
    components: {ScIcon},
    data: function() {
        return {
            showTip: true,
            showWrongPin: false,
            enteredPasscode: null,
            passcodeDisabled: true,
            loadHotspotOnHidden: false,
        };
    },
    mounted() {
        setTimeout(() => {  // this stops browser from autofilling the password field
            this.passcodeDisabled = false;
        }, 500);
    },
    methods: {
        hideModal() {
            this.$refs.scModal.$vb.modal.hide();
        },
        modalWasHidden() {
            let hotspotId = this.viewerState.pinModalForHotspotId;
            this.$store.commit('vwSetPinModalForHotspotId', {hotspotId: null});
            if (this.loadHotspotOnHidden) {
                this.$store.dispatch('vwLoadHotspotTarget', {hotspotId: hotspotId});
                this.loadHotspotOnHidden = false;
            }
        },
        submitPin: function (focusOnError) {
            // submit now
            let enteredPin = String(this.enteredPasscode);
            //console.log('submitPin', focusOnError, enteredPin, this.requiredPin);
            this.enteredPasscode = null;
            if (enteredPin === this.requiredPin) {
                _rememberedRealms.push('hs' + this.viewerState.pinModalForHotspotId);
                this.loadHotspotOnHidden = true;
                this.hideModal();

            } else {
                this.showTip = false;
                this.showWrongPin = true;
                if (focusOnError) this.focusAndSelect(this.$refs.inputPos1);
            }
        },
        pinNumClick: function (num) {
            //console.log('pinno click');
            this.showWrongPin = false;
            if (!this.enteredPasscode) this.enteredPasscode = '';
            this.enteredPasscode = this.enteredPasscode + String(num);

            if (this.enteredPasscode.length >= 4) {
                setTimeout(() => {  // slight delay so it look better on UI
                    this.submitPin(false);
                }, 250);
            }
        },
        backspaceClick: function() {
            if (this.enteredPasscode && this.enteredPasscode.length > 0) {
                this.enteredPasscode = this.enteredPasscode.substring(0, this.enteredPasscode.length -1);
            }
        },

        focusAndSelect: function(ref) {
            //console.log('focusAndSelect', ref);
            if (!ref) return;
            ref.focus();
            ref.select();
        },

        // keyboard support
        enteredPasscodeKeyUp: function() {
            //console.log('enteredPasscodeKeyUp');
            this.showWrongPin = false;
            if (this.enteredPasscode && this.enteredPasscode.length >= 4) {
                this.submitPin(true);
            }
        },
    },

    computed: {
        viewerState() {
            return this.$store.state.vw.viewerState;
        },
        scData() {
            return this.$store.state.vw.scData;
        },
        requiredPin() {
            let hotspot = _.findWhere(this.scData.hotspot, {id: this.viewerState.pinModalForHotspotId});
            if (hotspot && hotspot.passcode) return '' + hotspot.passcode;  // return a string
            return '0000';
        },
        pinInputStyleObject() {
            if (!this.showWrongPin) return {};
            return {
                border: '1px solid red'
            }
        }
    },

    // public functions

    clearRealms: function() {
        _rememberedRealms.splice(0);
    },

    isRememberedHotspot: function(hotspotId) {
        return _.indexOf(_rememberedRealms, 'hs' + hotspotId) > -1;
    },

};



</script>

<style>
    .pin-size {
        width: 3rem !important;
        height: 3rem !important;
    }
    .pin-size-input {
        width: 8rem !important;
        height: 3rem !important;
    }
</style>
