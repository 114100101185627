<template>
  <div v-if="page" class="pageScroller" :style="pageScrollerStyleObj">

    <div class="pageColorContainer" :style="pageColorStyleObj"></div>

    <div v-if="bgAnimatedResUrl" class="bgResContainer cinemagraphContainer">
      <img v-if="isAnimatedBgOfType('image')"
          :src="bgAnimatedResUrl" style="width: 100%;height: auto;"/>
      <video v-if="isAnimatedBgOfType('movie')" ref="video"
          loop muted playsinline :autoplay="!pauseAllVideos" style="width: 100%;height: auto;">
          <source type="video/mp4" :src="bgAnimatedResUrl" />
      </video>
    </div>

    <div v-if="bgResImgUrl && bgDims" class="bgResContainer bgImgContainer">
        <img :src="bgResImgUrl" :style="pageBgImgStyleObj"/>
    </div>

    <div :data-id="page.id" class="page" :style="pageStyleObj">

      <ViewerHotspot v-for="(hs, idx) in hostpostForThisPageSortedByZindex"
                     :cleanZindex="idx+1"
                     :key="'hs'+hs.id"
                     :hotspot="hs" :page="page" :topOffset="topOffset">
      </ViewerHotspot>

    </div>

  </div>
</template>


<script>

    import _ from 'underscore'; // findWhere, isNumber, sortBy, where
    import ViewerHotspot from './ViewerHotspot.vue';
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';

    export default {
      name: 'ViewerSlideshowPage',
      components: {ViewerHotspot},
      props: {
        page: {type: Object, default: null},
        pauseAllVideos: {type: Boolean, default: false}
      },
      data() {
        return {
          backHistoryLengthAtMount: 0,
          bgAnimatedResUrl: null,
        }
      },
      mounted() {
        this.backHistoryLengthAtMount = this.viewerState.backHistory.length;
        this.videoUrlPopulate();
      },
      methods: {
        videoUrlPopulate() {
          this.bgAnimatedResUrl = null;
          if (this.bgAnimatedRes) {
            ViewerEmbedHandler.getResourceUrlVideo(this.bgAnimatedRes).then((url) => {
              this.bgAnimatedResUrl = url;
            }, () => { });
          }
        },
        isAnimatedBgOfType(contentType) {
          let isType= false;
          if (this.bgAnimatedRes) {
            isType = (this.bgAnimatedRes.content_type === contentType)
          }
          return isType;
        }
      },
      computed: {
        options() {
          return this.$store.state.vw.options;
        },
        viewerState() {
          return this.$store.state.vw.viewerState;
        },
        scData() {
          return this.$store.state.vw.scData;
        },
        bgRes() {
          let bg = null;
          if (this.scData && this.page && _.isNumber(this.page.background_resource_id)) {
            bg = _.findWhere(this.scData.resource, {id: this.page.background_resource_id});
          }
          return bg;
        },
        bgResImgUrl() {
          return this.bgRes ? ViewerEmbedHandler.getResourceUrl(this.bgRes) : null;
        },
        bgAnimatedRes() {
          let bg = null;
          if (this.scData && this.page && _.isNumber(this.page.page_animated_bg_id)) {
            bg = _.findWhere(this.scData.resource, {id: this.page.page_animated_bg_id});
          }
          return bg;
        },
        bgDims() {
          if (!this.bgRes) return null;
          let ratio = Math.min(this.scData.presentation.width / this.bgRes.width, 1);  // we 'fit to width' background images
          return {width: this.bgRes.width*ratio, height: this.bgRes.height*ratio };
        },

        hostpostForThisPageSortedByZindex() {
          let hostpostForThisPageSortedByZindex = [];
          if (this.page && this.page.id) {
            let hostpotsForThisPage = _.where(this.scData.hotspot, {parent_page_id: this.page.id});
            hostpostForThisPageSortedByZindex = [...hostpotsForThisPage];
            hostpostForThisPageSortedByZindex.sort((a, b) => a.zindex - b.zindex || a.id - b.id);
          }
          return hostpostForThisPageSortedByZindex;
        },

        pageHeight() {
          let presentation = this.scData.presentation;
          let pageHeight = presentation.height;
          if (this.bgRes && this.bgDims) {  // make the page height the same as the pres unless the bg is taller
            if (this.bgDims.height > presentation.height) {
              pageHeight = this.bgDims.height;
            }
          }
          //console.log('pageHeight', pageHeight);
          return pageHeight;
        },

        topOffset() {
          let scaledPageHeight = this.pageHeight * this.viewerState.presScaleFactor;
          let scaledTopOffset = (this.viewerState.frameEleHeight - scaledPageHeight) / 2;
          if (scaledTopOffset < 0) scaledTopOffset = 0;
          let unscaledTopOffset = scaledTopOffset / this.viewerState.presScaleFactor;
          //console.log('scaledTopOffset', scaledTopOffset, unscaledTopOffset);
          return unscaledTopOffset;
        },

        pageScrollerStyleObj() {
          let scaledPageHeight = this.pageHeight * this.viewerState.presScaleFactor;
          return {
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: ((1000 * this.backHistoryLengthAtMount) -1) + this.options.baseZindexOffset,
            overflow: 'hidden',
            'overflow-y': scaledPageHeight > this.viewerState.frameEleHeight ? 'auto' : 'hidden'
          }
        },

        pageStyleObj() {
          let scalePx = (d) => {
            return (d * this.viewerState.presScaleFactor) + 'px';
          };
          return {
            left: '0px', top: '0px',
            height: scalePx(this.topOffset + this.pageHeight),
            overflow: 'hidden',
            position: 'relative',
            zIndex: (1000*this.backHistoryLengthAtMount) + this.options.baseZindexOffset,
            backgroundColor: 'transparent'
          }
        },

        pageBgImgStyleObj() {
          if (!this.bgDims) return {};
          return {
            width: (this.bgDims.width * this.viewerState.presScaleFactor) + 'px',
            height: (this.bgDims.height * this.viewerState.presScaleFactor) + 'px'
          }
        },

        pageColorStyleObj() {
          let scalePx = (d) => {
            return (d * this.viewerState.presScaleFactor) + 'px';
          };
          return {
            left: '0px', top: scalePx(this.topOffset),
            height: scalePx(this.pageHeight),
            position: 'absolute', width: '100%',
            backgroundColor: this.page.page_bg_color ? this.page.page_bg_color : '#000000'
          }
        }
      },
      watch: {
        page() {
          this.videoUrlPopulate();
        },
        pauseAllVideos() {
          if (!this.$refs.video) return;
          if (this.pauseAllVideos && !this.$refs.video.paused) this.$refs.video.pause();
          if (!this.pauseAllVideos && this.$refs.video.paused) this.$refs.video.play();
        }
      }

    }

</script>

<style scoped>

  .pageScroller {
    touch-action: pan-y; /* see ViewerSlideshow .swiper-slide for where this is overriden */
  }

  .bgResContainer {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

</style>