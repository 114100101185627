<template>
    <ScLayoutNoAuth>
        <div class="mt-4">
            <form class="form" @submit.prevent>
                <div class="mb-3 form-floating" v-if="!isSuccess">
                    <input class="form-control auth_email w-100" id="auth_user_email" name="email" type="text" maxlength="512"
                               placeholder="Email" v-model="email" autofocus />
                    <label for="auth_user_email" class="text-body">Email</label>
                </div>

                <div class="controls actions text-center">
                    <button v-if="!isSuccess" @click="submitResetForm" type="submit" :disabled="isSending"
                            class="btn col-sm-8 col-sm-offset-2 btn-no-auth-submit p-2 fw-bold">
                        Reset password <ScIcon v-if="isSending" name="spinnerFW" class="ms-2"/>
                    </button>

                    <div v-if="isError" class="mt-3 alert alert-danger">{{ errMsg }}</div>
                    <div v-if="isSuccess" class="mt-3 alert alert-success">
                        Please check your inbox, we've sent an email with instructions for resetting your password
                    </div>
                </div>

                <div class="text-center mt-4 fw-normal">
                    <router-link :to="{name: 'workshop-default'}"><ScIcon name="chevronLeftFW"/> Back</router-link></div>
            </form>
        </div>
    </ScLayoutNoAuth>
</template>


<script>

    import $ from 'jquery';
    import ScLayoutNoAuth from '../global/ScLayoutNoAuth.vue';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: 'ResetPasswordForm',
        components: { ScLayoutNoAuth, ScIcon },
        data () {
            return {
                email: null,
                isError: false,
                errMsg: null,
                isSuccess: false,
                isSending: false
            }
        },
        mounted () {
            let lsEmail = null;
            try {
                if (window.localStorage) lsEmail = window.localStorage.getItem('scLastEmail');
            } catch(e) {
                // ignore
            }
            if (lsEmail) this.email = lsEmail;
        },
        methods: {
            validateEmail () {
                return ScCommonUtil.validateEmail(this.email);
            },
            submitResetForm () {
                this.isSending = true;
                if (!this.validateEmail()) {
                    this.isError = true;
                    this.errMsg = "Oops - you've entered an invalid email";
                    this.isSending = false;
                }
                else {
                    this.isError = false;
                    this.errMsg = null;

                    $.ajax({
                        method: 'post', url: '/main/default/ajax_send_reset_pwd_email',
                        data: {email: this.email}
                    }).done((data) => {

                        if (data.status === 'ok') {
                            this.isSuccess = true;

                        } else {
                            this.isError = true;
                            this.errMsg = 'Oops, we had an error' + (data.message ? ': ' + data.message : '');
                        }

                    }).always(() => {
                        this.isSending = false;

                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'sending request');
                    });

                }
            }

        }
    }

</script>
