<template>
    <div class="container-fluid" style="background-color: black; min-height: 100vh;">

        <SharedCompModal v-if="showIntroModalComp"
                         :headerImg="headerImg"
                         :resources="resources"
                         :subject="subject"
                         :sharedType="sharedType"
                         :note="note"
                         :daysRemaining="daysRemaining"
                         :sharerEmail="sharerEmail"
                         :sharerDisplayName="sharerDisplayName"
                         :scData="scData"
                         :presentationTitle="presentationTitle"
                         :presentationThumbUrl="presentationThumbUrl"
                         :presentationId="presentationId"
                         :presentationLoading="presentationLoading"
                         :workspaceId="workspaceId"
                         :userId="userId"
                         :sharedId="sharedId"
                         :recordAnalytics="recordAnalytics"
                         :openAtOptions="openAtOptions"
                         :closeBtnShown="showcaseShareEnabled && presentationTitle && !presentationLoading"
                         @modal-was-hidden="hideModalComp"
                         @open-showcase="sharedCompShowcaseClick"
        ></SharedCompModal>

        <ShowcaseViewer v-if="showcaseViewerActive" ref="showcaseViewer"
                        :destroyOnExit="false"
                        :pauseShowcaseViewingReactive="showIntroModalComp"
                        :data="scData"
                        :userOptions="showcaseViewerOptions"
                        @showcase-viewer-exit="showcaseViewerExitEvt"></ShowcaseViewer>
    </div>
</template>

<script>

    import $ from "jquery";
    import MainAppMixin from "../MainAppMixin";
    import BrowserViewerPreload from "../browser-viewer/BrowserViewerPreload";
    import ShowcaseViewer from "../../shared/viewer/ShowcaseViewer.vue";
    import constants from "../../constants";
    import SharedCompModal from "./SharedCompModal.vue";
    import ViewerUtil from "../../shared/viewer/ViewerUtil";

    export default {
        name: "SharedComp",
        mixins: [ MainAppMixin ],
        components: { ShowcaseViewer, SharedCompModal },
        props: {
            workspaceId: {type: Number, default: null},
            userId: {type: Number, default: null},
            presentationId: {type: Number, default: null},
            presentationDataUrl: {type: String, default: null},
            sharedId: {type: Number, default: null},
            sharedType: {type: String, default: 'email'},
            sharedKey: {type: String, default: null},
            sharerEmail: {type: String, default: null},
            sharerDisplayName: {type: String, default: null},
            daysRemaining: {type: Number, default: null},
            resources: {type: Array, default: null},
            subject: {type: String, default: null},
            note: {type: String, default: null},
            recordAnalytics: {type: Boolean, default: false},
            wsIsExpired: {type: Boolean, default: false},
            headerImg: {type: String, default: null},
            openAtOptions: {type: Object, default: null}
        },
        data () {
            return {
                presentationTitle: null,
                presentationThumbUrl: null,
                presentationLoading: false,
                scData: null,
                showcaseViewerActive: false,
                showcaseViewerOptions: null,
                showIntroModalComp: true
            };
        },
        mounted () {
            this.scData = null;
            this.loadShowcase();
        },
        beforeUnmount() {
            $('body').removeClass('scviewer-active');
        },
        methods: {
            loadShowcase () {
                //console.log('loadShowcase', this.presentationId, this.showcaseShareEnabled, this.presentationDataUrl);
                if (!this.presentationId || !this.presentationDataUrl) return;

                this.presentationLoading = true;
                $.ajax({
                    type: 'GET', url: this.presentationDataUrl,
                }).done((data) => {
                    if (data.export) {
                        //console.log(data)
                        this.presentationTitle = (data.export.presentation && data.export.presentation.title) ? data.export.presentation.title : null;
                        ViewerUtil.updateForAltVideoVersions(data.export);
                        this.scData = data.export;
                        this.presentationThumbUrl = data.thumb_url;

                        if (this.showcaseShareEnabled) {
                            BrowserViewerPreload.preloadNow(data.export, null).then(() => {
                                this.viewShowcase();
                            });
                        }
                    }
                }).always(() => {
                    this.presentationLoading = false;
                }).fail(() => {
                    // this state is handled in the UI when presentationTitle is null
                });
            },
            viewShowcase () {
                if (!this.scData) return;

                this.showcaseViewerOptions = {
                    workspaceId: this.workspaceId,
                    userId: this.userId,
                    recordAnalytics: this.recordAnalytics,
                    expired: this.wsIsExpired,
                    shareEnabled: false,
                    likeEnabled: false,
                    userTagsEnabled: false,
                    showcaseFormsEnabled: true,
                    sharedId: this.sharedId,
                    sharedKey: this.sharedKey,
                    workshopHeaderImg: this.headerImg,
                    enableFullTextSearch: this.recordAnalytics,  // only enabled if published version
                    openAtPageId: (this.openAtOptions) ? this.openAtOptions.open_at_page_id : null,
                    openAtAdHocPagelistPageIds: (this.openAtOptions) ? this.openAtOptions.open_at_ad_hoc_pagelist_page_ids : null
                };
                $('body').addClass('scviewer-active');
                this.showcaseViewerActive = true;

                this.showIntroModalComp = true;

            },
            sharedCompShowcaseClick() {
                if (this.$refs.showcaseViewer) {
                    this.showIntroModalComp = false;
                }
            },
            showcaseViewerExitEvt () {
                console.log('saw a showcaseViewerExit');
                this.showIntroModalComp = true;
            },
            hideModalComp() {
                this.showIntroModalComp = false;
            }
        },
        computed: {
            showcaseShareEnabled () {
                let enabled = false;
                this.resources.forEach((res) => {
                    if (res.content_type === constants.RESOURCETYPE_SHOWCASE_SHARE) enabled = true;
                });
                return enabled;
            },
        }
    }

</script>
