<template>
  <div v-if="isLoaded" >
    <div class="d-inline-block mb-2 ms-4 text-break">
      <strong class="align-middle">
        <span v-if="(pagesInSamePageList.length > 1 || isAdHocPagelist) && !isSharingRecipient">Slideshow</span>
        <span v-else-if="!isAdHocPagelist && viewerState.pageId">Slide</span> {{ slideshowName }}</strong>
      <button v-if="isTagAndCanEdit && isTagInStore" class="btn btn-link ms-2 p-0 ps-2"
              @click="editTag" v-sc-tooltip="'Sort slides in tag'"><ScIcon name="sortFWR"/></button>
      <span v-if="isTagAndCanEdit && !isTagInStore && !isSharingRecipient" class="align-middle text-muted"> (deleted)</span>
    </div>

    <div :style="thumbListStyleObj" class="sc-drawer-thumbs my-auto text-center py-3">
      <div v-for="(pg, idx) in pagesInSamePageList" :key="'thumbSlide-' + idx" class="mb-4">
        <img :src="thumbSrc(pg)" :key="'thumbImg-' + idx" alt="Thumbnail"
             @click="selectThumbItem(pg.id)"
             :ref="'pgThumbRef' + pg.id"
             :class="['sc-drawer-thumb-item', (selectedPageId === pg.id) ? 'selected-thumb' : '']" />
      </div>
    </div>
  </div>
</template>

<script>
    import _ from 'underscore'; // findWhere, where, debounce
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ScIcon from "../common/ScIcon.vue";


    export default {
      name: "ViewerSlideshowThumbnails",
      components: {ScIcon},
      props: {
        //
      },
      data () {
        return {
          isLoaded: false,
          drawerOffsetHeight: 0,
        }
      },
      mounted () {
        //console.log(this.viewerState, 'mounted');
        this.calculateThumbContainerHeight();

        window.addEventListener('resize', this.windowResized, {passive: true});
      },
      beforeUnmount () {
        window.removeEventListener('resize', this.windowResized, {passive: true});
      },
      methods: {
        windowResized: _.debounce(function() {  // when the window changes size, resize us
          //console.log('window resize');
          this.isLoaded = false;
          this.calculateThumbContainerHeight();
        }, 250),

        selectThumbItem (item) {
          this.$store.commit('vwSetPageInSamePagelist', {pageId: item});
        },
        thumbSrc (page) {
          let thumbRes = this.scData.resource[String(page.resource_pagethumb_id)];
          if (!thumbRes) return null;
          return ViewerEmbedHandler.getResourceThumbUrl(thumbRes);
        },
        calculateThumbContainerHeight () {
          let totalHeight = this.cornerTopPos + 10;
          for (let i of document.getElementsByClassName('scDrawerButtonLink')) {
            totalHeight += i.offsetHeight;
          }
          this.drawerOffsetHeight = this.viewerState.frameEleHeight - totalHeight - 70;
          this.isLoaded = true;
          this.setInitialSlide();
        },

        setInitialSlide () {
          setTimeout(() => {
            if (!this.selectedPageId) return;
            let refName = 'pgThumbRef' + this.selectedPageId;
            //console.log('setInitialSlide', refName, this.$refs[refName]);
            let el = this.$refs[refName];
            if (el && typeof el.scrollIntoView === 'function') {
              this.$refs[refName].scrollIntoView();
            }
          });
        },
        editTag () {
          this.$store.commit('viewerTagsManageTagIdUpdate', this.viewerState.adHocPagelist.adHocTagUid);
          this.$store.commit('vwManageTagsModalToggle', { manageTagsModalShown: true });
        },

      },
      computed: {
        options () {
          return this.$store.state.vw.options;
        },
        viewerState () {
          return this.$store.state.vw.viewerState;
        },
        scData () {
          return this.$store.state.vw.scData;
        },
        selectedPageId () {
          return this.viewerState.pageId
        },
        thumbListStyleObj () {
          return {
            height: this.drawerOffsetHeight  + 'px',
            overflow: 'auto'
          }
        },
        page () {
          let pg = null;
          if (this.scData && this.viewerState && this.viewerState.pageId) {
            pg = _.findWhere(this.scData.page, {id: this.viewerState.pageId});
          }
          return pg;
        },
        pagesInSamePageList () {
          let pages = [];
          if (this.page && this.viewerState.adHocPagelist.adHocTagUid) {
            for (let pageId of this.viewerState.adHocPagelist.adHocPageIds) {
              let found = _.findWhere(this.scData.page, {id: pageId});
              if (found) pages.push(found);
            }
          } else if (this.page) {
            pages = _.where(this.scData.page, {pagelist_id: this.page.pagelist_id});
            pages.sort(function (a, b) {
              let aInt = Number(a.sort_order);
              let bInt = Number(b.sort_order);
              if (aInt < bInt) return -1;
              if (aInt > bInt) return 1;
              return 0;
            });
          }
          return pages;
        },
        cornerTopPos () {
          if (!this.viewerState) return {};
          let widgetHeight = (125+15+15);
          let pageIsShown = (this.viewerState.pageId && !this.viewerState.resourceId);
          if (pageIsShown && this.viewerState.frameEleHeight > (this.viewerState.presScaledHeight + (widgetHeight*2))) {
            return ((this.viewerState.frameEleHeight - this.viewerState.presScaledHeight)/2) - widgetHeight;
          }
          return 0;
        },
        slideshowName () {
          let showName = null;
          let pagesInSamePageList = this.pagesInSamePageList;
          if (this.viewerState.adHocPagelist.adHocTagUid && this.viewerState.adHocPagelist.adHocName) {
            showName = this.viewerState.adHocPagelist.adHocName;
          } else if (pagesInSamePageList && pagesInSamePageList.length > 0 && pagesInSamePageList[0]) {
            let startName = pagesInSamePageList[0].alpha_num_name;
            if (pagesInSamePageList.length > 1) {
              let endPage = pagesInSamePageList[pagesInSamePageList.length - 1];
              if (endPage) showName = startName + ' - ' + endPage.alpha_num_name;
            }   else {
              showName = startName;
            }
          }
          return showName;
        },
        isAdHocPagelist () {
          return this.viewerState.adHocPagelist.adHocTagUid && this.viewerState.adHocPagelist.adHocPageIds.length > 0;
        },
        isSharingRecipient () {
          return (this.viewerState.adHocPagelist && this.viewerState.adHocPagelist.adHocName === "Custom Slideshow");
        },
        isTagAndCanEdit () {
          return this.isAdHocPagelist && this.viewerState.adHocPagelist.adHocTagUid;
        },
        isTagInStore () {
          return this.viewerState.adHocPagelist.adHocTagUid &&
              _.findWhere(this.$store.state.viewerTags.userTags,{ tag_uid: this.viewerState.adHocPagelist.adHocTagUid});
        },
      }
    }
</script>

<style>

  .sc-drawer-thumbs .sc-drawer-thumb-item {
    width: 180px;
    height: auto;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.2), 0 1px 0px 0px rgba(0, 0, 0, 0.19)
  }

  .sc-drawer-thumbs .sc-drawer-thumb-item.selected-thumb {
    width: 200px;
    height: auto;
    margin: -5px;
  }

  .sc-drawer-thumbs .swiper-container {
    width: 100%;
    height: 100%;
  }

  .sc-drawer-thumbs .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
</style>