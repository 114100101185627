<!--
    requirements for component:

    - enter email address on enter, comma, blur and copy & paste
    - enter multiple emails separated by ','
    - valid input: email@email.com, Test User <email@co.nz>

    - show searched preloaded addresses in dropdown via up & down keys and select highlighted item on enter
    - validate entered email addresses on adding tag (highlight invalid tags)

-->

<template>
    <div class="col p-0" :class="[limitWidth ? 'limit-width' : '']">
        <div v-if="selectedItems.length !== 0" class="mb-2">
            <span v-for="(tag, idx) in selectedItems" :key="'tag-' + idx"
                  :class="['btn btn-outline-secondary bg-secondary active border sc-tag', {error: !validateEmail(tag)}]">
                <span class="selected-contact-display-name text-truncate">{{tag}}</span>
                <span class="delete-tag text-muted float-end" @click.prevent="removeTag(idx)">&times;</span>
            </span>
        </div>

        <!-- we need this button to make the dropdown work  -->
        <div class="dropdown position-relative">
            <input ref="searchInput" type="email" aria-expanded="false"
                   name="recipient-search" tabindex="0"
                   autocomplete="off" autocorrect="off"
                   autocapitalize="off" spellcheck="false"
                   class="dropdown-toggle form-control"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   :value="searchValue"
                   @blur="blurSearch"
                   @focus="noBlur=false"
                   @input="checkForComma"
                   @keyup="searchValueUpdate"
                   @keyup.enter.prevent="selectHighlightedItem"
                   @keyup.up.prevent="highlightPrevItem"
                   @keyup.down.prevent="highlightNextItem">
            <div style="width: 0;height: 0;" ref="ddToggle" data-bs-toggle="dropdown"></div>

            <slot></slot>

            <div v-if="!disabled" class="dropdown-menu" aria-labelledby="searchInput" ref="dropdownMenu">
                <ul class="sc-ul m-0 p-0">
                    <li v-for="(item, idx) in searchedItems"
                        :key="'rl-'+idx" :ref="'rl'+idx"
                        :class="[ (idx === highlightedItemIdx ? 'highlighted':'') ]"
                        @click="addFromList(item)" @touchstart="addFromList(item)"
                        @mouseover="mouseOver(idx)">{{ displayNameAndEmail(item) }}</li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>

    import {Dropdown} from 'bootstrap';
    import _ from 'underscore'; // filter, sortBy, isUndefined, findWhere, isEmpty, clone
    import ScCommonUtil from '../common/ScCommonUtil';
    import ScNotification from '../../shared/common/ScNotification.vue';

    export default {
        name: "ScSelectEmailWithTagsAndSearch",
        emits: ['update-selected-items'],
        props: {
            items: {type: Array, default: () => []},
            placeholder: {type: String, default: 'Select an item...'},
            disabledWhileSending: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            limitWidth: {type: Boolean, default: false},
            maxItems: {type: Number, default: 0},
            defaultSelectedItems: {type: Array, default: () => []}
        },
        data () {
            return {
                selectedItems: [],
                searchValue: null,
                highlightedItemIdx: -1,
                noBlur: false
            }
        },
        mounted () {
            //console.log('ScSelectEmailWithTagsAndSearch mounted');
            this.selectedItems = this.defaultSelectedItems;
            new Dropdown(this.$refs.ddToggle);
        },
        beforeUnmount() {
            let dd = Dropdown.getInstance(this.$refs.ddToggle);
            if (dd) dd.dispose();
        },
        computed: {
            searchedItems () {
                //console.log('computed searchedItems');
                let results = _.filter(this.searchListLessSelected, (item) => {
                    if (!this.searchValue)
                        return true;
                    let string = this.displayNameAndEmail(item).toLowerCase();
                    if (!string)
                        return false;
                    return string.indexOf(this.searchValue.toLocaleLowerCase()) !== -1;
                });
                return _.sortBy(results, 'name');
            },
            searchListLessSelected: function() {
                // all items less those that are selected
                let arr = _.filter(this.items, (i) => {
                    if (this.selectedItems.indexOf(this.displayNameAndEmail(i)) > -1)
                        return false;
                    return i;
                });
                return arr;
            }
        },
        methods: {
            highlightPrevItem () {
                this.highlightedItemIdx = this.highlightedItemIdx - 1;
                if (this.highlightedItemIdx < 0)
                    this.highlightedItemIdx = 0;
                this.scrollInView();
            },
            highlightNextItem () {
                this.highlightedItemIdx = this.highlightedItemIdx + 1;
                if (this.highlightedItemIdx >= this.searchedItems.length)
                    this.highlightedItemIdx = this.searchedItems.length-1;
                this.scrollInView();
            },
            mouseOver (idx) {
                this.highlightedItemIdx = idx;
            },
            scrollInView () {
                //console.log('scrollInView');
                let container = this.$refs.dropdownMenu;
                let cHeight = container.clientHeight;
                let cTop = container.scrollTop;

                let element = this.$refs['rl' + this.highlightedItemIdx];
                if (!element) return;
                //if (element) element = element[0];
                //if (element) element = element[0];

                let eTop = element.offsetTop - container.offsetTop;
                let eBottom = eTop + element.clientHeight;

                if ((eTop < 0 && eBottom > 0 ) || (eTop > 0 && eTop <= cHeight) || (eTop > 0 && eTop >= cHeight) || (eTop === cTop))
                    element.scrollIntoView({block: 'nearest'});
            },

            blurSearch () {
                //console.log('blurSearch', this.noBlur, e.relatedTarget);
                if (this.noBlur){
                    this.noBlur = false;
                    return;
                }

                if (this.highlightedItemIdx >= 0)
                    return;
                if (this.searchValue)
                    this.addNewTag(this.searchValue);

            },
            checkForComma (e) {
                //console.log('checkForComma', e);
                if (this.maxItems > 0 && e.currentTarget && e.currentTarget.value.split(',').length > this.maxItems) {
                    ScNotification.clear();
                    ScNotification.growlErrMsg('You cannot add more than '+ this.maxItems +' recipients in a single action.');
                    return;
                }

                if (!_.isUndefined(e.data)) {
                    // paste event on android / ios
                    if (e.inputType === "insertFromPaste") {
                        this.addNewTag(e.target.value)
                    }
                    // in android keypress does not return the actual key presses, so we have to use @input and check
                    if (!e.data || e.data.length < 1 || e.data.substring(e.data.length - 1, 1) !== ',')
                        return;
                }
                else {
                    // ie doesn't support e.data
                    if (e.currentTarget.value.length < 1 || e.currentTarget.value.substr(-1) !== ',')
                        return;
                }

                if (this.searchValue) {
                    this.addNewTag(this.searchValue);
                    let dd = Dropdown.getInstance(this.$refs.searchInput);
                    if (dd) dd.hide();
                }
            },
            selectHighlightedItem () {
                //console.log('selectHighlightedItem');
                if (this.highlightedItemIdx === -1) {
                    this.addNewTag(this.searchValue);
                    return;
                }
                if (!this.searchedItems[this.highlightedItemIdx])
                    return;
                this.addFromList(this.searchedItems[this.highlightedItemIdx]);
            },
            addNewTag (tag) {
                //console.log('addNewTag', tag);
                if (tag === null)
                    return;
                if ( !_.isEmpty(tag.trim()) && tag.includes(',') ) {
                    for (let user of tag.split(',')) {
                        if(user.trim() !== "") this.addToSelectedItems(user.trim());
                    }
                }
                else if (!_.isEmpty(tag.trim())) {
                    //check if tag is in loaded items
                    let entry = this.emailInLoaded(tag);
                    if (entry) {
                        this.addFromList(entry);
                        this.clearSearchInput();
                        return;
                    }

                    if (this.selectedItems.length === 0)
                        this.addToSelectedItems(tag);

                    else {
                        let inList = _.filter(this.selectedItems, (item) => { if (item.includes(tag)) return true });
                        if (inList.length === 0)
                            this.addToSelectedItems(tag);
                    }

                }

                this.toggleDD();

                this.clearSearchInput();

            },
            addFromList (item) {
                //console.log('addFromList', item);
                this.highlightedItemIdx = -1;
                this.clearSearchInput();

                let itemDisplayName = this.displayNameAndEmail(item);
                this.addToSelectedItems(itemDisplayName);
                this.$refs.searchInput.focus();
            },
            addToSelectedItems (itemDisplayName) {
                //console.log('addToSelectedItems', itemDisplayName);
                if (this.maxItems > 0 && this.selectedItems.length >= this.maxItems) {
                    ScNotification.clear();
                    ScNotification.growlErrMsg('You cannot add more than '+ this.maxItems +' recipients in a single action.');
                    return;
                }
                let selectedItems = _.clone(this.selectedItems);
                if (!this.selectedItems.includes(itemDisplayName)) {
                    selectedItems.push(itemDisplayName);
                    this.$emit('update-selected-items', selectedItems);
                }
            },

            /** helper **/
            displayNameAndEmail (item) {
                if (!item || !item.email) return '';
                return item.name && item.name !== item.email ? item.name + ' <' + item.email + '>' : item.email;
            },
            validateEmail (email) {
                return ScCommonUtil.validateEmail(this.extractEmail(email));
            },
            extractEmail(email) {
                if (email.includes('<') && email.includes('>'))
                    email = email.slice(email.indexOf('<') + 1, email.indexOf('>'));
                return email;
            },
            emailInLoaded (email) {
                return _.findWhere(this.items, { email: this.extractEmail(email) });
            },
            clearSearchInput () {
                //console.log('clearSearchInput');
                if (this.$refs.searchInput) this.$refs.searchInput.value = '';  // fix for android, shouldn't be needed but it is!
                this.searchValue = null;
            },

            toggleDD () {
                //console.log('toggleDD');
                this.$nextTick(() => {
                    if (!this.$refs.ddToggle) return;
                    let searchValueLength = this.searchValue ? this.searchValue.length : 0;
                    let ddToggle = Dropdown.getInstance(this.$refs.ddToggle);
                    //console.log('toggleDD tick', searchValueLength, expanded);
                    if (searchValueLength === 0 && ddToggle) ddToggle.hide();
                    if (searchValueLength > 0 && ddToggle) ddToggle.show();
                    if (this.$refs.searchInput !== document.activeElement) this.noBlur = true;
                });
            },

            /** delete tag function **/
            removeTag (idx) {
                if (this.disabledWhileSending) return;
                //console.log('remove tag', idx);
                let selectedItems = _.clone(this.selectedItems);
                selectedItems.splice(idx, 1);
                this.$emit('update-selected-items', selectedItems);
            },

            searchValueUpdate: function(e) {
                //console.log('searchValueUpdate', e.target.value);
                this.searchValue = e.target.value;
            },

        },
        watch: {
            searchValue () {
                //console.log('watch searchValue');
                this.highlightedItemIdx = -1;
                this.toggleDD();
            }
        },
    }
</script>

<style scoped>

    .dropdown-menu {
        width: 100%; position: relative; margin-top: 0; padding: 0;
    }
    .sc-ul {
        list-style: none;
        cursor: pointer;
        max-height: 200px;
        overflow-y: scroll;
    }
    .sc-ul li {
        padding: 5px 10px;
    }
    .sc-ul li.highlighted {
        background-color: #eee;
    }
    .sc-tag {
        display: inline-flex;
        max-width: 100%;
        margin: 2px; padding-right: 0
    }
    .sc-tag.error {
        border: 1px solid red !important;
    }
    .delete-tag {
        display: inline-block;
        cursor: pointer;
        font-weight: bold;
        padding: 0 10px 0 6px;
    }
    .selected-contact-display-name {
        display: inline-block;
        overflow-x: hidden;
        overflow-y: visible;
    }
    .limit-width .selected-contact-display-name {
        max-width: calc(100vw - 6rem);
    }

    input[name="recipient-search"]::placeholder {
        overflow: hidden;
    }

</style>