<template>
  <teleport to=".sc-modals-anchor">
    <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="modalWasHidden"
         @vb-shown-bs-modal="modalWasShown"
         class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-bottom">
            <button class="btn-close" data-bs-dismiss="modal"></button>
            <h4 class="modal-title w-100 text-center">Sort tag
              <small v-if="!viewerState.isViewerOnline" class="badge badge-warning"
                     v-sc-tooltip="'Sorting is disabled when offline'">
                <ScIcon name="exclamationTriangleFW"/> Offline</small>
            </h4>
          </div>
          <div class="modal-body pb-5 rounded-bottom"  @click.prevent="selectClick()">
            <div class="row">
              <div class="col-auto me-auto mt-3">
                <strong class="h3 text-truncate d-block">{{tagName}}</strong>
              </div>
              <div class="col-auto ms-auto mt-3">
                <button class="btn btn-link px-2" @click.prevent="previewTag"
                        :disabled="currentTag && currentTag.tagged_pages.length===0">
                  <ScIcon name="playCircle"/> Present</button>
                <button class="btn btn-link px-2" @click.prevent="renameTag"
                        :disabled="!viewerState.isViewerOnline">
                  <ScIcon name="pencil"/> Rename</button>
                <button class="btn btn-link ps-2 pe-0" @click.prevent="deleteTag"
                        :disabled="!viewerState.isViewerOnline"
                        v-sc-tooltip="'Delete ' + tagName"><ScIcon name="trashAlt"/></button>
              </div>
            </div>

            <div v-if="pagesInTagList.length > 0" class="m-3" ref="allResultsEl">

              <div v-for="(page, idx) in pagesInTagList" :key="'thumbSlide-' + idx" class="m-2 d-inline-flex">

                <ScSimpleDraggable :isDraggable="viewerState.isViewerOnline"
                                   :listIdx="idx"
                                   :showDragOverLineOnSide="calcedNumResultDivsPerRow > 1"
                                   @has-dropped="hasDropped($event)">

                  <div :class="['shadow-sm rounded bg-white text-center thumbnail-page',
                                 page.id === selectedPageId ? 'border-primary mt-0' : '']">
                    <a href="#" @click.prevent.stop="selectClick(page)">

                      <img v-if="thumbSrc(page)" :src="thumbSrc(page)" alt="Thumbnail" class="mt-2 mx-2 mb-0"
                           :style="{ userSelect: 'none', width: (primaryLayout.thumb_width*0.83) + 'px',
                                     height: (primaryLayout.thumb_height*0.83) + 'px'}"/>

                      <span v-else
                            class="page-thumb border d-flex align-items-center justify-content-center mt-2 mx-2 mb-0"
                            :style="{width: (primaryLayout.thumb_width*0.83) + 'px', height: (primaryLayout.thumb_height*0.83) + 'px', 'background-color': '#000000'}">
                        <ScIcon name="imageMedium" class="text-white opacity-25"/>
                      </span>

                      <div v-if="page.id === selectedPageId"
                           class="row g-0 justify-content-between mx-0 py-1 px-2" style="padding-top: 2px !important">
                        <div class="col-auto">
                          <button class="btn btn-link py-0" v-sc-tooltip="'Move left'"
                                  :disabled="idx === 0 || !viewerState.isViewerOnline"
                                  @click.prevent.stop="sortTagged(page, true)"><ScIcon name="chevronLeftFW"/></button>
                        </div>
                        <div class="col-auto">
                          <button class="btn btn-link py-0" v-sc-tooltip="'Remove slide from tag'"
                                  :disabled="!viewerState.isViewerOnline"
                                  @click.prevent.stop="removeFromTagged(page)"><ScIcon name="trashAlt"/></button>
                        </div>
                        <div class="col-auto">
                          <button class="btn btn-link py-0" v-sc-tooltip="'Move right'"
                                  :disabled="idx === pagesInTagList.length -1 || !viewerState.isViewerOnline"
                                  @click.prevent.stop="sortTagged(page, false)"><ScIcon name="chevronRightFW"/></button></div>
                      </div>
                      <div v-else class="text-truncate py-1 px-2" :style="{width: (primaryLayout.thumb_width*0.83) + 'px' }">
                        <strong>{{ page.alpha_num_name }}</strong> {{ currentPageTitle(page) }}
                      </div>
                    </a>
                  </div>

                </ScSimpleDraggable>

              </div>
            </div>
            <div v-else class="text-muted text-center">
              <em>No slides</em>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
    import _ from 'underscore'; // clone, findWhere
    import ScIcon from "../common/ScIcon.vue";
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ScRenameModal2 from '../common/ScRenameModal2.vue';
    import ScConfirmModal2 from '../common/ScConfirmModal2.vue';
    import ScNotification from '../common/ScNotification.vue';
    import ScSimpleDraggable from "../../shared/common/ScSimpleDraggable.vue";
    import ScCommonUtil from "../common/ScCommonUtil";

    export default {
      name: "ViewerTagsManageModal",
      components: {ScIcon, ScSimpleDraggable},
      props: {
        //
      },
      data () {
        return {
          selectedPageId: null,
          hasChanges: false,
          calcedNumResultDivsPerRow: 0,
          previewTagOnHiddenBool: false
        }
      },
      mounted () {
        //console.log('mounted', )
        window.addEventListener('resize', this.calcNumItemsPerRow, false);
      },
      beforeUnmount() {
        window.removeEventListener('resize', this.calcNumItemsPerRow, false);
      },
      methods: {
        hideModal() {
          this.$refs.scModal.$vb.modal.hide();
        },
        modalWasShown() {
          this.calcNumItemsPerRow();
        },
        modalWasHidden() {
          this.$store.commit('vwManageTagsModalToggle', { manageTagsModalShown: false });
          if (this.hasChanges) {
            ScNotification.growlClear();
            ScNotification.growlSuccessMsg('Your changes will be active once you navigate away from this slide.');
          }
          if (this.previewTagOnHiddenBool) {
            let taggedPages = _.clone(this.currentTag.tagged_pages);
            this.$store.dispatch('vwPage', {pageId: taggedPages[0], direction: 'up', adHocPagelist: {
                adHocName: '#' + this.currentTag.tag_name, adHocPageIds: taggedPages,
                adHocTagUid: this.currentTag.tag_uid}});
            this.$store.commit('viewerTagsSelectedTagIdUpdate', this.currentTag.tag_uid);
            this.previewTagOnHiddenBool = false;
          } else {
            this.$store.commit('viewerTagsManageTagIdUpdate', null);
          }
        },
        calcNumItemsPerRow() {
          setTimeout(() => {
            this.calcedNumResultDivsPerRow = 0;
            if (this.$refs.allResultsEl) {
              // https://stackoverflow.com/questions/49043684/how-to-calculate-the-amount-of-flexbox-items-in-a-row
              const grid = Array.from(this.$refs.allResultsEl.children);
              if (grid.length > 0) {
                const baseOffset = grid[0].offsetTop;
                const breakIndex = grid.findIndex(item => item.offsetTop > baseOffset);
                this.calcedNumResultDivsPerRow = (breakIndex === -1 ? grid.length : breakIndex);
              }
            }
          }, 1);
        },
        thumbSrc (page) {
          let thumbRes = this.scData.resource[String(page.resource_pagethumb_id)];
          if (!thumbRes) return null;
          return ViewerEmbedHandler.getResourceThumbUrl(thumbRes);
        },
        currentPageTitle(page) {
          return (page.title && page.title !== 'None') ? page.title : '';
        },
        selectClick (page) {
          this.selectedPageId = (page) ? page.id: null;
        },
        removeFromTagged (p) {
          if (!this.viewerState.isViewerOnline) return;
          let tagged = [...this.currentTag.tagged_pages];
          tagged = tagged.filter(page => page !== p.id);

          ScConfirmModal2.modal('Are you sure?').then(() => {
            this.$store.dispatch('viewerTagsToggleUserTag', {
              workspaceId: this.options.workspaceId, presentationId: this.scData.presentation.id,
              value: {tag_uid: this.currentTag.tag_uid, tagged_pages: tagged}});

            ScNotification.growlSuccessMsg('Slide has been removed from tag.');
            this.hasChanges = true;
            this.selectedPageId = null;
          }, () => {});
        },
        sortTagged (p, sortUp) {
          if (!this.viewerState.isViewerOnline) return;
          // do sorting here and commit changed array
          let tagged = [...this.currentTag.tagged_pages];
          let taggedIdx = this.currentTag.tagged_pages.indexOf(p.id);

          if (sortUp) {
            tagged.splice(taggedIdx - 1, 2, this.currentTag.tagged_pages[taggedIdx], this.currentTag.tagged_pages[taggedIdx - 1])
          } else {
            tagged.splice(taggedIdx, 2, this.currentTag.tagged_pages[taggedIdx + 1], this.currentTag.tagged_pages[taggedIdx])
          }
          this.hasChanges = true;
          this.$store.dispatch('viewerTagsChangeSortOrder', {
            workspaceId: this.options.workspaceId, presentationId: this.scData.presentation.id,
            value: {tag_uid: this.currentTag.tag_uid, tagged_pages: tagged}});
        },
        previewTag() {
          this.previewTagOnHiddenBool = true;
          ScCommonUtil.triggerEvent('scapp.viewerTagsAddModalHide');
          this.hideModal();
        },
        renameTag () {
          if (!this.viewerState.isViewerOnline) return;
          ScRenameModal2.modal({name: this.currentTag.tag_name, maxLength: 128, additionalInputPrepend: '#'}, (newName) => {
            newName = newName.replace('#', '');
            this.hasChanges = true;
            return this.$store.dispatch('viewerTagsRenameTag', {
              workspaceId: this.options.workspaceId, presentationId: this.scData.presentation.id,
              value: {tag_uid: this.currentTag.tag_uid, tag_name: newName}});
          });
        },
        deleteTag () {
          if (!this.viewerState.isViewerOnline) return;
          ScConfirmModal2.modal('Are you sure?').then(() => {
            this.$store.dispatch('viewerTagsDeleteTag', {
              workspaceId: this.options.workspaceId, presentationId: this.scData.presentation.id,
              value: {tag_uid: this.currentTag.tag_uid}});
            ScNotification.growlSuccessMsg('Tag has been deleted.');
            this.hasChanges = true;
            this.hideModal();
          }, () => {});
        },
        hasDropped (arr) {
          //console.log('hasDropped', arr.fromIdx, arr.toIdx);
          let tagged = [...this.currentTag.tagged_pages];
          tagged.splice(arr.fromIdx, 1);  // remove item being dragged
          tagged.splice(arr.toIdx, 0, this.currentTag.tagged_pages[arr.fromIdx]);  // add it where
          this.hasChanges = true;
          this.$store.dispatch('viewerTagsChangeSortOrder', {
            workspaceId: this.options.workspaceId, presentationId: this.scData.presentation.id,
            value: {tag_uid: this.currentTag.tag_uid, tagged_pages: tagged}});
        },
      },
      computed: {
        options() {
          return this.$store.state.vw.options;
        },
        viewerState() {
          return this.$store.state.vw.viewerState;
        },
        scData() {
          return this.$store.state.vw.scData;
        },
        primaryLayout () {
          return this.scData.primary_layout;
        },
        currentTag () {
          return _.findWhere(this.$store.state.viewerTags.userTags,{ tag_uid: this.$store.state.viewerTags.viewerTagManageId });
        },
        tagName () {
          return (this.currentTag) ? "#" + this.currentTag.tag_name : '';
        },
        pagesInTagList () {
          let pages = [];
          if (!this.currentTag) return pages;
          for (let pageId of this.currentTag.tagged_pages) {
            let found = _.findWhere(this.scData.page, {id: pageId});
            if (found) pages.push(found);
          }
          return pages;
        }
      }
    }
</script>
