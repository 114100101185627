<template>
    <teleport to=".sc-modals-anchor">
    <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="modalWasHidden"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-sm modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body text-center bg-white rounded">
                    <h4 class="modal-title text-center">Choose an action</h4>
                    <form action="#">
                        <div class="mt-2">
                            <input type="button" value="Sharing" class="btn btn-outline-secondary btn-default w-100 mb-2"
                                @click.prevent="shareNow"/>
                            <input type="button" value="Open with (download)" class="btn btn-outline-secondary btn-default w-100 mb-2 mt-2"
                                @click.prevent="openWith"/>
                            <!--<input type="button" value="Print" class="btn btn-block btn-outline-secondary btn-default"
                                @click.prevent="printFile"/>-->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import _ from "underscore"; // findWhere
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';

    export default {
        name: 'ViewerOpenOrShareModal',
        data() {
            return {
                shareWhenHidden: false,
                openExternallyWhenHidden: false,
            }
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            modalWasHidden() {
                if (this.openExternallyWhenHidden) {
                    // ensure the pdf is not shown otherwise it causes an issue in ios
                    this.$store.dispatch('vwBack');
                    // now ask native exts to open the pdf
                    let currentShareResource = _.findWhere(this.scData.resource, {id: this.viewerState.openOrShareResourceId});
                    ViewerEmbedHandler.openResExternally(currentShareResource);
                    this.openExternallyWhenHidden = false;

                } else if (this.shareWhenHidden) {
                    this.$store.commit('vwShareNow');
                    this.shareWhenHidden = false;

                } else {
                    this.$store.commit('vwSetOpenOrShareResourceId', {openOrShareResourceId: null});
                }
            },
            shareNow() {
                this.shareWhenHidden = true;
                this.hideModal();
            },
            openWith() {
                this.openExternallyWhenHidden = true;
                this.hideModal();
            },
            printFile () {
                // todo add print function here
            }
        },
        computed: {
            viewerState() {
                return this.$store.state.vw.viewerState;
            },
            scData() {
                return this.$store.state.vw.scData;
            }
        },

    };

</script>
