
import _ from 'underscore' // findWhere
import ViewerShareUtil from '../sharing/ViewerShareUtil'
import ScNotification from "../common/ScNotification.vue";
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';


let replaceSpecialUrlVars = function(targetUrl, userEmail, webUrlUserJwt) {
    if (userEmail && /\${userEmail}/.test(targetUrl)) {
        targetUrl = targetUrl.replace(/\${userEmail}/g, userEmail)
    }
    if (webUrlUserJwt && /\${userInfoJwt}/.test(targetUrl)) {
        targetUrl = targetUrl.replace(/\${userInfoJwt}/g, webUrlUserJwt)
    }
    return targetUrl;
};

let _currentResHandler = null;

export default {

    getCurrentHandler() {
        return _currentResHandler;
    },

    determineHandler: function($store, options, viewerState, resource, scData) {
        let rZindex = (1000 * 200) + options.baseZindexOffset;
        let resourceHandler = null;

        if ( resource && resource.content_type === 'movie' ) {
            resourceHandler = {
                isOpenedInternally: () => true,
                displayResource: () => {}, // handled via ViewerResourceVideo
                doneAnimation: () => {},  // handled via ViewerResourceVideo
                closeResource: () => {},  // handled via ViewerResourceVideo
            }

        }   else if ( resource.content_type === 'weburl' ) {
            let wuRmd = _.findWhere(scData.resourcemetadata, {resource_id: resource.id, name: 'weburl'});
            let fullUrl = resource.name;
            if (wuRmd && wuRmd.value) fullUrl = wuRmd.value;

            let oeRmd = _.findWhere(scData.resourcemetadata, {resource_id: resource.id, name: 'open_externally'});
            let openExternally = oeRmd && (''+oeRmd.value) === 'true';

            if (!/^[a-zA-Z]+:/.test(fullUrl)) {  // if no protocol we force http://
                fullUrl = 'http://' + fullUrl;
            }
            let protoIsHttp = /^http:/i.test(fullUrl);
            let protoIsHttps = /^https:/i.test(fullUrl);
            fullUrl = replaceSpecialUrlVars(fullUrl, options.userEmail, options.webUrlUserJwt);

            if (/^showcaseworkshop:/.test(fullUrl)) {
                resourceHandler = {
                    displayResource: () => {
                        if (/^showcaseworkshop:\/\/home/i.test(fullUrl)) {
                            $store.commit('vwExit');
                        } else if (/^showcaseworkshop:\/\/backtostart/i.test(fullUrl) &&
                                viewerState.backHistory.length > 1) {
                            $store.dispatch('vwToStart');
                        } else if (/^showcaseworkshop:\/\/back/i.test(fullUrl)) {
                            $store.dispatch('vwBack');
                        } else if (/^showcaseworkshop:\/\/share/i.test(fullUrl)) {
                            if (options.shareEnabled) $store.commit('vwShareNow');
                        } else if (/^showcaseworkshop:\/\/next/i.test(fullUrl)) {
                            if (viewerState.nextPageId && !viewerState.resourceId) $store.commit('vwNext');
                        } else if (/^showcaseworkshop:\/\/prev/i.test(fullUrl)) {
                            if (viewerState.prevPageId && !viewerState.resourceId) $store.commit('vwPrev');
                        }
                        // ideally we would not record analytics for these
                        return false;
                    },
                    doneAnimation: function() {}
                }

            } else if (/^showcaseform:/i.test(fullUrl)) {
                resourceHandler = {
                    isOpenedInternally: function() {
                        return false;
                    },
                    displayResource: function() {
                        console.log('FormResourceHandler', resource.id);
                        if (!options.showcaseFormsEnabled) {
                            ScNotification.growlErrMsg("Forms not supported for sharing recipients.");
                            return;
                        }
                        $store.commit('vwSetFormsResourceId', { formsResourceId: resource.id });
                    }
                };

            } else if (/^mailto:/i.test(fullUrl)) {
                resourceHandler = new ViewerEmbedHandler.handlers.MailToHandler(resource, scData, rZindex, fullUrl);

            } else if ((protoIsHttp || protoIsHttps) && !openExternally) {
                resourceHandler = new ViewerEmbedHandler.handlers.WebUrlHandler(resource, scData, rZindex, fullUrl);
            } else {
                resourceHandler = new ViewerEmbedHandler.handlers.OpenExternallyHandler(resource, scData, rZindex,
                    fullUrl);
            }

        } else if ( resource.content_type === 'document' && /\.html\.zip$/i.test(resource.name)) {
            if (options.showcaseFormsEnabled) {
                resourceHandler = new ViewerEmbedHandler.handlers.HtmlZipHandler(resource, scData, rZindex,
                    options.workspaceId, options.userEmail, options.sharedKey);
            }   else {
                ScNotification.growlErrMsg("HTML.zip files not supported.");
            }

        } else if ( resource.suffix === 'pdf') {
            let shareEnabled = options.shareEnabled && ViewerShareUtil.hasSharedItemsImpl(scData, viewerState.pageId,
                resource.id);
            resourceHandler = new ViewerEmbedHandler.handlers.PdfHandler(resource,
                viewerState.presScaledHeight, rZindex, shareEnabled, viewerState);

        } else {
            resourceHandler = new ViewerEmbedHandler.handlers.DefaultHandler(resource);
        }
        _currentResHandler = resourceHandler;
        return resourceHandler;
    },


}