<template>
    <ScLayoutNoAuth>

        <div class="text-center">
            Presentations are best viewed in the app:
        </div>

        <div v-if="likelyIos||showAllLinks||!platformFound" class="text-center my-3">
            <a :href="'https://app.showcaseworkshop.com/main/app-link/' + linkSuffix"
               class="btn w-100 mb-2 btn-no-auth-submit fw-bold">Open in iOS App</a>
            <a href="https://apps.apple.com/app/showcase-workshop/id460799429" target="_blank"
               class="btn-market btn-apple mt-2" role="button">
                <span class="btn-market-subtitle">Download on the</span>
                <span class="btn-market-title">App Store</span>
            </a>
        </div>

<!--        <div v-if="likelyAndroid||showAllLinks||!platformFound" class="text-center my-3">-->
<!--            <a :href="'https://app.showcaseworkshop.com/main/app-link/' + linkSuffix"-->
<!--               class="btn w-100 mb-2 btn-no-auth-submit fw-bold">Open in Android App</a>-->
<!--            <a href="https://play.google.com/store/apps/details?id=showcase.android" target="_blank"-->
<!--               class="btn-market btn-google mt-2" role="button">-->
<!--                <span class="btn-market-subtitle">Download on</span>-->
<!--                <span class="btn-market-title">Google Play</span>-->
<!--            </a>-->
<!--        </div>-->

        <div v-if="likelyWindows||showAllLinks||!platformFound" class="text-center my-3">
            <a :href="'showcaseworkshop://' + linkSuffix"
               class="btn w-100 mb-2 btn-no-auth-submit fw-bold">Open in Windows App</a>
            <a href="https://showcaseworkshop.com/download/#showcase-xe" target="_blank"
               class="btn-market btn-windows mt-2" role="button">
                <span class="btn-market-subtitle">Download for</span>
                <span class="btn-market-title">Windows</span>
            </a>
        </div>

<!--        <div v-if="likelyMac||showAllLinks||!platformFound" class="text-center my-3">-->
<!--            <a :href="'showcaseworkshop://' + linkSuffix"-->
<!--               class="btn w-100 mb-2 btn-no-auth-submit fw-bold">Open in Mac App</a>-->
<!--            <a href="https://showcaseworkshop.com/download/#download-mac" target="_blank"-->
<!--               class="btn-market btn-apple mt-2" role="button">-->
<!--                <span class="btn-market-subtitle">Download for</span>-->
<!--                <span class="btn-market-title">Mac</span>-->
<!--            </a>-->
<!--        </div>-->

        <div class="text-center">
            or
        </div>
        <router-link v-if="showcaseId && workshopId" :to="{ name:'viewer-presentation', params: {
                workshopId: this.workshopId, showcaseId: this.showcaseId}}"
           class="mt-4 btn w-100 mb-2 btn-no-auth-submit">Continue in browser</router-link>
        <router-link v-else :to="{ name:'workshop-default'}"
           class="mt-4 btn w-100 mb-2 btn-no-auth-submit">Continue in browser</router-link>

        <div class="mt-4 text-center">
            <a href="https://showcaseworkshop.com/download"
               class="btn btn-link mx-2">Downloads</a>
            <a v-if="platformFound" href="#" @click.prevent="showAllLinks = !showAllLinks"
               class="btn btn-link mx-2">
                <template v-if="!showAllLinks">Show</template>
                <template v-if="showAllLinks">Hide</template> all apps</a>
        </div>
    </ScLayoutNoAuth>
</template>


<script>

import ScLayoutNoAuth from '../global/ScLayoutNoAuth.vue';

/**
 * this page's full url is one of:
 * https://app.dev1.showcaseworkshop.com/main/app-link
 * https://app.dev1.showcaseworkshop.com/main/app-link/[wsId]/showcases/[scId]
 *
 * iOS and Android: if app is installed, the OS will trigger opening the app
 *  if app is not installed it will display this page
 *
 * Windows and Mac: this page will always be opened
 * app-link-chooser ->
 *   click btn -> windows app opens or does nothing
 *   click btn -> mac app opens or does nothing
 *   click btn -> ios app opens or goes to app_link
 *   click btn -> android app opens or goes to app_link
 *
 *
 */

export default {
    name: 'AppLinkChoose',
    components: {ScLayoutNoAuth},
    data: function() {
        return {
            workshopId: null,
            showcaseId: null,
            showAllLinks: false,
            likelyIos: ['iPad', 'iPhone', 'iPhone Simulator', 'iPad Simulator'].includes(navigator.platform),
            //likelyMac: ['MacIntel'].includes(navigator.platform),
            likelyWindows: ['Win16', 'Win32'].includes(navigator.platform),
            //likelyAndroid: ['Android'].includes(navigator.platform),
        }
    },
    mounted() {
        this.workshopId = Number(this.$route.params.workshopId);
        this.showcaseId = Number(this.$route.params.showcaseId);
    },
    computed: {
        linkSuffix() {
            if (this.workshopId && this.showcaseId) return 'workshop/' + this.workshopId + '/showcase/' + this.showcaseId;
            return 'workshop/';
        },
        platformFound() {
            // return this.likelyIos || this.likelyAndroid || this.likelyWindows || this.likelyMac;
            return this.likelyIos || this.likelyWindows;
        }
    }
}

</script>

