<template>
    <div v-if="thumbUrl" :style="{width: '220px'}"
         class="shadow-sm rounded bg-white mt-3 ms-3 result-page text-center">
         <a href="#" class="sc-search-page-link" :style="{height: scaledThumbDims.height + 'px'}"
             @click.prevent="$emit('page-click', pageMatch.page.id)">
             <img :src="thumbUrl" loading="lazy" alt="Thumbnail" class="m-2"
                 v-sc-tooltip="pageTooltip"
                 :style="{
                    'background-color': 'black',
                    width: scaledThumbDims.width + 'px',
                    height: scaledThumbDims.height + 'px'
                }" />
         </a>
         <button class="text-truncate text-center btn btn-link w-100 p-2 pt-0 d-block mx-auto"
                @click.prevent="$emit('page-click', pageMatch.page.id)"
                :style="{ color: '#333' }">
             <img v-if="pageMatch.pageIsLiked" loading="lazy" alt="Liked" title="Liked" class="me-1"
                  :src="staticRoot + '/showcase-icons/5.0.0/icon_liked.png'"
                  :style="{width: '25px', height: '25px', position: 'relative', top: '-2px'}"/>
            <strong class="me-2">{{ pageMatch.page.alpha_num_name }}</strong>
            <span :title="currentPageTitle">{{ currentPageTitle }}</span>
         </button>
    </div>

</template>

<script>

import ScNativeExts from 'ScVueAliasScNativeExts';


export default {
    name: 'ViewerSearchResultPage',
    emits: ['page-click'],
    props: {
        pageMatch: {type: Object, default: null},
        thumbWidth: {type: Number, default: null},
        thumbHeight: {type: Number, default: null},
        thumbUrl: {type: String, default: null},
    },
    methods: {

    },
    computed: {
        pageTooltip: function() {
            return (this.pageMatch.page.alpha_num_name ? this.pageMatch.page.alpha_num_name : '') +
                (this.currentPageTitle ? ' ' + this.currentPageTitle : '');
        },
        currentPageTitle() {
            return (this.pageMatch.page.title && this.pageMatch.page.title !== 'None') ? this.pageMatch.page.title : null;
        },
        staticRoot() {
            return ScNativeExts.getStaticRootUrl();
        },
        scaledThumbDims() {
          let maxWidth = 200; // define max-width for thumbs
          let scaleFactor = (maxWidth / this.thumbWidth);
          // landscape = scale thumb height && portrait = original thumb height
          let calcHeight = (this.thumbWidth < maxWidth) ? this.thumbHeight : this.thumbHeight * scaleFactor;
          let calcWidth = (maxWidth < this.thumbWidth) ? maxWidth : this.thumbWidth;
          return {width: calcWidth, height: calcHeight}
        }
    }


}



</script>

<style scoped>

    .result-page {
        content-visibility: auto;  /* speed up rending by telling browser this node is contained */
    }

</style>
