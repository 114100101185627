<template>
    <ScLayoutNoAuth>

        <div class="mt-3">
            <div v-if="isValidating" class="w-100 my-5 text-center">
                <ScIcon name="spinnerSmall" class="text-muted" />
            </div>
            <!-- for mobile devices, try to reset password in the app -->
            <div v-if="!isValidating && appUrlResetPasswordFinalize" class="w-100 my-5 text-center">
                <a :href="appUrlResetPasswordFinalize" class="btn btn-no-auth-submit p-2 fw-bold">Set new password in the Showcase App</a>

                <div class="mt-5"><small>
                    <a href="#" @click.prevent="appUrlResetPasswordFinalize=null">Not working? Reset password in browser</a>
                </small>

                </div>
            </div>
            <!-- for mobile devices, on success, try and get the user into the app -->
            <div v-if="!isValidating && appUrlOpenShowcase" class="w-100 my-5 text-center">
                <p><strong>Your password was updated.</strong></p>

                <a :href="appUrlOpenShowcase" class="btn btn-no-auth-submit p-2 fw-bold">Open the Showcase App</a>

                <div class="mt-5"><small>
                    <router-link :to="{name: 'workshop-default'}">Not working?  Use Showcase in this Browser</router-link>
                </small>

                </div>
            </div>

            <div v-if="pwdKeyInvalid" class="alert alert-danger mb-5">
                Reset key invalid.
                <router-link :to="{name: 'reset'}">Send password reset request again</router-link>
            </div>

            <div v-if="!isValidating && !appUrlResetPasswordFinalize && !appUrlOpenShowcase && !pwdKeyInvalid">
                <div class="alert alert-info">You are about to reset your password. Afterwards, you will be able to log in
                    with your email address and the new password.</div>
                <form class="form" @submit.prevent>
                   <div class="mb-3 form-floating">
                        <input type="hidden" name="email" :value="userEmail"/>
                        <input class="form-control w-100" id="no_table_new_password" name="new_password" type="password"
                               placeholder="Password" tabindex="1" v-model="pwd"
                               autocomplete="new-password"
                               :maxlength="maxPwdLength" :minlength="minPwdLength" autofocus />
                        <label for="no_table_new_password" class="text-body">Password</label>
                    </div>

                    <div class="controls actions text-center">
                        <div v-if="pwdErrMsg" class="alert alert-danger">{{ pwdErrMsg }}</div>

                        <button @click="submitSetPwdForm" type="submit" tabindex="2" :disabled="isSending"
                                class="btn col-sm-8 col-sm-offset-2 btn-no-auth-submit p-2 fw-bold">
                            Update Password <ScIcon v-if="isSending" name="spinnerFW" class="ms-2" />
                        </button>
                    </div>

                </form>
            </div>

        </div>
    </ScLayoutNoAuth>
</template>


<script>
    import $ from 'jquery';
    import ScLayoutNoAuth from '../global/ScLayoutNoAuth.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';


    export default {
        name: 'SetNewPasswordForm',
        components: {ScLayoutNoAuth, ScIcon},
        data () {
            return {
                pwd: null,
                pwdErrMsg: null,
                pwdKeyInvalid: false,
                userEmail: null,
                appUrlResetPasswordFinalize: null,
                appUrlOpenShowcase: null,

                minPwdLength: 6,
                maxPwdLength: 32,
                isSending: false,
                isValidating: false
            }
        },
        mounted () {
            this.validatePwdKey();

        },
        methods: {
            validatePwdKey() {
                if (this.$route.params.pwdKey) {
                    this.isValidating = true;
                    $.ajax({
                        method: 'post', url: '/main/default/ajax_validate_pwd_key',
                        data: { key: this.$route.params.pwdKey }

                    }).done((data) => {
                        if (data.status === 'ok') {
                            this.userEmail = data.email;
                            this.redirectToAppAsNeeded();
                        } else {
                            this.pwdKeyInvalid = true;
                        }
                    }).always(() => {
                        this.isValidating = false;

                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'validating key');
                    });
                }   else {
                    this.pwdErrMsg = 'No password reset key was found.  Please try clicking the link in the email again.';
                }
            },
            redirectToAppAsNeeded() {
                if (window.SC_APP_URL_SCHEME && this.$route.params) {
                    let checkForApp = !!(navigator.userAgent.match(/(iPad|iPhone|iPod)/g)); // android excluded for now
                    if (checkForApp) {
                        let paramsObj = {
                            key: this.$route.params.pwdKey,
                            email: this.userEmail
                        };
                        this.appUrlResetPasswordFinalize = window.SC_APP_URL_SCHEME + "://resetpassword/?" + $.param(paramsObj);
                    }
                }
            },
            submitSetPwdForm () {
                this.isSending = true;
                this.pwdErrMsg = null;
                if (!this.pwd) {
                    this.pwdErrMsg = 'Please set a password';
                    this.isSending = false;

                } else if (this.pwd.length < this.pwd.minPwdLength || this.pwd.length > this.pwd.maxPwdLength) {
                    this.pwdErrMsg = 'Your new password must be at least 6 characters long and made up of some ' +
                        'combination of letters and numbers (maximum length 32 characters)';
                    this.isSending = false;

                } else {
                    $.ajax({
                        method: 'post', url: '/main/default/ajax_set_new_pwd',
                        data: { pwd: this.pwd, key: this.$route.params.pwdKey }

                    }).done((data) => {
                        if (data.status === 'ok') {
                            let checkForApp = !!(navigator.userAgent.match(/(iPad|iPhone|iPod|Android)/g));
                            if (checkForApp) {
                                this.appUrlOpenShowcase = window.SC_APP_URL_SCHEME + "://";
                            }   else {
                                this.$router.push({name: 'workshop-default'});
                            }

                        } else {
                            for (let err of data.form_errors) {
                                if (err) {
                                    if (!this.pwdErrMsg) this.pwdErrMsg = '';
                                    this.pwdErrMsg += ' ' + err
                                }
                            }
                        }
                    }).always(() => {
                        this.isSending = false;

                    }).fail((jqXhr) => {
                        ScNotification.growlXhrError(jqXhr, 'setting password');
                    });
                }

            }
        }

    }

</script>

