<template>
    <div v-if="resMatch"
         class="result-card d-flex align-items-center shadow-sm rounded p-2 bg-white mt-3 ms-3">
        <a href="#" @click.prevent="$emit('res-click', resMatch.resource.id)">
            <img alt="Thumbnail" :src="resourceIconUrl()" style="width: 50px; height: 50px;" class="me-1"/>
        </a>
        <button class="text-truncate text-start btn btn-link px-2 py-0"
                v-sc-tooltip="resMatch.resource.name"
                @click.prevent="$emit('res-click', resMatch.resource.id)"
                style="width: 230px; color: #333;">
            <img v-if="resMatch.resIsLiked" loading="lazy" title="Liked" class="me-1"
                 :src="staticRoot + '/showcase-icons/5.0.0/icon_liked.png'"
                 :style="{width: '25px', height: '25px', position: 'relative', top: '-2px'}"/>
            {{resMatch.resource.name}}
        </button>
    </div>
</template>

<script>

    import ScNativeExts from 'ScVueAliasScNativeExts';

    export default {
        name: 'ViewerSearchResultResource',
        emits: ['res-click'],
        props: {
            resMatch: {type: Object, default: null}
        },
        methods: {
            resourceIconUrl() {
                if (!this.displayGenericThumbnails) return this.resMatch.resource.thumb_url;
                if (!this.resMatch.resource) return null;
                let custom_suffix_map = {
                    'docx': 'doc',
                    'xlsx': 'xls',
                    'rar': 'zip',
                    'pptx': 'ppt',
                    'html.zip': 'zip',
                    'showcase': 'scw'
                };
                let resIconImg = 'all';
                if (!this.resMatch.resource.suffix)
                    resIconImg = 'all';
                else if (this.resMatch.resource.suffix.toLowerCase() in custom_suffix_map)
                    resIconImg = custom_suffix_map[this.resMatch.resource.suffix.toLowerCase()];
                else if (['mov', 'mp4', 'm4v', 'avi', 'wmv', 'flv', 'mpg', 'mpeg', 'webm', 'f4v'].includes(this.resMatch.resource.suffix.toLowerCase()))
                    resIconImg = 'video';
                else if (!['pdf', 'doc', 'xls', 'zip', 'ppt'].includes(this.resMatch.resource.suffix.toLowerCase()))
                    resIconImg = 'all';
                return ScNativeExts.getStaticRootUrl() + '/showcase-icons/7.1.1/200x200_' + resIconImg + '.png';
            },
        },
        computed: {
            displayGenericThumbnails() {
                return !navigator.onLine;
            },
            staticRoot() {
                return ScNativeExts.getStaticRootUrl();
            }
        }
    }
</script>

<style scoped>

    .result-card {
        width: 300px;
        content-visibility: auto;  /* speed up rending by telling browser this node is contained */
    }
    @media (max-width: 576px) {
        .result-card {
            width: 275px;
        }
    }

</style>