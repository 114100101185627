<template>
  <div v-if="pres" class="presentation-list-item p-0 my-2 rounded border bg-white text-center" >
    <div class="row g-0 d-sm-block align-items-center clearfix">
      <div class="col-auto presentation-thumb-holder p-2">
        <router-link
            :to="{name: 'viewer-presentation',
                  params: {workshopId: mainMxCurrentWorkshopId, showcaseId: pres.id}}"
            :title="pres.title">
          <img class="presentation-thumb mx-auto" alt="Thumbnail" :src="pres.thumbnail" :style="thumbDimsStyleObject" />
        </router-link>
      </div>
      <div class="col mb-2 mb-sm-3 mt-2 mt-sm-1">
        <div class="presentation-caption-holder text-break text-start text-sm-center p-2 pt-0 clearfix">
          <router-link
              class="presentation-caption d-block fw-bold text-muted ms-2 ms-sm-0"
              :to="{name: 'viewer-presentation',
                    params: {workshopId: mainMxCurrentWorkshopId, showcaseId: pres.id}}"
              :title="pres.title">
            <span class="d-block">{{ pres.title }}</span>
          </router-link>
        </div>

        <div class="presentation-details-holder clearfix pt-0 row g-0 align-items-center">
          <div class="col-8 text-start ps-3">
            <span class="text-muted align-text-bottom">{{ formattedPresSize }}</span>
          </div>
          <div class="col-4 text-end pe-3">
            <div class="btn-group dropdown">
              <button class="btn p-0" data-bs-toggle="dropdown" type="button" v-sc-tooltip="'More'"
                      aria-expanded="false"><ScIcon name="ellipsisHFW"/></button>
              <div class="dropdown-menu pe-3" aria-labelledby="dropdown">

                <button type="button" class="btn btn-link dropdown-item me-3" @click.prevent="presentInKioskMode">
                  <ScIcon name="keynoteFW" class="me-3 h3"/>
                  <span class="d-inline-block align-text-bottom">Present in Kiosk Mode</span>
                </button>

                <button v-if="hasIntroVideo && introVideoState === 'enabled'" type="button"
                        class="btn btn-link dropdown-item me-3"
                        @click.prevent="toggleOpeningVideo()">
                  <ScIcon name="videoFW" class="me-3 h3"/>
                  <span class="d-inline-block align-text-bottom">Disable opening video</span>
                </button>

                <button v-else-if="hasIntroVideo && introVideoState === 'disabled'" type="button"
                        class="btn btn-link dropdown-item me-3"
                        @click.prevent="toggleOpeningVideo()">
                  <ScIcon name="videoSlashFW" class="me-3 h3"/>
                  <span class="d-inline-block align-text-bottom">Enable opening video</span>
                </button>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>

    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import MainAppMixin from '../MainAppMixin';
    import ViewerUtil from '../../shared/viewer/ViewerUtil';


    export default {
      name: 'BrowserViewerPresentationListItem',
      mixins: [ MainAppMixin ],
      components: { ScIcon },
      props: {
        pres: {type: Object, default: null},
        isSingleColumnLayout: {type: Boolean, default: false},
      },
      methods: {
        presentInKioskMode () {
          this.$emit('toggle-kiosk-mode-on-off');
          this.$router.push({name: 'viewer-presentation', params: {
              workshopId: this.mainMxCurrentWorkshopId, showcaseId: this.pres.id},
          });
        },
        toggleOpeningVideo () {
          this.$emit('toggle-intro-video-on-off', this.pres.id);
        }
      },
      computed: {
        formattedPresSize () {
          let bytes = 0;
          if (!this.pres) return '';
          if (this.pres.bytesize_sd > 0 && ViewerUtil.isScreenSD()) {
            bytes = this.pres.bytesize_sd;
          }
          else if (this.pres.bytesize_hd > 0) {
            bytes = this.pres.bytesize_hd;
          }
          return ScCommonUtil.filesizeWithBytes(bytes);
        },
        introVideoState () {
          if (!this.$store.state.user) return 'enabled';
          return (this.$store.state.user.viewer_intro_video_disabled.includes(this.pres.id) ? 'disabled' : 'enabled');
        },
        hasIntroVideo () {
          return (this.pres && this.pres.intro_video ? true : false);
        },
        thumbDimsStyleObject () {
          let maxWidth = (this.isSingleColumnLayout) ? 70 : 200; // define max-width for thumbs
          let thumb = ScCommonUtil.getThumbDimensionsFromUrl(this.pres.thumbnail, maxWidth, 150);
          let scaleFactor = (maxWidth / thumb.w);
          // mobile + landscape = scale thumb height
          // mobile + portrait = scale thumb height
          // desktop + landscape = scale thumb height
          // desktop + portrait = original thumb height
          let calcHeight = (!this.isSingleColumnLayout && this.pres.orientation === 'portrait') ? thumb.h : thumb.h * scaleFactor;
          let calcWidth = (maxWidth < thumb.w) ? maxWidth : thumb.w ;

          return {
            height: calcHeight + 'px',
            width: calcWidth + 'px'
          }
        }
      }
    }

</script>


<style>

  .presentation-list-item {
    float: left;
    width: 220px;
  }

  @media only screen and (max-width: 499px) {
    .presentation-list-item {
      width: 100%;
    }
  }

</style>