<template>
  <ScNormalPageLayout headingPart1="Share" headingPart2="Content" submenu="sharing">
    <div class="mb-5">
      <div class="container mt-3 mt-sm-5">
        <div class="row justify-content-center">
          <form class="form col-11 col-sm-8 mb-5" @submit.prevent="" autocomplete="off">

            <NewShareForm
                :workspaceId="mainMxCurrentWorkshopId"
                :presentationId="presentationId"
                :scData="scData" :scDataLoading="scDataLoading"
                :currentUserDisplayName="$store.state.user.displayable_name"
                :currentUserEmail="$store.state.user.email"
                :headerImg="mainMxCurrentWorkshop.header_img"
                @warn-before-leaving-update="warnBeforeLeaving=$event">

              <template v-slot:shareContentIntroText="slotProps">
                <div v-if="slotProps.shareStage === 'select-type'">
                  <h5 class="mb-3 fw-normal">
                    Send an entire presentation and/or files allowed to be shared via email, link or
                    VIP Pass. Then use <router-link :to="{ name: 'reporting-sharing-history',
                                        params: { workshopId: mainMxCurrentWorkshopId }}">Sharing History</router-link>
                    to track recipients' viewing.
                  </h5>
                </div>
              </template>

              <template v-slot:default="slotProps">
                <div v-if="slotProps.shareStage === 'select-type'">
                  <div class="my-3">
                    <label for="scSelectContent" class="pb-1">Select a presentation</label>
                    <div v-if="presentationsLoading" class="text-center p-4">
                      <ScIcon name="spinnerFW" class="text-muted"/>
                    </div>
                    <div v-else class="clearfix">
                      <ScSelectWithThumbnailAndSearch id="scSelectContent"
                                                      :items="presentations"
                                                      :defaultSelectedItemId="presentationId"
                                                      placeholder="Select a published presentation"
                                                      @sc-select-item="selectShowcase"
                                                      @deselect-item="deselectShowcase"></ScSelectWithThumbnailAndSearch>
                    </div>
                  </div>
                </div>
              </template>

              <template v-slot:sharedFilesFootnote>
                <div v-if="mainMxUwCanPerform('a:list_pres_for_edit') && presentationId" class="d-flex justify-content-end">
                  <small class="text-muted">
                    <ScIcon name="questionCircle" /> Control what gets listed here in
                    "Manage Sharing". Changes must be published to take effect.
                  </small>
                </div>
              </template>

            </NewShareForm>

            <div class="mt-3 text-center">

              <NewSharePrevButton class="mx-2">
              </NewSharePrevButton>

              <NewShareNextButton class="mx-2">
                <template v-slot:nextLinkEmailSave>
                  <router-link :to="{ name: 'reporting-sharing-history', params: { workshopId: mainMxCurrentWorkshopId }}"
                               class="btn btn-outline-secondary ms-2">Sharing History</router-link>
                </template>
                <template v-slot:nextLinkSave>
                  <router-link :to="{ name: 'reporting-sharing-history', params: { workshopId: mainMxCurrentWorkshopId }}"
                               class="btn btn-outline-secondary ms-2">Sharing History</router-link>
                </template>
              </NewShareNextButton>

            </div>
          </form>
        </div>
      </div>
    </div>
  </ScNormalPageLayout>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScSelectWithThumbnailAndSearch from '../../shared/common/ScSelectWithThumbnailAndSearch.vue';
    import MainAppMixin from '../MainAppMixin';
    import NewShareForm from "../../shared/sharing/NewShareForm.vue";
    import NewSharePrevButton from "../../shared/sharing/NewSharePrevButton.vue";
    import NewShareNextButton from "../../shared/sharing/NewShareNextButton.vue";
    import ScConfirmModal2 from '../../shared/common/ScConfirmModal2.vue';

    export default {
      name: "ShareContent",
      components: {
        ScNormalPageLayout, ScSelectWithThumbnailAndSearch, NewShareForm, NewShareNextButton,
        NewSharePrevButton, ScIcon
      },
      data () {
        return {
          presChooserNeeded: true,
          presentationsLoading: false,
          presentations: [],
          presentationId: null,
          presentationTitle: null,
          presentationThumbUrl: null,
          scDataLoading: false,
          scData: null,
          warnBeforeLeaving: false,
          warnMsg: 'You will lose your draft, are you sure?',
        };
      },
      mixins: [ MainAppMixin ],
      created () {
        window.addEventListener('beforeunload', this.userLeaving);
      },
      mounted () {
        this.loadShowcases();
      },
      beforeUnmount() {
        window.removeEventListener('beforeunload', this.userLeaving);
      },
      beforeRouteLeave (to, from, next) {  // vue router will call this
        if (this.warnBeforeLeaving) {
          ScConfirmModal2.modal(this.warnMsg).then(() => {
            next();
          }, () => {
            next(false);  // Cancel navigation
          });
        }   else {
          next();
        }
      },
      methods: {
        userLeaving (e) {
          if (this.warnBeforeLeaving) e.returnValue = this.warnMsg;
        },
        selectShowcase (id) {
          let correctPresDataLoaded = (this.scData && this.scData.presentation.id === id && id === this.presentationId);
          this.presentationId = id;
          if (!correctPresDataLoaded) this.loadScData();
        },
        deselectShowcase () {
          this.presentationId = null;
          this.presentationThumbUrl = null;
          this.scData = null;
        },
        loadShowcases () {
          this.presentationsLoading = true;
          $.ajax({
            url: "/api/v1/showcases",
            data: { workspace_id: this.mainMxCurrentWorkshopId }
          }).done((data) => {
            //console.log('sc loaded', data);
            this.presentations = data;
          }).always(() => {
            this.presentationsLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "fetching presentations");
          });
        },
        loadScData(){
          this.scDataLoading = true;
          $.ajax({
            url: "/api/v1/showcases/"+ this.presentationId,
            data: { workspace_id: this.mainMxCurrentWorkshopId }
          }).done((data) => {
            this.scData = data.export;
            this.presentationThumbUrl = data.thumb_url;
            this.presentationTitle = data.presentation_title;
          }).always(() => {
            this.scDataLoading = false;
          }).fail((jqXhr) => {
            if (jqXhr) ScNotification.growlXhrError(jqXhr, 'loading presentation data');
          });
        }
      },
    }
</script>
