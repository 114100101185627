import _ from 'underscore';

let ViewerShareUtil = {
  getCurrentResShareableRes: function (data, pageId, resourceId) {
    //console.log('_getCurrentResShareableRes', {pageId, resourceId})
    let currentRes = null;
    if (resourceId) {
      let currentSr = Object.values(data.shareable_resource).find(sr => sr.resource_id === resourceId);
      if (!currentSr && data.shareable_page_resource) {
        currentSr = Object.values(data.shareable_page_resource).find((sr) => {
          return sr.resource_id === resourceId && sr.page_id === pageId;
        });
      }
      if (currentSr) {
        let res = data.resource[currentSr.resource_id];
        currentRes = res && ViewerShareUtil.isFileShareable(res) ? res : null;
      }
    }
    return currentRes;
  },

  getShowcaseShareItem: function (data) {
    let dsr = data.shareable_resource ? data.shareable_resource : {};
    for (let sr of Object.values(dsr)) {
      if (sr.resource_id) {
        let res = data.resource[sr.resource_id];
        if (res && res.name && _.isNumber(res.id) && res.content_type === 'showcase_share') {
          return res;
        }
      }
    }
    return null;
  },

  getAllPagesShareableItems: function (data, pageLevelShareableItems, resourceId) {
    //console.log('_getAllPagesShareableItems', data);
    let srs = [];
    let dsr = data.shareable_resource ? data.shareable_resource : {};
    Object.values(dsr).forEach((sr) => {
      if (!sr.resource_id) return;
      if (_.findWhere(pageLevelShareableItems, {id: sr.resource_id})) return;  // if already in page level skip
      if (_.findWhere(srs, {id: sr.resource_id})) return;   // if already in the list, skip
      let res = data.resource[sr.resource_id];
      if (ViewerShareUtil.isFileShareable(res) && res.id !== resourceId) srs.push(res)
    });
    return _.sortBy(srs, function (r) {
      // note, full presentation share handled in the template
      return r && r.name ? r.name.toLowerCase() : '';
    });
  },

  getPageLevelShareableItems: function (data, pageId, resourceId) {
    if (!pageId) return [];
    let srps = [];
    let dspr = data.shareable_page_resource ? data.shareable_page_resource : {};
    Object.values(dspr).forEach((sr) => {
      if (sr.page_id === pageId) {
        if (_.findWhere(srps, {id: sr.resource_id})) return;   // if already in the list, skip
        let res = data.resource[sr.resource_id];
        if (ViewerShareUtil.isFileShareable(res) && res.id !== resourceId) srps.push(res)
      }
    });
    return _.sortBy(srps, (r) => {
      return r && r.name ? r.name.toLowerCase() : '';
    });
  },

  isFileShareable: function (res) {
    return res && res.name && _.isNumber(res.id) && res.suffix !== 'html.zip' &&
      res.content_type !== 'showcase_share';
  },

  hasSharedItemsImpl: function (data, pageId, resourceId) {
    //console.log('hasSharedItemsImpl', data, pageId, resourceId);
    let currentResource = ViewerShareUtil.getCurrentResShareableRes(data, pageId, resourceId);
    let showcaseShareable = ViewerShareUtil.getShowcaseShareItem(data);
    let shareablePageResources = ViewerShareUtil.getPageLevelShareableItems(data, pageId, resourceId);
    let shareableResources = ViewerShareUtil.getAllPagesShareableItems(data, shareablePageResources);
    return !!(currentResource || showcaseShareable ||
      (shareablePageResources && shareablePageResources.length > 0) ||
      (shareableResources && shareableResources.length > 0));
  },

  isResListedOnPageOrAllImpl(data, pageId, resourceId) {
    return !!(ViewerShareUtil.getCurrentResShareableRes(data, pageId, resourceId));
  }
};

export default ViewerShareUtil;
