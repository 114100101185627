<template>
  <teleport to=".sc-modals-anchor">
    <div v-vb-is:modal.show ref="scModal" @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-bottom">
            <button class="btn-close" data-bs-dismiss="modal"></button>
            <h4 class="modal-title w-100 text-center">Assign your tags
              <small v-if="!viewerState.isViewerOnline" class="badge badge-warning"
                     v-sc-tooltip="'Assigning is disabled when offline'">
                <ScIcon name="exclamationTriangleFW"/> Offline</small>
            </h4>
          </div>

          <div class="modal-body pb-4 rounded-bottom">
            <template v-if="loadedTagsForShowcase.length > 0">
              <div v-for="tag in loadedTagsForShowcase" :key="tag.tag_uid" class="row ms-1">
                <div class="my-2 form-check col-6 col-lg-7">
                  <input type="checkbox" :checked="tag.tagged_pages.includes(activePage.id)"
                         :id="'addTagChk_' + tag.tag_uid" :disabled="!viewerState.isViewerOnline"
                         @change="addTag(tag)" class="me-2 form-check-input">
                  <label class="form-check-label d-flex" :for="'addTagChk_' + tag.tag_uid">
                    <span class="badge bg-primary fw-bold fs-md mb-0 text-truncate d-block"
                    >#{{ tag.tag_name }}</span></label>
                </div>
                <div class="col-6 col-md-5 my-auto text-start text-md-right px-0 px-md-2">
                  <button @click.prevent="editTag(tag)" class="btn btn-link px-1 px-md-2 py-0">
                    <ScIcon name="sortFWR"/> Sort</button>
                  <button @click.prevent="previewTag(tag)" :disabled="tag.tagged_pages.length===0"
                          class="btn btn-link px-1 px-md-2 py-0">
                    <ScIcon name="playCircle"/> Present</button>
                </div>
              </div>
            </template>
            <div v-else class="row ms-1">
              <div class="col text-muted text-center">
                <em>No tags</em>
              </div>
            </div>
            <div v-if="!showAddTagForm" class="form mt-5">
              <button class="btn btn-outline-secondary" @click.prevent="showAddTagForm=true"
                      :disabled="!viewerState.isViewerOnline">New tag</button>
            </div>
            <form v-if="showAddTagForm" class="form mt-5 row" @submit.prevent>
              <div class="col-11 px-2 d-flex">
                <button class="btn btn-link disabled px-2">
                  <span class="">#</span>
                </button>
                <input type="text" v-model="newTagName" @keyup.enter.prevent="newTag"
                       class="form-control px-sm-3 mx-2" name="search" :disabled="!viewerState.isViewerOnline"
                       placeholder="New tag" :style="{'min-width': '100px'}"/>
                <button @click.prevent="newTag" class="btn btn-outline-secondary" :disabled="!viewerState.isViewerOnline"
                        type="button">Create</button>
              </div>
              <button type="button" class="btn btn-link add-label-close px-2 col-1"
                      @click.prevent="showAddTagForm=false" v-sc-tooltip="'Cancel'">
                <span class="h4 align-top">×</span></button>
            </form>

            <div class="row text-muted">
              <div class="col mt-4">
                <ScIcon name="infoCircle" class="text-muted"/>
                Tag slides to group them into custom slideshows. Share while presenting a tag and your
                recipient will see that sequence first.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
    import _ from 'underscore'; // clone, findWhere
    import ScIcon from "../common/ScIcon.vue";
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ScCommonUtil from "../common/ScCommonUtil";

    export default {
      name: "ViewerTagsAddModal",
      components: {ScIcon},
      props: {
        //
      },
      data() {
        return {
          newTagName: null,
          showAddTagForm: false,
          previewTagOnHidden: null,
        }
      },
      mounted () {
        //console.log(this.scData, this.$store);
        if (ViewerEmbedHandler.keyboardOverlayFixSetup) ViewerEmbedHandler.keyboardOverlayFixSetup(this.$refs.scModal);
        ScCommonUtil.onEvent('scapp.viewerTagsAddModalHide', this.hideModal);
      },
      beforeUnmount() {
        ScCommonUtil.offEvent('scapp.viewerTagsAddModalHide');
        if (ViewerEmbedHandler.keyboardOverlayFixDestroy) ViewerEmbedHandler.keyboardOverlayFixDestroy(this.$refs.scModal);
      },
      methods: {
        hideModal() {
          this.$refs.scModal.$vb.modal.hide();
        },
        modalWasHidden() {
          this.$store.commit('vwAddTagModalToggle', {addTagModalShown: false});
          if (this.previewTagOnHidden) {
            let tag = this.previewTagOnHidden;
            let tagged_pages = _.clone(tag.tagged_pages);
            this.$store.dispatch('vwPage', {pageId: tagged_pages[0], direction: 'up',
              adHocPagelist: {adHocName: '#' + tag.tag_name, adHocPageIds: tagged_pages, adHocTagUid: tag.tag_uid}});
            this.$store.commit('viewerTagsSelectedTagIdUpdate', tag.tag_uid);
            this.previewTagOnHidden = null;
          }
        },
        addTag(tag) {
          // prevent disabled checkbox to be clicked
          if (!this.viewerState.isViewerOnline) return;

          let tagged = [...tag.tagged_pages];
          if (!tagged.includes(this.activePage.id)) {
            tagged.push(this.activePage.id);
          } else {
            tagged = tagged.filter(page => page !== this.activePage.id);
          }
          this.$store.dispatch('viewerTagsToggleUserTag', {
            workspaceId: this.options.workspaceId, presentationId: this.scData.presentation.id,
            value: {tag_uid: tag.tag_uid, tagged_pages: tagged}});
        },
        newTag () {
          // remove if hashtag is included
          if (this.newTagName)
            this.newTagName = this.newTagName.replace('#', '');
          this.$store.dispatch('viewerTagsAddNewUserTag', {
            workspaceId: this.options.workspaceId, presentationId: this.scData.presentation.id,
            value: {tag_name: this.newTagName, tagged_pages: [this.activePage.id]}});
          //clear and close form after saving
          this.newTagName = null;
          this.showAddTagForm = false;
        },
        editTag (tag) {
          this.$store.commit('viewerTagsManageTagIdUpdate', tag.tag_uid);
          this.$store.commit('vwManageTagsModalToggle', { manageTagsModalShown: true });
        },
        previewTag (tag) {
          this.previewTagOnHidden = tag;
          this.hideModal();
        }
      },
      computed: {
        options() {
          return this.$store.state.vw.options;
        },
        viewerState() {
          return this.$store.state.vw.viewerState;
        },
        scData() {
          return this.$store.state.vw.scData;
        },
        activePage() {
          let pg = null;
          if (this.scData && this.viewerState && this.viewerState.drawerPageId) {
            pg = _.findWhere(this.scData.page, {id: this.viewerState.drawerPageId});
          }
          return pg;
        },

        loadedTagsForShowcase () {
          return this.$store.state.viewerTags.userTags;
        }
      }
    }
</script>

<style scoped>
  .add-label-close:hover {
    opacity: 1;
  }
  .add-label-close {
    height: 38px;
    color: #333;
    font-weight: 700;
    opacity: .5;
  }
  .add-label-close span {
    line-height: 0.75
  }
</style>