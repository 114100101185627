<template>
    <teleport to=".sc-modals-anchor">
        <div v-vb-is:modal.show ref="scModal" @vb-hide-bs-modal="canModalHide"
             @vb-hidden-bs-modal="$emit('modal-was-hidden')"
             class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="border: none;background-color: transparent;">
                    <SharingSummaryForRecipient
                            class="mx-auto shadow-lg"
                            :note="note" :resources="resources" :sharedType="sharedType"
                            :sharerEmail="sharerEmail" :sharedId="sharedId" :workspaceId="workspaceId"
                            :sharerDisplayName="sharerDisplayName"
                            :daysRemaining="daysRemaining"
                            :headerImg="headerImg" :presentationId="presentationId"
                            :presentationLoading="presentationLoading"
                            :presentationTitle="presentationTitle" :recordAnalytics="recordAnalytics"
                            :presentationThumbUrl="presentationThumbUrl"
                            :openAtOptions="openAtOptions" :scData="scData"
                            :closeBtnShown="closeBtnShown" @close-btn-click="hideModal"
                            @open-showcase="openShowcase"></SharingSummaryForRecipient>
                </div>
            </div>
        </div>
    </teleport>
</template>


<script>

    import MainAppMixin from "../MainAppMixin";
    import BrowserViewerAnalyticsLogger from "../browser-viewer/BrowserViewerAnalyticsLogger";
    import SharingSummaryForRecipient from '../../shared/sharing/SharingSummaryForRecipient.vue';

    export default {
        name: 'SharedCompModal',
        emits: ['modal-was-hidden', 'open-showcase'],
        components: {SharingSummaryForRecipient},
        mixins: [MainAppMixin],
        props: {
            headerImg: {type: String, default: null},
            resources: {type: Array, default: null},
            sharedType: {type: String, default: 'email'},
            subject: {type: String, default: null},
            note: {type: String, default: null},
            daysRemaining: {type: Number, default: null},
            sharerEmail: {type: String, default: null},
            sharerDisplayName: {type: String, default: null},

            scData: {type: Object, default: null},
            presentationLoading: {type: Boolean, default: false},
            presentationTitle: {type: String, default: null},
            presentationThumbUrl: {type: String, default: null},

            workspaceId: {type: Number, default: null},
            userId: {type: Number, default: null},
            sharedId: {type: Number, default: null},
            presentationId: {type: Number, default: null},
            recordAnalytics: {type: Boolean, default: true},
            closeBtnShown: {type: Boolean, default: true},

            openAtOptions: {type: Object, default: null}
        },
        mounted () {
            if (this.recordAnalytics && this.presentationId) {
                // record event on page load
                BrowserViewerAnalyticsLogger.record('share_page_view', {showcase_id: this.presentationId },
                    this.workspaceId, this.userId, this.sharedId);
            }
        },
        methods: {
            hideModal() {
                this.$refs.scModal.$vb.modal.hide();
            },
            canModalHide(e) {
                if (!this.closeBtnShown) e.preventDefault();
            },
            openShowcase() {
                this.$emit('open-showcase');
            }
        },
    }

</script>



