<template>
    <div :class="['scWidgets', viewerState.widgetsDimmed ? 'widgetsDimmed' : '']"
         v-if="scData && viewerState && viewerState.widgetsShown
                    && !viewerState.searchId && !viewerState.isScreensaverVideoPlaying">

        <a href="#" class="scBackLink scMainButtonLink"
            @click.prevent="$store.dispatch('vwBack')"
            :style="topLeftIconStyleObj">
            <img alt="Back" v-if="viewerState.backHistory.length > 1 || (viewerState.resourceId && viewerState.resourceIsOpeningVideo)"
                 class="scMainButtonImg scMainButtonImg4c m-3"
                 :src="options.staticRoot + '/showcase-icons/5.0.0/back_button.png'"/>

            <img alt="Home" v-if="(viewerState.backHistory.length <= 1 && !options.kioskMode)"
                 class="scMainButtonImg scMainButtonImg4c m-3"
                 :src="options.staticRoot + '/showcase-icons/5.0.0/home_button.png'"/>
        </a>

        <a v-if="shareShown" href="#" class="scTopRightShareLink scMainButtonLink"
           @click.prevent="$store.commit('vwShareNow')"
           :style="topRightIconStyleObj">
            <img alt="Share" class="scTopRightShareImg scMainButtonImg scMainButtonImg4c m-3"
                :src="options.staticRoot + '/showcase-icons/5.0.0/share_button.png'"/>
        </a>

        <a v-if="showNextPrev && viewerState.prevPageId && !viewerState.resourceId" href="#" class="scPrev scMainButtonLink"
           @click.prevent="$store.commit('vwPrev')"
           :style="{top: (viewerState.frameEleHeight/2)-((10+50+10)/2) + 'px', left: (widgetsXPos+15) + 'px'}">
            <img alt="Previous" class="scPrevImg scNextPrevImg" style="margin: 10px; width: 18px; height: 50px;"
                :src="options.staticRoot + '/showcase-icons/7.1.1/slideimages_left.png'"/>
        </a>

        <a v-if="showNextPrev && viewerState.nextPageId && !viewerState.resourceId" href="#" class="scNext scMainButtonLink"
           @click.prevent="$store.commit('vwNext')"
           :style="{top: (viewerState.frameEleHeight/2)-((10+50+10)/2) + 'px', right: (widgetsXPos+15) + 'px'}">
            <img alt="Next" class="scNextImg scNextPrevImg" style="margin: 10px; width: 18px; height: 50px;"
                 :src="options.staticRoot + '/showcase-icons/7.1.1/slideimages_right.png'"/>
        </a>

        <a v-if="showDrawerOpenBtn" href="#" class="scDrawerOpen scMainButtonLink"
           @click.prevent="$store.commit('vwDrawerShow', {drawerPageId: viewerState.pageId, drawerResourceId: viewerState.resourceId})"
            :style="bottomLeftIconStyleObj">
            <img alt="Drawer" class="scDrawerOpenImg scMainButtonImg scMainButtonImg4c m-3"
                 :src="options.staticRoot + '/showcase-icons/5.0.0/menu_button.png'"/></a>

        <div v-if="drawerShown" class="scDrawer p-4" @click="$store.commit('vwDrawerHide')"
             style="width: calc(100% - 260px); left: 260px; height: 100vh; background: transparent; position: absolute; display: block"></div>

        <div v-if="drawerShown" class="scDrawer"
            :style="drawerStyleObj">
            <div class="p-4">
                <a href="#" @click.prevent="$store.commit('vwDrawerHide')" class="scDrawerButtonLink">
                    <img alt="Close" class="drawerCloseImg scDrawerButtonImg m-1"
                         :src="options.staticRoot + '/showcase-icons/5.0.0/icon_close.png'"/></a>

                <a v-if="(viewerState.backHistory.length <= 1 && !options.kioskMode)" href="#"
                   class="homeLink scDrawerButtonLink" @click.prevent="$store.commit('vwExit')">
                    <img alt="Home" class="scDrawerButtonImg m-1"
                         :src="options.staticRoot + '/showcase-icons/5.0.0/icon_house.png'"/>
                    <strong class="d-inline-block align-middle">Home</strong></a>

                <a v-if="viewerState.backHistory.length > 1" href="#" class="toStartLink scDrawerButtonLink"
                   @click.prevent="clickedToStartLink">
                    <img alt="To start" class="scDrawerButtonImg m-1 "
                         :src="options.staticRoot + '/showcase-icons/5.0.0/icon_to_start.png'"/>
                    <strong class="d-inline-block align-middle">Back to start</strong></a>

                <a v-if="viewerState.pageId && !viewerState.resourceId" href="#" class="searchLink scDrawerButtonLink"
                   @click.prevent="$store.dispatch('vwSearch', {searchId: 0, direction: 'up'})">
                    <img alt="Search" class="scDrawerButtonImg m-1"
                         :src="options.staticRoot + '/showcase-icons/5.0.0/icon_search.png'"/>
                    <strong class="d-inline-block align-middle">Search</strong></a>

                <a v-if="viewerState.drawerFullscreenShown" href="#" class="scFsLink scDrawerButtonLink"
                   @click.prevent="toggleFullscreen()">
                    <img alt="Full screen" class="scDrawerButtonImg m-1"
                         :src="options.staticRoot + '/showcase-icons/5.0.0/icon_expand.png'">
                    <strong class="d-inline-block align-middle">Fullscreen</strong></a>

                <a v-if="(options.likeEnabled && !options.kioskMode && drawerLikeShown)" href="#" class=" mt-2 likeLink scDrawerButtonLink"
                   @click.prevent="toggleLike">
                    <img alt="Liked" v-if="drawerSlideLiked" class="scDrawerButtonImg m-1"
                         :src="options.staticRoot + '/showcase-icons/5.0.0/icon_liked.png'"/>
                    <img alt="Not liked" v-if="!drawerSlideLiked" class="scDrawerButtonImg m-1"
                         :src="options.staticRoot + '/showcase-icons/5.0.0/icon_unliked.png'"/>
                    <strong class="d-inline-block align-middle">Favorite</strong></a>

                <a v-if="(options.userTagsEnabled && !options.kioskMode && viewerState.pageId)" href="#" class="tagLink scDrawerButtonLink"
                   @click.prevent="openAddTagModal">
                    <ScIcon name="hashtagSmall" class="d-inline-flex m-2 ps-1"/>
                    <strong class="d-inline-flex align-text-bottom my-2 mx-1">Tags</strong></a>
            </div>
            <ViewerSlideshowThumbnails></ViewerSlideshowThumbnails>

        </div>
    </div>
</template>


<script>

    import _ from 'underscore'; // findWhere
    import ViewerLikes from './ViewerLikes';
    import ViewerShareUtil from '../sharing/ViewerShareUtil';
    import ViewerSlideshowThumbnails from './ViewerSlideshowThumbnails.vue';
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ScIcon from "../common/ScIcon.vue";
    import store from '../../store';
    import ViewerTagsStore from "./ViewerTagsStore";

    const WIDGET_BOX_SIZE = (15+45+15);  // 45 px for the image and 5px margin on left and right

    if (!store.state.viewerTags) store.registerModule('viewerTags', ViewerTagsStore);


    export default {
        name: 'ViewerWidgets',
        props: {
            //
        },
        data() {
            return {
                drawerSlideLiked: false
            }
        },
        components: {ScIcon, ViewerSlideshowThumbnails},
        mounted() {
            //console.log('mount', this.options.workspaceId, this.scData.presentation.id);
            if (this.options.userTagsEnabled)
                this.$store.dispatch('viewerTagsLoadUserTags', { workspaceId: this.options.workspaceId,
                    presentationId: this.scData.presentation.id, pages: this.scData.page });
        },
        methods: {
            toggleLike() {
                ViewerLikes.togglePageLike(this.options.workspaceId, this.scData.presentation.id,
                    this.scData.presentation.version,
                    this.viewerState.resourcePageId ? this.viewerState.resourcePageId : this.viewerState.drawerPageId,
                    this.viewerState.drawerResourceId);
                this.updateDrawerSlideLiked();
            },
            clickedToStartLink () {
                this.$store.dispatch('vwToStart');
            },
            updateDrawerSlideLiked() {
                //console.log('updateDrawerSlideLiked', this.viewerState.drawerPageId, this.viewerState.drawerResourceId);
                // we have to manually update likes as ViewerLikes.isPageLiked is not reactive
                this.drawerSlideLiked = false;
                if (this.viewerState.drawerResourceId) {
                    this.drawerSlideLiked = ViewerLikes.isResLiked(this.scData.presentation.id, this.viewerState.drawerResourceId);
                }   else if (this.viewerState.drawerPageId) {
                    this.drawerSlideLiked = ViewerLikes.isPageLiked(this.scData.presentation.id, this.viewerState.drawerPageId);
                }
            },
            toggleFullscreen() {
                this.$store.dispatch('vwFullscreen');
                ViewerEmbedHandler.updateLastShowcaseShownInFullscreen();
            },
            openAddTagModal () {
                this.$store.commit('vwAddTagModalToggle', {addTagModalShown: true});
            }

        },

        computed: {
            options() {
                return this.$store.state.vw.options;
            },
            viewerState() {
                return this.$store.state.vw.viewerState;
            },
            scData() {
                return this.$store.state.vw.scData;
            },
            drawerShown() {
                //console.log('recompute drawerShown', this.viewerState.drawerPageId, this.viewerState.drawerResourceId);
                return this.viewerState && (this.viewerState.drawerPageId || this.viewerState.drawerResourceId);
            },
            drawerLikeShown() {
                if (this.viewerState.drawerPageId) return true;
                return !!(this.drawerResource.content_type === 'movie' ||
                    (this.drawerResource.content_type === 'document' && this.drawerResource.suffix === 'pdf'));
            },
            alignWidgetsToEdgeOfPres() {
                if (!this.viewerState) return false;
                return (this.viewerState.pageId && !this.viewerState.resourceId) &&
                    this.viewerState.frameEleWidth > (
                        this.viewerState.presScaledWidth + (WIDGET_BOX_SIZE*2));
            },
            widgetEdgeWhenAlignedToPres() {
                if (!this.viewerState) return 0;
                return ((this.viewerState.frameEleWidth - this.viewerState.presScaledWidth) / 2) - WIDGET_BOX_SIZE;
            },
            topLeftIconStyleObj() {
                return {top: '0px', left: this.widgetsXPos + 'px'}
            },
            topRightIconStyleObj() {
                return {top: '0px', right: this.widgetsXPos + 'px'}
            },
            bottomLeftIconStyleObj() {
                return {bottom: '0px', left: this.widgetsXPos + 'px'}
            },
            drawerStyleObj() {
                return {
                    display: 'block',
                    width: '260px', height: '100%',
                    left: 0, top: 0
                }
            },
            widgetsXPos: function() {
                return (this.alignWidgetsToEdgeOfPres ? this.widgetEdgeWhenAlignedToPres : 0);
            },
            showDrawerOpenBtn: function() {
                let showBtn = !this.drawerShown;
                if (showBtn && this.viewerState.resourceIsOpeningVideo && !this.viewerState.pageId) {
                    showBtn = false;
                }
                else if (showBtn && this.viewerState.pageId) {
                    showBtn = true;
                }
                else if ( showBtn && this.currentResource) {
                    showBtn = !!(this.currentResource.content_type === 'movie' ||
                        (this.currentResource.content_type === 'document' && this.currentResource.suffix === 'pdf'));
                }
                return showBtn;
            },
            currentResource: function() {
                if (!this.viewerState.resourceId) return null;
                return _.findWhere(this.scData.resource, {id: this.viewerState.resourceId});
            },
            shareShown() {
                if (!this.scData) return false;
                let pageId = this.viewerState.resourceId ? this.viewerState.resourcePageId : this.viewerState.pageId;
                let showingOpeningVideo = this.viewerState.resourceId && this.viewerState.resourceIsOpeningVideo;
                let uptShareEnabled = this.options.shareEnabled && ViewerShareUtil.hasSharedItemsImpl(this.scData, pageId,
                    this.viewerState.resourceId) && !showingOpeningVideo;
                if ( uptShareEnabled && this.viewerState.resourceId ) {
                    let currResource = _.findWhere(this.scData.resource, {id: this.viewerState.resourceId});
                    if ( currResource && currResource.content_type === 'weburl') {
                        uptShareEnabled = false;
                    }
                }
                return uptShareEnabled;

            },
            showNextPrev() {
                if (!this.scData) return false;
                let showNextPrevBool = ViewerEmbedHandler.shouldDisplayNextPrevArrowsWhenSettingUndefined();
                if (typeof this.scData.presentation.viewer_arrows_enabled === "boolean")
                    showNextPrevBool = this.scData.presentation.viewer_arrows_enabled;
                return showNextPrevBool;
            },
            drawerResource() {
                if (!this.scData) return false;
                return _.findWhere(this.scData.resource, {id: this.viewerState.drawerResourceId});
            }
        },

        watch: {
            'viewerState.drawerPageId'() {
                this.updateDrawerSlideLiked();
            },
            'viewerState.drawerResourceId'() {
                this.updateDrawerSlideLiked();
            }
        }

    }

</script>


<style>

    .scMainButtonImg4c {
        width: 45px;
        height: 45px;
    }

    .scDrawerButtonImg {
        width: 45px;
        height: 45px;
    }

    .scMainButtonImg {
        outline: none;
        opacity: 0.5
    }

    .scNextPrevImg {
        outline: none;
        opacity: 0.8
    }

    .widgetsDimmed .scMainButtonImg {
        opacity: 0.2
    }
    .widgetsDimmed .scNextPrevImg {
        opacity: 0.5
    }


</style>