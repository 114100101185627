<template>
    <ScLayoutNoAuth>
        <div>
             <!-- EMAIL VERIFICATION BLOCK -->
             <div v-if="formMode === 'validate_email'"  class="row justify-content-center">
                 <form class="form signup-form" @submit.prevent="validateEmailClick(false)">

                     <h2 class="text-white text-center">Create your account</h2>

                     <div class="my-4 form-floating">
                         <input class="form-control auth_email w-100" id="auth_user_email2" name="email"
                                type="email" placeholder="Work email" v-model="emailAddress"
                                autofocus :disabled="validateEmailLoading" autocomplete="email"
                                @keyup.enter.prevent="validateEmailClick(false)"/>
                         <label for="auth_user_email2" class="text-body">Work email</label>
                     </div>

                     <div class="mb-3 text-center">
                         <button type="submit" @click.prevent="validateEmailClick(false)"
                                 :disabled="validateEmailLoading"
                                 class="btn btn-primary-green fw-bold shadow" style="min-width: 7rem;">
                             <ScIcon v-if="validateEmailLoading" name="spinnerFW" class="mx-3" />
                             <span v-else>Continue</span></button>
                     </div>
                     <div class=" mb-3 text-center">
                         <router-link :to="{name: 'workshop-default'}"
                                      class="btn btn-link btn-link-signup px-0 text-white">
                             <ScIcon name="chevronLeftFW"/> Back</router-link>
                     </div>

                 </form>
             </div>

             <!-- AZURE SCREENS -->
             <div v-if="formMode === 'forced_azure_signup'" class="row justify-content-center">
                 <div class="col-12">
                     <div class="form signup-form mt-4">
                         <div  class="form-group ">
                             <div class="alert alert-info" >
                                 Your email address is associated with an organisation that already uses Showcase.
                                 Redirecting to Microsoft sign-in. <ScIcon name="spinnerFW" class="ms-2"/>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
            <div v-if="formMode === 'optional_azure_signup'" class="row justify-content-center">
                <div class="col-12">
                    <div class="form signup-form mt-4">
                        <div class="form-group">
                            <div class="alert alert-info">
                              Your email address is associated with an organisation that already uses Showcase.
                              You can sign in with Microsoft, or use your email and password to sign in.
                            </div>
                            <div class="text-center mt-4 mb-2"><a :href="optionalAzureLoginUrl">
                                <!-- https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-azure-ad-apps -->
                                <img :src="mainMxCdn('/showcase-icons/7.1.1/ms-symbollockup_signin_light.svg')"
                                     style="width: 214px; height: 41px;" alt="Sign in with Microsoft" />
                            </a></div>
                            <div class="text-center mb-2">or</div>
                            <div class="text-center mb-3"><button type="button" class="btn btn-primary-green text-white fw-bold shadow"
                              @click.prevent="formMode='confirm_email'" style="width: 214px;">Use Email</button></div>
                        </div>
                    </div>
                </div>
              <div class="col-12 mb-3 text-center">
                <button type="button" class="btn btn-link text-white" @click.prevent="formMode='validate_email'">
                  <ScIcon name="chevronLeftFW"/> Back</button>
              </div>
            </div>

            <div v-if="formMode === 'confirm_email'" class="row justify-content-center">
                <div class="form-row">
                    <div class="col-12 mb-3 text-center">Cool, we'll email a signup code to:
                        <p class="pt-2 mb-2"><strong>{{emailAddress}}</strong></p>

                        Is that right?</div>
                    <div class="col-12 mb-3 text-center">
                        <button type="submit" @click.prevent="validateEmailClick(true)" :disabled="validateEmailLoading"
                                value="Send email" class="btn btn-primary-green fw-bold shadow">
                            <ScIcon v-if="validateEmailLoading" name="spinnerFW" class="mx-3" />
                            <span v-else>Yes, send that code!</span></button>
                    </div>
                    <div class="col-12 mb-3 text-center">
                        <button type="button" @click.prevent="formMode='standard_signup'" :disabled="validateEmailLoading"
                                tabindex="3" class="btn btn-link btn-link-signin col-sm-8 col-sm-offset-2 mt-3 px-0 fw-normal text-white text-decoration-underline opacity-75">
                            I have a code already</button>
                    </div>
                    <div class="col-12 mb-3 text-center">
                        <button type="button" class="btn btn-link text-white" @click.prevent="formMode='validate_email'">
                            <ScIcon name="chevronLeftFW"/> Back</button>
                    </div>
                </div>
            </div>

            <!-- SIGNUP FORM -->
             <div v-if="formMode === 'standard_signup'" class="row justify-content-center">
                 <form class="form standard-signup-form">

                     <h2 class="text-white text-center">Choose a password</h2>

                     <div class="mb-1 form-floating">
                         <input class="form-control w-100 border-0 text-white bg-none"
                                id="auth_user_signup_email" name="auth_user_signup_email" type="text"
                                placeholder="for email" v-model.trim="emailAddress" disabled/>
                         <label for="auth_user_signup_email" class="text-white fw-bold">for email</label>
                     </div>

                     <div class="mb-3 form-floating">
                         <input class="form-control w-100" id="auth_user_signup_code" name="signup_code" type="text"
                                    autocomplete="signup-code" placeholder="Code from email" v-model.trim="signupCode"
                                    maxlength="10" :disabled="signupSaving || signupDoneLoginLoading"/>
                         <label for="auth_user_signup_code" class="text-body">Signup code</label>
                     </div>

                     <div class="mb-3 form-floating">
                         <input class="password form-control w-100" id="auth_user_password" name="password"
                                    type="password" autocomplete="new-password"
                                    :placeholder="minPwdLength + ' characters minimum'" v-model.trim="password"
                                    :maxlength="maxPwdLength" :disabled="signupSaving || signupDoneLoginLoading"/>
                         <label for="auth_user_password" class="text-body">Create password</label>
                     </div>

                     <div class="mt-3 mb-2 text-center">
                         <button type="submit" @click.prevent="verifyCodeSavePassword()" :disabled="signupSaving || signupDoneLoginLoading"
                                 class="btn btn-primary-green fw-bold shadow">
                             <ScIcon v-if="signupSaving || signupDoneLoginLoading" name="spinnerFW" class="mx-4" />
                             <span v-else>Continue</span></button>

                         <div class="mt-2"><small>One more step!</small></div>
                     </div>
                     <div class="mb-3 text-center">
                         <button type="button" @click.prevent="formMode = 'confirm_email'" :disabled="validateEmailLoading"
                                 tabindex="3" class="btn btn-link btn-link-signin col-sm-8 col-sm-offset-2 mt-3 px-0 fw-normal text-white text-decoration-underline opacity-75 ">
                             Resend signup code</button>
                     </div>
                     <div class="mb-3 text-center">
                         <button type="button" class="btn btn-link text-white" @click.prevent="formMode = 'confirm_email'">
                             <ScIcon name="chevronLeftFW"/> Back</button>
                     </div>

                 </form>
             </div>

        </div>
    </ScLayoutNoAuth>
</template>

<script>

    import $ from 'jquery';
    import ScVueTrackEvents from '../global/ScVueTrackEvents';
    import MainAppMixin from '../MainAppMixin';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScLayoutNoAuth from '../global/ScLayoutNoAuth.vue';

    export default {
        name: "SignupForm",
        mixins: [MainAppMixin],
        components: {ScIcon, ScLayoutNoAuth},
        data () {
            return {
                formMode: 'validate_email',  // validate_email, standard_signup, confirm_email, forced_azure_signup, optional_azure_signup
                optionalAzureLoginUrl: null,
                validateEmailLoading: false,
                emailAddress: null,
                signupCode: null,
                password: null,
                minPwdLength: 6,
                maxPwdLength: 32,
                verificationEmailSaving: false,
                signupDoneLoginLoading: false,
                signupSaving: false,
                signupEnabled: false
            }
        },
        mounted () {
          ScVueTrackEvents.trackSignupPageView();
          this.signupEnabled = true;
          this.emailAddress = this.$route.query && this.$route.query.e ? this.$route.query.e : null;
          this.signupCode = this.$route.query && this.$route.query.sc ? this.$route.query.sc : null;
          $('#auth_user_email').focus();

          this.validateEmailLoading = true;
          this.$store.dispatch('userPrefsLoad').then(() => {  // check to see if user already logged in
              this.validateEmailLoading = false;
              if (this.$store.state.user.user_id) {
                  if (this.$store.state.user.termsAccepted) {
                      ScNotification.growlErrMsg('You\'re already logged in as '+
                          this.$store.state.user.email + '! Log out to sign up.')
                  } else {
                      ScNotification.growlSuccessMsg('You\'re almost done signing up as ' +
                          this.$store.state.user.email + '. Please accept terms.')
                  }
                  this.$router.push({name: 'workshop-default'});
              } else {
                  if (this.emailAddress && this.emailAddress.length > 0) {
                      this.validateEmailClick(false);
                  }
                  this.loadEmailFromLocalStorage();
              }
          });
        },
        methods: {
            loadEmailFromLocalStorage() {
                let lsEmail = null;
                try {
                    if (window.localStorage) lsEmail = window.localStorage.getItem('scLastEmail');
                } catch(e) {
                    // ignore
                }
                if (lsEmail && !this.emailAddress) this.emailAddress = lsEmail;
            },
            validateEmailClick (sendVerificationEmail) {
                ScNotification.clear();
                if (!ScCommonUtil.validateEmail(this.emailAddress)) {
                    ScNotification.growlErrMsg('Please enter a valid email address');
                } else {
                    if (sendVerificationEmail) this.signupCode = null;
                    this.validateEmailLoading = true;
                    this.optionalAzureLoginUrl = null;
                    this.$store.dispatch('userLoginValidateEmail', {
                        email: this.emailAddress, sendCode: sendVerificationEmail
                    }).then((res) => {
                        this.validateEmailLoading = false;
                        if (res && res.user_exists) {
                            ScNotification.growlErrMsg('We found an existing Showcase account with that email! To login, hit "Back" below.');
                        } else if (res && res.login_requirements_type === 'oauth_force_v1') {
                            this.formMode = 'forced_azure_signup';
                            document.location = res.login_requirements_url
                        }   else if (res && res.login_requirements_type === 'oauth_optional_v1') {
                            this.formMode = 'optional_azure_signup';
                            this.optionalAzureLoginUrl = res.login_requirements_url;
                        }   else if (res && res.login_requirements_type === 'standard_v1' && this.signupCode ) {
                            this.formMode = 'standard_signup'
                        }   else if (res && res.login_requirements_type === 'standard_v1' && res.prevent_signup_message && !this.signupCode) {
                            ScNotification.growlErrMsg(res.prevent_signup_message);
                        }   else if (res && res.login_requirements_type === 'standard_v1' && !sendVerificationEmail ) {
                            this.formMode = 'confirm_email';
                        }   else if (res && res.login_requirements_type === 'standard_v1' && sendVerificationEmail ) {
                            if (res.signup_code_error) {
                                ScNotification.growlErrMsg(res.signup_code_error);
                            }   else {
                                this.formMode = 'standard_signup';
                            }
                        }
                    }).catch(() => {
                        this.validateEmailLoading = false;
                    });
                }
            },
            validateModel  () {
                let modelValid = true;
                if (!ScCommonUtil.validateEmail(this.emailAddress)) {
                    ScNotification.growlErrMsg('Email address is not valid');
                    modelValid = false;
                } else if (!this.signupCode) {
                    ScNotification.growlErrMsg('Please enter the code from your email');
                    modelValid = false;
                } else if (!this.password || (this.password &&
                        (this.password.length < this.minPwdLength || this.password.length > this.maxPwdLength))) {
                    ScNotification.growlErrMsg(`Please enter a password that is at least ${this.minPwdLength}
                        characters long (maximum ${this.maxPwdLength} characters)`);
                    modelValid = false;
                }
                return modelValid;
            },

            verifyCodeSavePassword() {
                let valid = this.validateModel();
                if (valid) {
                    this.signupSaving = true;
                    $.ajax({
                        method: 'post', url: '/main/signup/ajax_web_accept_v2',
                        data: { email_address: this.emailAddress, signup_code: this.signupCode, password: this.password,
                                client_name: 'browser'}
                    }).then((data) => {
                        if (data.status === 'ok') {
                            ScVueTrackEvents.trackSignupEvent('signup', 'completed');
                            this.signupDoneNowLogin();
                        } else {
                            ScNotification.growlErrMsg(data.details);
                        }
                    }).fail((jqXhr) => {
                        let serverErrorCode = 'server_error_' + jqXhr.status;
                        ScVueTrackEvents.trackSignupEvent('signup', serverErrorCode);
                        ScNotification.growlXhrError(jqXhr, 'trying to signup');
                    }).always(() => {
                        this.signupSaving = false;
                    });
                }
            },
            signupDoneNowLogin: function() {
                this.signupDoneLoginLoading = true;
                this.$store.dispatch('userLoginPost', {
                    email: this.emailAddress, pwd: this.password
                }).then(() => {
                    return this.$store.dispatch('userPrefsLoad');
                }).then(() => {
                    this.$router.push({name: 'workshop-default'});
                    this.signupDoneLoginLoading = false;
                }).catch(() => {
                    this.signupDoneLoginLoading = false;
                });
            },
        },
        watch: {
            emailAddress() {
                try {
                    if (window.localStorage) window.localStorage.setItem('scLastEmail', this.emailAddress);
                } catch(e) {
                    // ignore
                }
            }
        }

    }
</script>

<style scoped>

</style>