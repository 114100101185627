<template>
  <transition name="sc-transition-fade" appear mode="in-out" v-on:after-enter="afterEnter">
    <div v-if="!showcaseViewerActive" class="viewer-pres-loading text-center">
      <ScIcon name="spinnerMedium" class="text-muted"/>
    </div>
    <ShowcaseViewer v-else
                    :data="showcaseViewerData"
                    :userOptions="showcaseViewerOptions"
                    :kioskMode="kioskModeEnabled"
                    @showcase-viewer-exit="showcaseViewerExitEvt"></ShowcaseViewer>
  </transition>
</template>

<script>

    import $ from 'jquery';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import MainAppMixin from '../MainAppMixin';
    import ViewerLikes from '../../shared/viewer/ViewerLikes';
    import ShowcaseViewer from '../../shared/viewer/ShowcaseViewer.vue';
    import ViewerUtil from '../../shared/viewer/ViewerUtil';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
      name: "BrowserViewerPresentation",
      components: {ShowcaseViewer, ScIcon},
      mixins: [ MainAppMixin ],
      props: {
        useDraft: {type:Boolean, default: false}
      },
      data () {
        return {
          kioskModeEnabled: false,
          showcaseViewerActive: false,
          showcaseViewerOptions: null,
          showcaseViewerData: null,
          fetchedData: {},
          compExiting: false,
          loadPageId: null,
          loadResId: null
        };
      },
      mounted () {
        this.compExiting = false;
        this.loadPageId = this.$route.query.pid ? Number(this.$route.query.pid) : null;
        this.loadResId = this.$route.query.rid ? Number(this.$route.query.rid) : null;
        if (this.loadResId) this.loadPageId = null;

        if (!this.useDraft) {  // used via the "Present" tab
          this.kioskModeEnabled = this.$store.state.user.viewer_kiosk_mode;
        }
        ViewerLikes.initLikes(this.mainMxCurrentWorkshopId);
        // note, methods -> afterEnter runs after the transition is complete
      },
      beforeUnmount () {
        this.$store.commit('userViewerKioskModeToggle', false); // reset kiosk mode
        this.compExiting = true;
        if ( this.showcaseViewerActive ) {
          this.showcaseViewerActive = false;
        }
        $('body').removeClass('scviewer-active');  // in case showcaseViewerExitEvt doesn't fire
      },

      methods: {
        afterEnter () {  // do load pres after transition so that we are a little more graceful
          this.loadPres(Number(this.$route.params.showcaseId));
        },
        showcaseViewerExitEvt () {
          $('body').removeClass('scviewer-active');
          if (!this.compExiting) {
            this.$router.push({name: this.$route.meta.scParentRouteName});
          }
        },
        loadPresWithData (data) {
          //console.log('displayPresWithData');
          this.showcaseViewerData = data.export;

          // useDraft=true - being used as a preview
          // useDraft=false - used via the "Present" tab
          let introVideosDisabled = (this.useDraft) ? !!this.loadPageId : this.$store.state.user.viewer_intro_video_disabled.includes(data.presentation_id);

          this.showcaseViewerOptions = {
            workspaceId: this.mainMxCurrentWorkshopId,
            userId: this.$store.state.user.user_id,
            userEmail: this.$store.state.user.email,
            userDisplayName: this.$store.state.user.displayable_name,
            sharedId: null,
            expired: this.$store.getters.userCurrentWorkspaceObj.expired,
            kioskMode: this.kioskModeEnabled,
            enableOpeningScene: !introVideosDisabled,
            userTagsEnabled: data.user_tags_enabled,
            openAtPageId: this.loadPageId,
            openAtResId: this.loadResId,
            recordAnalytics: !this.useDraft,
            enableFullTextSearch: !this.useDraft,
            workshopHeaderImg: (this.mainMxCurrentWorkshop) ? this.mainMxCurrentWorkshop.header_img : null,
            webUrlUserJwt: this.$store.getters.userCurrentWorkspaceObj.weburl_user_jwt
          };

          $('body').addClass('scviewer-active');
          this.showcaseViewerActive = true;
        },
        loadPres: function(presId) {
          //console.log('loadPres', presId, this.fetchedData[presId]);
          this.showcaseViewerActive = false;
          if ( this.fetchedData[presId]) {
            this.loadPresWithData(this.fetchedData[presId]);
          }
          else {
            let url = '/api/v1/showcases/'+presId;
            let urlArgs = {workspace_id: this.mainMxCurrentWorkshopId};
            if (this.useDraft) {
              url = '/main/editor/ajax_draft_showcase'
              urlArgs = {workspace_id: this.mainMxCurrentWorkshopId, presentation_id: presId}
            }
            $.ajax({
              method: 'GET', url: url, data: urlArgs
            }).done((data) => {
              //console.log('got', data);
              if (data.error===true) {
                ScNotification.growlErrMsg(data.details);
                this.$router.back();
              } else {
                ViewerUtil.updateForAltVideoVersions(data.export);
                this.fetchedData[presId] = data;
                this.loadPresWithData(data);
              }
            }).fail((jqXhr) => {
              if (jqXhr.status === 404 || jqXhr.status === 403) {
                ScNotification.growlErrMsg('Presentation is no longer available');
              }   else {
                ScNotification.growlXhrError(jqXhr, 'loading Presentation');
              }
              this.$router.back();
            });
          }
        },
      }
    }


</script>

<style scoped>

  .viewer-pres-loading {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 45vh;
    min-height: 100vh;
    min-width: 100vw;
    background-color: black;
    z-index: 11003000;  /* see README.md webapp z-index values */
  }

</style>
