<template>
    <span>
        <span v-if="newShareState.shareStage === 'select-type'">
            <button class="btn btn-primary-orange fw-bold" type="button"
                    @click.prevent="$store.dispatch('newShareFilesSelected')"
                    :disabled="newShareState.linkSaveLoading">
                <span v-if="!newShareState.linkSaveLoading">Next</span>
                <ScIcon v-else name="spinnerFW" class="mx-3"/>
            </button>
        </span>
        <span v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'edit'">
            <button v-if="newShareState.emailPreviewEnabled" class="btn btn-primary-orange fw-bold" type="button"
                    @click.prevent="$store.dispatch('newShareEmailPreview')" :disabled="newShareState.emailPreviewLoading">
                <span v-if="!newShareState.emailPreviewLoading">Preview</span>
                <ScIcon v-else name="spinnerFW" class="mx-3"/>
            </button>
            <button v-else @click.prevent="$store.dispatch('newShareEmailSave')" class="btn btn-primary-orange fw-bold"
                    :disabled="newShareState.emailSaveLoading" type="button">
                <span v-if="!newShareState.emailSaveLoading">Send</span>
                <ScIcon v-else name="spinnerFW" class="mx-3"/>
            </button>
        </span>
        <span v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'preview'">
            <button @click.prevent="$store.dispatch('newShareEmailSave')" class="btn btn-primary-orange fw-bold"
                    :disabled="newShareState.emailSaveLoading" type="button">
                <span v-if="!newShareState.emailSaveLoading">Send</span>
                <ScIcon v-else name="spinnerFW" class="mx-3"/>
            </button>
        </span>
        <span v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'save'">
            <slot name="nextLinkEmailSave"></slot>
        </span>
        <span v-if="newShareState.shareType === 'link-basic' && newShareState.shareStage === 'save'">
            <slot name="nextLinkSave"></slot>
        </span>
        <span v-if="newShareState.shareType === 'link-vip-pass' && newShareState.shareStage === 'edit'">
            <button class="btn btn-primary-orange fw-bold" @click.prevent="$store.dispatch('newShareLinkPreview')"
                    :disabled="newShareState.linkPreviewLoading" type="button">
                <span v-if="!newShareState.linkPreviewLoading">Preview</span>
                <ScIcon v-else name="spinnerFW" class="mx-3"/>
            </button>
        </span>
        <span v-if="newShareState.shareType === 'link-vip-pass' && newShareState.shareStage === 'preview'">
            <button @click.prevent="$store.dispatch('newShareLinkSave')" class="btn btn-primary-orange fw-bold"
                    :disabled="newShareState.linkSaveLoading" type="button">
                <span v-if="!newShareState.linkSaveLoading">Save</span>
                <ScIcon v-else name="spinnerFW" class="mx-3"/>
            </button>
        </span>
        <span v-if="newShareState.shareType === 'link-vip-pass' && newShareState.shareStage === 'save'">
            <slot name="nextLinkSave"></slot>
        </span>
    </span>
</template>

<script>
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: "NewShareNextButton",
        components: {ScIcon},
        computed: {
            newShareState() {
                return this.$store.state.newShare;
            },
        },
    }
</script>
