<template>
    <ScNormalPageLayout headingPart1="Search">
        <div class="mb-5">
            <div class="container mt-3 mt-sm-5">
                <div class="row justify-content-center">
                    <SearchAllComp class="col-12"
                        :workshopId="mainMxCurrentWorkshopId"
                        @pres-click="presClick"
                        @page-click="pageClick"
                        @res-click="resClick"
                        :noResultsMsg="'No results in published presentations'"></SearchAllComp>
                </div>
            </div>

            <!-- for child routes -->
            <router-view></router-view>

        </div>
    </ScNormalPageLayout>
</template>

<script>

    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import MainAppMixin from '../MainAppMixin';
    import SearchAllComp from '../../shared/viewer/SearchAllComp.vue';

    export default {
        name: "SearchAllPage",
        components: {ScNormalPageLayout, SearchAllComp},
        mixins: [ MainAppMixin ],
        methods: {
            presClick(presId) {
                this.$router.push({name: 'search-all-viewer-presentation', params: {
                        workshopId: this.mainMxCurrentWorkshopId, showcaseId: presId}})
            },
            pageClick(presId, pageId) {
                this.$router.push({name: 'search-all-viewer-presentation', params: {
                        workshopId: this.mainMxCurrentWorkshopId, showcaseId: presId},
                    query: {pid: pageId}})
            },
            resClick(presId, resId) {
                this.$router.push({name: 'search-all-viewer-presentation', params: {
                        workshopId: this.mainMxCurrentWorkshopId, showcaseId: presId},
                    query: {rid: resId}})
            },
        }
    }
</script>
