<template>
    <transition :name="viewerState.searchTransition" appear @after-enter="afterEnter">
        <div class="search-dialog bg-white" :style="{zIndex: searchDialogZIndex}">
            <div class="search-dialog-header bg-white d-flex justify-content-between align-items-start"
                 :style="{zIndex: searchDialogZIndex+1}">
                <div class="row g-0 w-100 my-2">
                    <div class="col-auto me-auto m-0 ps-2 mx-1 ms-sm-2 d-flex align-items-center">
                        <a href="#" @click.prevent="$store.dispatch('vwBack')">
                            <img :src=" options.staticRoot + '/showcase-icons/5.0.0/back_button.png'" alt="Back"
                                 style="width: 45px; height: 45px;"/>
                        </a>
                    </div>
                    <div class="col-auto m-0 p-0 mx-1 mx-sm-2 d-flex align-items-center ms-auto">
                        <form class="form" @submit.prevent>
                            <div class="input-group">
                                <input type="search" class="form-control px-sm-3 sc-search-query" name="search"
                                       placeholder="Search" :style="{width: '21vw', 'min-width': '100px'}"
                                       @keyup.enter.prevent
                                       @focus="searchFocused" v-model.trim="searchForText" />

                                <button @click.prevent class="btn btn-outline-secondary input-group-text" type="button">
                                    <ScIcon v-if="searchingLocally || searchingAtServer || searchDelayPending"
                                            name="spinnerFW" class="text-muted"/>
                                    <ScIcon v-else name="search" class="text-muted"/>
                                </button>
                            </div>
                        </form>
                        <div v-if="options.likeEnabled" class="btn-group ms-2" role="group">
                            <button type="button"
                                    :class="['btn btn-outline-secondary px-sm-3', currentAllToggleState === 'all' ?  'active fw-bold':'']"
                                    @click.prevent="setAllToggle('all', $event)">All</button>
                            <button type="button"
                                    :class="['btn btn-outline-secondary px-sm-3', currentAllToggleState === 'liked' ? 'active fw-bold':'']"
                                    @click.prevent="setAllToggle('liked', $event)">
                                <span class="d-none d-sm-inline">Favorites</span>
                                <span class="d-inline d-sm-none"><ScIcon name="heart"/></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="search-dialog-body m-0 p-0 w-100">
                <div class="m-0 pt-0 pe-3 pb-3 ps-3" style="-webkit-overflow-scrolling: touch;">

                    <div v-if="loadedTagsForShowcase.length > 0" class="ms-3 mt-4">
                        <template v-for="tag in loadedTagsForShowcase">
                            <a href="#" v-if="tag.tagged_pages.length > 0" :key="'left-panel-' + tag.tag_uid"
                               @click.prevent="selectViewerTag(tag)" class="d-inline-block">
                                <span class="badge bg-primary fw-bold fs-md mb-0 me-2 mb-2"
                                      v-sc-tooltip="tag.tag_name">#{{ tag.tag_name }}</span>
                            </a>
                        </template>
                    </div>

                    <div v-if="pageSearchResults.length > 0" class="d-flex flex-wrap">
                        <ViewerSearchResultPage v-for="pageMatch in pageSearchResults" :key="'rs'+ pageMatch.page.id"
                            :pageMatch="pageMatch" :thumbUrl="thumbUrlForPage(pageMatch.page)"
                            :thumbWidth="thumbWidthForPages" :thumbHeight="thumbHeightForPages"
                            @page-click="$store.dispatch('vwPage', {pageId: $event, direction: 'up'})"></ViewerSearchResultPage>
                    </div>
                    <div v-if="resSearchResults.length > 0" class="d-flex flex-wrap">
                        <ViewerSearchResultResource v-for="(resMatch, fIdx) in resSearchResults" :key="'fm'+fIdx"
                            @res-click="resClick(resMatch)"
                            :resMatch="resMatch"></ViewerSearchResultResource>
                    </div>
                    <div v-if="pageSearchResults.length >= maxResultsShown || resSearchResults.length >= maxResultsShown"
                         class="clearfix modal-body-sc-viewer-search-no-matches text-center text-muted my-4">
                        <em>The first {{maxResultsShown}} slides and files that match are shown.</em>
                    </div>
                    <div v-if="!searchingLocally && pageSearchResults.length === 0 && resSearchResults.length === 0"
                        class="clearfix modal-body-sc-viewer-search-no-matches text-center text-muted w-100"
                         style="padding-top: 40vh;">
                        <em v-if="!searchForText && currentAllToggleState === 'liked'">No favorites</em>
                        <em v-else-if="!searchForText">No slides or files</em>

                        <em v-else-if="searchForText && currentAllToggleState === 'liked'">Your favorites search </em>
                        <em v-else-if="searchForText">Your search </em>

                        <em v-if="searchForText && (!options.enableFullTextSearch || searchingAtServer || searchDelayPending)">
                            did not match any file names or slide names</em>
                        <em v-else-if="searchForText">
                            did not match any file names, slide names,<br/> hotspot text or PDF contents</em>
                        <br/>
                        <ScIcon v-if="searchingLocally || searchingAtServer || searchDelayPending"
                                name="spinnerFW" class="mt-3 text-muted" />
                    </div>
                    <!-- take up space so that the user can tell it's the bottom easily -->
                    <div class="my-3"></div>
                </div>
            </div>
        </div>
    </transition>
</template>



<script>

import _ from 'underscore'; // indexOf, sortBy, where, clone, debounce, isEmpty, findWhere
import $ from 'jquery';

import ViewerLikes from './ViewerLikes';
import ViewerPinModal from './ViewerPinModal.vue';
import ScNotification from '../common/ScNotification.vue'
import ViewerSearchResultPage from './ViewerSearchResultPage.vue'
import ViewerSearchResultResource from './ViewerSearchResultResource.vue'
import ScIcon from '../common/ScIcon.vue';
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';

let _searchStateById = [];
let _nextSearchId = 0;

/* public */
/* public */
/* public */
/* public */

export default {
    name: 'ViewerSearch',
    components: {ViewerSearchResultPage, ViewerSearchResultResource, ScIcon},
    props: {
        //
    },
    data: function() {
        return {
            rootPageId: null,
            searchingLocally: false,
            searchDelayPending: false,
            searchingAtServer: false,
            searchForText: null,
            currentAllToggleState: 'all',
            allPageMatches: [],
            allResMatches: [],
            pageSearchResults: [],
            resSearchResults: [],
            backHistoryLengthAtMount: 0,
            maxResultsShown: 100,
        }
    },

    mounted: function() {
        this.backHistoryLengthAtMount = this.viewerState.backHistory.length;
        this.searchingLocally = true;
        // see methods/afterEnter
    },

    methods: {
        afterEnter: function() {  // called after enter animation done
            if (!this.searchId) {
                this.searchingLocally = false;
                return;
            }
            let searchByStateForId = _searchStateById[this.searchId];
            this.searchForText = searchByStateForId ? searchByStateForId.searchForText : '';
            this.currentAllToggleState = searchByStateForId ? searchByStateForId.currentAllToggleState : 'all';

            this.$store.dispatch('vwRecordScAnalytics', {alType: 'search_view', alObj: {
                showcase_id: this.scData.presentation.id, showcase_version: this.scData.presentation.version,
                page_id: this.viewerState.pageId, resource_id: this.viewerState.resourceId
            }, alWsId: this.options.workspaceId, alUsId: this.options.userId, alShId: this.options.sharedId});

            setTimeout(() => {  // delay slightly to allow ui to update
                this.populateAllPages();
                this.populateAllRes();
                this.searchNow();
            });
        },
        populateAllPages: function() {
            // collect up all pages that we can navigate to from the root
            this.allPageMatches.splice(0);
            let donePageListIds = [];
            let donePageIds = [];
            let walkTree = (pageListId) => {
                if (_.indexOf(donePageListIds, pageListId) > -1) return;  // done already
                donePageListIds.push(pageListId);

                let pages = _.sortBy(_.where(this.scData.page, {pagelist_id: pageListId}), 'sort_order');
                pages.forEach((page) => {
                    if (!this.rootPageId) this.rootPageId = page.id;
                    if (_.indexOf(donePageIds, page.id) > -1) return;  // done already
                    donePageIds.push(page.id);
                    this.allPageMatches.push({toggleStateFound: true, pageId: page.id, page: page, score: 0, pageIsLiked: false});

                    let hotspots = _.where(this.scData.hotspot, {parent_page_id: page.id});
                    hotspots.forEach((hotspot) => {
                        if (hotspot.target_pagelist_id) {
                            if (!_.isEmpty(hotspot.passcode) && !ViewerPinModal.isRememberedHotspot(hotspot.id)) {
                                // ignore as it's protected
                            }   else {
                                walkTree(hotspot.target_pagelist_id);
                            }
                        }
                    });
                });
            };
            walkTree(this.scData.presentationmetadata.root_pagelist_id);

            // calculate what pages are liked
            this.allPageMatches.forEach((pageMatch) => {
                pageMatch.pageIsLiked = ViewerLikes.isPageLiked(this.scData.presentation.id, pageMatch.page.id);
            });
        },
        populateAllRes() {  // we only want "navigatable" files that are not behind a pin protected hotspot
            let doneResIds = [];
            this.allPageMatches.forEach((pageMatch) => {
                let hotspots = _.where(this.scData.hotspot, {parent_page_id: pageMatch.pageId});
                hotspots.forEach((hotspot) => {
                    if (hotspot.target_resource_id) {
                        if (!_.isEmpty(hotspot.passcode) && !ViewerPinModal.isRememberedHotspot(hotspot.id)) {
                            // ignore as it's protected
                        }   else {
                            if (_.indexOf(doneResIds, hotspot.target_resource_id) > -1) return;  // done already
                            doneResIds.push(hotspot.target_resource_id);
                            let resource = _.findWhere(this.scData.resource, {id: hotspot.target_resource_id});
                            if (resource && (resource.content_type === 'document'|| resource.content_type === 'movie')) {
                                this.allResMatches.push({toggleStateFound: true, resourceId: resource.id,
                                    resource: resource, score: 0
                                });
                            }
                        }
                    }
                });
            });
            this.allResMatches.forEach((resMatch) => {
                resMatch.resIsLiked = ViewerLikes.isResLiked(this.scData.presentation.id, resMatch.resourceId);
            });
        },
        searchFocused() {
            if (this.options.recordAnalytics) {
                ViewerEmbedHandler.recordUiAnalyticsEvent("search", "filter-focus");
            }
        },
        searchNow: function() {
            this.searchingLocally = true;
            let searchForText = this.searchForText;
            let allToggleState = this.currentAllToggleState;
            //console.log('searchNow', searchForText, allToggleState);

            searchForText = searchForText ? searchForText.toLocaleLowerCase() : '';

            this.allPageMatches.forEach((pageMatch) => {
                pageMatch.score = 0;
                if (allToggleState === 'liked') {  // hide/show based on toggle
                    pageMatch.toggleStateFound = pageMatch.pageIsLiked;
                } else {
                    pageMatch.toggleStateFound = true;
                }
                if (searchForText === '') {
                    pageMatch.score = 1;
                } else {
                    let page = pageMatch.page;
                    let pgTitle = page.title ? page.title.toLocaleLowerCase() : '';  // search for text in page title
                    let alphaName = page.alpha_num_name ? page.alpha_num_name.toLocaleLowerCase() : '';
                    if (alphaName.indexOf(searchForText) > -1) pageMatch.score = 100;
                    if (pgTitle.indexOf(searchForText) > -1) pageMatch.score = 90;
                }
                //console.log('pg match?', pageMatch.score, searchForText, pgTitle);
            });
            this.allResMatches.forEach((resMatch) => {
                resMatch.score = 0;
                if (allToggleState === 'liked') {  // hide/show based on toggle
                    resMatch.toggleStateFound = resMatch.resIsLiked;
                } else {
                    resMatch.toggleStateFound = true;
                }
                //console.log('searchForText', searchForText, resMatch);
                if (searchForText === '') {
                    resMatch.score = 1;
                } else {
                    let res = resMatch.resource;
                    let resName = res.name ? res.name.toLocaleLowerCase() : '';  // search for text in res name
                    if (resName.indexOf(searchForText) > -1) resMatch.score = 90;
                }
            });

            setTimeout(() => {  // try to keep ui responsive for large presentations
                this.updatePageSearchResults();
                this.updateResSearchResults();
                this.searchingLocally = false;
                this.searchAtServer();  // now enhance with results from the server if we can
            }, 1);
        },
        searchDelayed: _.debounce(function() { this.searchNow() }, 1000),

        searchAtServer: function() {
            if (!this.options.enableFullTextSearch) return;
            let searchForText = this.searchForText ? this.searchForText.toLocaleLowerCase() : '';
            if (!searchForText || searchForText.length < 3) return;

            this.searchDelayPending = true;
            this.searchAtServerDelayed();
        },
        searchAtServerDelayed: _.debounce(function() {
            this.searchDelayPending = false;
            this.searchingAtServer = true;
            let searchForText = this.searchForText ? this.searchForText.toLocaleLowerCase() : '';
            if (!searchForText || searchForText.length < 3) return;

            this.searchPresentationAtServer({
                workspaceId: this.options.workspaceId, presentationId: this.scData.presentation.id,
                presentationVersion: this.scData.presentation.version, searchTerm: searchForText,
                sharedKey: this.options.sharedKey
            }).then((data) => {
                Object.values(data.search_results).forEach((searchRes) => {
                    if (!searchRes || ! searchRes.score) return;  // invalid result
                    if (searchRes.page_id && !searchRes.resource_id) {
                        let pageMatch = _.findWhere(this.allPageMatches, {pageId: searchRes.page_id});
                        if (!pageMatch) return;
                        if (searchRes.score > pageMatch.score) {  // must be greater than offline score
                            pageMatch.score = searchRes.score;
                        }
                    }
                    if (searchRes.resource_id) {
                        let resMatch = _.findWhere(this.allResMatches, {resourceId: searchRes.resource_id});
                        if (!resMatch) return;
                        if (searchRes.score > resMatch.score) {  // must be greater than offline score
                            resMatch.score = searchRes.score;
                        }
                    }
                });
                this.updatePageSearchResults();
                this.updateResSearchResults();
                this.searchingAtServer = false;
            }, () => {
                this.searchingAtServer = false;
            });

        }, 2000),

        // todo: offline thumbs

        searchPresentationAtServer: function(opts) {
            let ajaxUrl = ViewerEmbedHandler.ajaxWrapUrl("/main/presentations/ajax_full_text_search");
            let ajaxData = { workspace_id: opts.workspaceId, presentation_id: opts.presentationId,
                presentation_version: opts.presentationVersion, search_term: opts.searchTerm };

            if (opts.sharedKey) {
                ajaxUrl = ViewerEmbedHandler.ajaxWrapUrl("/api/no_auth_v1/shared-advanced-search");
                ajaxData = { shared_key: opts.sharedKey, workspace_id: opts.workspaceId, search_term: opts.searchTerm };
            }

            return new Promise((resolve, reject) => {
                if (!navigator.onLine) {
                    reject();
                    return;
                }
                $.ajax({
                    type: "GET",
                    url: ajaxUrl,
                    data: ajaxData,
                    beforeSend: ViewerEmbedHandler.ajaxBeforeSend
                }).done(function(data){
                    resolve(data);
                }).fail(function(jqXhr) {
                    ScNotification.growlXhrError(jqXhr, "performing advanced search");
                    reject();
                });
            });
        },

        setAllToggle: function(allToggle, e) {
            if (e) e.target.blur();
            this.currentAllToggleState = allToggle;

            if (this.options.recordAnalytics && allToggle === 'all') {
                ViewerEmbedHandler.recordUiAnalyticsEvent("search", "toggle-all");
            } else if (this.options.recordAnalytics && allToggle === 'liked') {
                ViewerEmbedHandler.recordUiAnalyticsEvent("search", "toggle-favourites");
            }
            _searchStateById[this.searchId].currentAllToggleState = allToggle;
            this.searchDelayed();
        },
        updatePageSearchResults: function() {
            //console.log('updatePageSearchResults', this.allPageMatches);
            let sortedResults = [];
            this.allPageMatches.forEach((pageMatch) => {
                if (pageMatch && pageMatch.toggleStateFound && pageMatch.score > 0) {
                    sortedResults.push(pageMatch);
                }
            });
            let getPlSortNum = (pageMatch) => {
                return this.scData.presentationmetadata.root_pagelist_id === pageMatch.page.pagelist_id ?
                    0 : pageMatch.page.pagelist_id;
            }
            let getPageScore = (pageMatch) => {
                return 1 / ((!pageMatch.score) ? 0.00001 : pageMatch.score);  // just in case
            }
            sortedResults.sort((pageMatchA, pageMatchB) => {  // sort pages by pagelist then sort_order
                if (getPageScore(pageMatchA) < getPageScore(pageMatchB)) return -1;
                if (getPageScore(pageMatchA) > getPageScore(pageMatchB)) return 1;
                if (getPlSortNum(pageMatchA) < getPlSortNum(pageMatchB)) return -1;
                if (getPlSortNum(pageMatchA) > getPlSortNum(pageMatchB)) return 1;
                if (pageMatchA.page.sort_order < pageMatchB.page.sort_order) return -1;
                if (pageMatchA.page.sort_order > pageMatchB.page.sort_order) return 1;
                return 0;
            });

            this.pageSearchResults.splice(0);
            if (sortedResults) {
                sortedResults.forEach((pageMatch) => {
                    if (this.pageSearchResults.length < this.maxResultsShown) {  // limit results for rendering performance
                        this.pageSearchResults.push(pageMatch);
                    }
                });
            }
        },
        updateResSearchResults: function() {
            //console.log('updateResSearchResults', this.resSearchResults);
            let unsortedResults = [];
            this.allResMatches.forEach((resMatch) => {
                if (resMatch && resMatch.toggleStateFound && resMatch.score > 0) {
                    unsortedResults.push(resMatch);
                }
            });
            let sortedResults = _.sortBy(unsortedResults, (resMatch) => {  // sort res by score then name
                let res = resMatch.resource;
                if (!resMatch.score) resMatch.score = 0.00001;  // just in case
                return [1/resMatch.score, res.name];
            });
            this.resSearchResults.splice(0);
            if (sortedResults) {
                sortedResults.forEach((resMatch) => {
                    if (this.resSearchResults.length < this.maxResultsShown) {  // limit results for rendering performance
                        this.resSearchResults.push(resMatch);
                    }
                });
            }
        },
        thumbUrlForPage(page) {
            let thumbRes = this.scData.resource[String(page.resource_pagethumb_id)];
            if (!thumbRes) return null;
            return ViewerEmbedHandler.getResourceThumbUrl(thumbRes);
        },
        resClick(resMatch) {
            let pageId = this.rootPageId;
            let hotspot = _.findWhere(this.scData.hotspot, {target_resource_id: resMatch.resource.id});
            if (hotspot) pageId = hotspot.parent_page_id;
            this.$store.dispatch('vwResource', {pageId: pageId, resourceId: resMatch.resource.id, isOpeningVideo: false,
                isMovieLooping: false, isRecordingAnalytics: true, popHistory: false});
        },
        selectViewerTag (tag) {
            let tagged_pages = _.clone(tag.tagged_pages);
            this.$store.dispatch('vwPage', {pageId: tagged_pages[0], direction: 'up',
                adHocPagelist: {adHocName: '#' + tag.tag_name, adHocPageIds: tagged_pages,
                adHocTagUid: tag.tag_uid}});
        }
    },

    computed: {
        options() {
            return this.$store.state.vw.options;
        },
        viewerState() {
            return this.$store.state.vw.viewerState;
        },
        scData() {
            return this.$store.state.vw.scData;
        },
        searchId() {
            //console.log('searchId', this.viewerState.searchId);
            return this.viewerState.searchId;
        },
        thumbWidthForPages() {
            return this.scData.primary_layout.thumb_width;
        },
        thumbHeightForPages() {
            return this.scData.primary_layout.thumb_height;
        },
        loadedTagsForShowcase () {
            return this.$store.state.viewerTags.userTags;
        },
        searchDialogZIndex() {
            return ((1000 * this.backHistoryLengthAtMount) -1) + this.options.baseZindexOffset
        }
    },

    watch: {
        searchForText: function() {
            let searchState = _searchStateById[this.searchId];
            if (searchState) {
                searchState.searchForText = this.searchForText;
                this.searchDelayed();
            }
        }
    },




    //////


    getNextSearchId: function() {
        _nextSearchId = _nextSearchId + 1;
        let nextSearchId = _nextSearchId;
        _searchStateById[nextSearchId] = {searchForText: null, currentAllToggleState: 'all'};
        return nextSearchId;
    },

};


</script>



<style>

    .search-dialog {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .search-dialog-header {
        position: sticky;
        top: 0;
    }

    /* search */
    .search-dialog-body {
        background-color: #f5f5f5;
        border-top: 2px solid #e9e9f2;
    }

    /* add extra padding only on ios where max is supported for the notch safe-area-inset-*) */
    @supports(padding-top: max(0.01px, env(safe-area-inset-top))) {  /* note, webpack converts 0px to 0, this doesn't work so we have to use 0.01px */
        .search-dialog-header {
            padding-top: max(0.01px, env(safe-area-inset-top));
            padding-right: max(0.01px, env(safe-area-inset-right));
            padding-left: max(0.01px, env(safe-area-inset-left));
        }
        .search-dialog-body {
            padding-right: max(0.01px, env(safe-area-inset-right));
            padding-bottom: max(0.01px, env(safe-area-inset-bottom));
            padding-left: max(0.01px, env(safe-area-inset-left));
        }
    }


</style>