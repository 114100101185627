<template>
    <ScNormalPageLayout headingPart1="Workshop" headingPart2="Default">
        <div style="height: 100vh;">
            <div class="text-center d-flex mt-5 h-100 justify-content-center">
                <ScIcon v-if="!showErrors"
                        name="spinnerMedium" class="text-muted"/>
                <div v-else>
                    <div v-if="$store.state.user.workshops.length === 0">
                        <em class="text-muted">No workshops</em>
                        <p class="text-muted mt-5">
                            <ScIcon name="questionCircle" />
                            Ask your Admin for access, or create a workshop in Settings.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </ScNormalPageLayout>
</template>


<script>

    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import MainAppMixin from '../MainAppMixin';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
        name: 'WorkshopDefault',
        mixins: [MainAppMixin],
        components: {ScNormalPageLayout, ScIcon},
        data: function() {
            return {
                showErrors: false
            }
        },
        mounted: function() {
            //console.log('WorkshopDefault mounted');
            if (!this.$store.state.user.user_id) {
                // /App.vue will show login screen
            }   else {
                //console.log('store.state.user.user_id', this.$store.state.user.user_id);
                let mostRecentWs = this.$store.getters.userGetMostRecentWorkspaceObj;
                //console.log('workshop is', mostRecentWs);

                if (mostRecentWs && this.$store.getters.userCanPerform('a:list_pres_for_edit', mostRecentWs.id)) {
                    this.$router.push({name: 'workshop-home', params: {workshopId: mostRecentWs.id}});
                } else if (mostRecentWs && this.$store.getters.userCanPerform('a:list_pres_for_view', mostRecentWs.id)) {
                    this.$router.push({name: 'viewer-list', params: {workshopId: mostRecentWs.id}});
                } else {
                    console.log('Unable to establish workshop?', mostRecentWs);
                    setTimeout(() => {
                        this.showErrors = true;
                    }, 2000);
                }
            }
        }
    }

</script>