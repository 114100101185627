<template>
  <teleport to=".sc-modals-anchor">
    <div v-vb-is:modal.show ref="scModal"
         @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1">

      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header row g-0">
            <div class="col-4">
              <NewSharePrevButton :showShareAgain="false">
                <template v-slot:prevButtonSelectFiles>
                  <button type="button" class="btn btn-link"
                          @click.prevent="forceModalHide">Close</button>
                </template>
              </NewSharePrevButton>
            </div>
            <div class="col-4">
              <h4 class="modal-title text-center">Sharing</h4>
            </div>
            <div class="col-4 text-end">
              <NewShareNextButton class="mx-2">
                <template v-slot:nextLinkEmailSave>
                  <button type="button" class="btn btn-link"
                          @click.prevent="forceModalHide">Done</button>
                </template>
                <template v-slot:nextLinkSave>
                  <button type="button" class="btn btn-link"
                          @click.prevent="forceModalHide">Done</button>
                </template>
              </NewShareNextButton>
            </div>
          </div>
          <div class="modal-body pb-5 rounded-bottom">
            <form @submit.prevent class="form" action="#">
              <NewShareForm
                  :workspaceId="options.workspaceId"
                  :presentationId="scData.presentation.id"
                  :pageId="pageId" :resourceId="resourceId" :adHocPagelist="viewerState.adHocPagelist"
                  :scData="scData" :scDataLoading="false"
                  :currentUserDisplayName="options.userDisplayName"
                  :currentUserEmail="options.userEmail" :emailPreviewEnabled="false"
                  :headerImg="options.workshopHeaderImg"
                  @warn-before-leaving-update="onlyCloseModalWithButton=$event">
                <template v-slot:default></template>
              </NewShareForm>
            </form>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>



<script>


/**
 *
 * Presentation Viewer Sharing
 *
 *
 */

import NewShareForm from "../sharing/NewShareForm.vue";
import NewShareNextButton from "../sharing/NewShareNextButton.vue";
import NewSharePrevButton from "../sharing/NewSharePrevButton.vue";
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';


export default {
  name: 'ViewerShare',
  components: { NewShareForm, NewShareNextButton, NewSharePrevButton },
  props: {
    //
  },
  data() {
    return {
      onlyCloseModalWithButton: false
    }
  },
  mounted () {
    if (ViewerEmbedHandler.keyboardOverlayFixSetup) ViewerEmbedHandler.keyboardOverlayFixSetup(this.$refs.scModal);
  },
  beforeUnmount() {
    if (ViewerEmbedHandler.keyboardOverlayFixDestroy) ViewerEmbedHandler.keyboardOverlayFixDestroy(this.$refs.scModal);
  },

  methods: {
    hideModal() {
      this.$refs.scModal.$vb.modal.hide();
    },
    modalWasHidden() {
      this.$store.commit('vwShareHideNow');
    },
    canModalHide(e) {
      if (this.onlyCloseModalWithButton) e.preventDefault();
    },
    forceModalHide: function() {
      //console.log('hideModal', forceClose, this.onlyCloseModalWithButton);
      this.onlyCloseModalWithButton = false;
      this.hideModal();
    },
  },

  computed: {
    options() {
      return this.$store.state.vw.options;
    },
    viewerState() {
      return this.$store.state.vw.viewerState;
    },
    scData() {
      return this.$store.state.vw.scData;
    },
    pageId() {
      if (!this.viewerState) return null;
      if (this.viewerState.backHistory.length < 1) return null;
      return this.viewerState.backHistory[this.viewerState.backHistory.length - 1].pageId;
    },
    resourceId() {
      if (!this.viewerState) return null;
      if (this.viewerState.backHistory.length < 1) return null;
      return this.viewerState.backHistory[this.viewerState.backHistory.length - 1].resourceId;
    },
  }

};



</script>



<style>

  #share-modal .modal-body {
    min-height: 25vh;
  }

</style>
