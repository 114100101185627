<template>
    <teleport to=".sc-modals-anchor">
    <div v-vb-is:modal.show ref="scModal" @vb-hide-bs-modal="canModalHide" @vb-hidden-bs-modal="modalWasHidden"
            class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header g-0 clearfix">
                    <div class="col-3">
                        <button type="button" @click.prevent="forceModalHide" class="btn btn-link">Close</button>
                    </div>
                    <div class="col-6">
                        <h4 class="modal-title text-center">Showcase Form</h4>
                    </div>
                    <div class="col-3 text-end">
                        <button v-if="scFormDefn" type="submit" class="btn btn-primary-orange fw-bold scform-send"
                                :disabled="errorDetected && !sendingFormRequest"
                            @click.prevent="validateForm">Send</button>
                    </div>
                </div>
                <div class="modal-body">
                    <div style="color:black">

                        <div v-if="!scFormDefn" class="text-center m-5"><em class="text-muted">No form was found</em></div>

                        <form v-if="scFormDefn" action="#" @submit.prevent>
                            <div v-if="scFormDefn.fields" id="scform-fields" class="mt-2" style="max-height: 85vh; overflow-y: auto;">
                                <div v-for="(field, idx) in scFormDefn.fields"
                                     :key="'sf' + idx"
                                     class="mb-3">
                                    <label v-if="field.label && field.label !== ''"
                                            :for="'sc-field-'+idx">{{field.label}}</label>
                                    <input type="text"
                                           :class="['form-control scform-field', fieldErrors[field.name] ? 'is-invalid' : '']"
                                           :id="'sc-field-'+idx"
                                           :name="field.name"
                                           :data-field-name="field.name"
                                           :placeholder="field.description ? field.description : ''"
                                            @input="fieldChange(field.name, $event.target.value)"
                                            @focus="clearErr(field.name)"/>
                                </div>
                            </div>
                        </form>

                        <div class="clearfix mt-4"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

/**
 *
 * Showcase Forms
 *
 * showcaseform://
 *      {"name":"simple2",
 *       "fields":  [
 *          {"name":"name","mandatory":"true","label":"Name","description":"Enter your full name"},
 *          {"name":"email","mandatory":"true","label":"Email","description":"Enter your email"}
 *       ]
 *      }
 *
 */


import _ from "underscore"; // findWhere, isEmpty
import ScNotification from '../common/ScNotification.vue'
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';


let _formRegex = /^showcaseform:\/\//i;


let ViewerForms = {
    name: 'ViewerForms',
    props: {
        //
    },
    data() {
        return {
            fieldValues: null,
            fieldErrors: null,
            sendingFormRequest: false,
            errorDetected: false,
            onlyCloseModalWithButton: false
        }
    },
    created() {
        this.fieldValues = {};
        this.fieldErrors = {};
    },
    mounted() {
        if (ViewerEmbedHandler.keyboardOverlayFixSetup) ViewerEmbedHandler.keyboardOverlayFixSetup(this.$refs.scModal);
    },
    beforeUnmount() {
        if (ViewerEmbedHandler.keyboardOverlayFixDestroy) ViewerEmbedHandler.keyboardOverlayFixDestroy(this.$refs.scModal);
        if (this.$refs.scModal) this.$refs.scModal.$vb.modal.hide();
    },
    methods: {
        modalWasHidden() {
            if ( this.viewerState && this.viewerState.formsResourceId ) {
                this.$store.commit('vwSetFormsResourceId', { formsResourceId: null });
            }
        },
        canModalHide(e) {
            if (this.sendingFormRequest || this.onlyCloseModalWithButton) e.preventDefault();
        },
        hideModal() {
            this.$refs.scModal.$vb.modal.hide();
        },
        forceModalHide: function() {
            this.onlyCloseModalWithButton = false;
            this.hideModal();
        },
        clearErr(fieldName) {
            this.fieldErrors[fieldName] = false;
        },
        fieldChange(fieldName, fieldValue) {
            this.fieldValues[fieldName] = fieldValue;
            this.onlyCloseModalWithButton = true;
            this.errorDetected = false;
        },
        validateForm() {
            if (this.scFormDefn) {
                this.scFormDefn.fields.forEach((field) => {
                    if (!this.fieldValues[field.name]) {
                        this.fieldValues[field.name] = null;
                    }
                });
            }
            this.errorDetected = false;
            ScNotification.growlClear();
            this.scFormDefn.fields.forEach((field) => {
                this.fieldErrors[field.name] = false;
            });
            this.scFormDefn.fields.forEach((field) => {
                if ( this.errorDetected ) return;
                let fieldVal = this.fieldValues[field.name];
                let mandatory = field.mandatory === true || field.mandatory === 'true';
                if (mandatory && (!fieldVal || fieldVal === '')) {
                    ScNotification.growlErrMsg('The field "' + field.label + '" is mandatory.');
                    this.fieldErrors[field.name] = true;
                    this.errorDetected = true;
                }
            });
            if ( ! this.errorDetected ) this.sendForm();
        },
        sendForm() {
            this.sendingFormRequest = true;
            //console.log(this.options)
            ViewerEmbedHandler.saveFormSubmission({
                data_name: this.scFormDefn.name,
                showcase_id: this.scData.presentation.id,
                content: JSON.stringify(this.fieldValues),
                date_entered: new Date().toISOString(),
                workspace_id: this.options.workspaceId,
                user_email: this.options.userEmail,
                shared_key: this.options.sharedKey
            }).then(() => {  // good response
                this.fieldEdited = false;
                this.onlyCloseModalWithButton = false;
                this.$store.commit('vwSetFormsResourceId', { formsResourceId: null });

            }, () => {  // bad response
                //console.log('bad', e);
                this.sendingFormRequest = false;
            });
        },
        isUrlShowcaseForm: function(url) {
            return _formRegex.test(url);
        },


    },
    computed: {
        options() {
            return this.$store.state.vw.options;
        },
        viewerState() {
            return this.$store.state.vw.viewerState;
        },
        scData() {
            return this.$store.state.vw.scData;
        },
        formUrl() {
            if (!this.viewerState || !this.viewerState.formsResourceId) return;
            let resource = _.findWhere(this.scData.resource, {id: this.viewerState.formsResourceId});
            let formDefn = resource.name;
            // form defn stored in r.name might be truncated get full version from rmd
            let formRmd = _.findWhere(this.scData.resourcemetadata, { resource_id: resource.id, name: 'weburl' });
            if (formRmd && formRmd.value) formDefn = formRmd.value;
            return formDefn;
        },
        scFormDefn() {
            let scFormDefn = {};
            if (this.formUrl && this.isUrlShowcaseForm(this.formUrl)) {
                let rawJson = this.formUrl.replace(_formRegex, '');
                try {
                    let formJson = JSON.parse(rawJson);
                    if (!_.isEmpty(formJson)) scFormDefn = formJson;
                } catch (e) {
                    console.error('exception parsing form', e);
                    ScNotification.growlErrMsg('Form could not be loaded (parse error).');
                }
            }
            //console.log('scFormDefnDone');
            return scFormDefn;
        }
    },

};

export default ViewerForms;


</script>