<template>
    <transition appear name="sc-transition-fade">
        <div v-if="data" :style="{
                backgroundColor: 'black',
                position: 'fixed', left: 0, top: 0, width: '100%', height: '100%',
                zIndex: 11003001 }">
            <ViewerRoot v-if="viewerState && scData"
                    :pauseAllVideos="!!(pauseShowcaseViewingReactive||viewerState.shareModalShown||viewerState.drawerPageId||viewerState.drawerResourceId)"
                    :suppressAnalyticsEvents="pauseShowcaseViewingReactive"></ViewerRoot>
        </div>
    </transition>
</template>


<script>

    import './viewer.css'
    import _ from 'underscore'; // contains
    import ViewerRoot from './ViewerRoot.vue';
    import ViewerLikes from './ViewerLikes'
    import ScCommonUtil from '../common/ScCommonUtil';
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ViewerStore from './ViewerStore';

    export default {
        name: 'ShowcaseViewer',
        emits: ['showcase-viewer-exit'],
        components: {ViewerRoot},
        props: {
            data: {type: Object},
            userOptions: {type: Object},
            kioskMode: {type: Boolean},
            destroyOnExit: {type: Boolean, default: true},
            pauseShowcaseViewingReactive: {type: Boolean, default: false},
        },
        created() {
            if (!this.$store.hasModule('vw')) {
                this.$store.registerModule('vw', ViewerStore);
            }
        },
        async mounted() {
            //console.log('ShowcaseViewer mounted');

            let presentation = this.data.presentation;
            let transitionsEnabled = true;
            if (presentation && typeof presentation.transitions_enabled == "boolean") {
                transitionsEnabled = presentation.transitions_enabled;
            }

            let viewerStateDefaults = {
                drawerFullscreenShown: await ViewerEmbedHandler.fullscreenPossible(),
                transitionsEnabled: transitionsEnabled
            };

            this.$store.commit('vwSetOptions', {userOptions: this.userOptions, scData: this.data,
                viewerStateDefaults: viewerStateDefaults});

            if (this.options.prefetchResources) {
                ViewerEmbedHandler.preloadNow(this.scData, this.options.openAtPageId ? this.options.openAtPageId : null);
            }

            // handle various events
            ScCommonUtil.onEvent('scapp.viewRequestBack', () => {
                this.$store.dispatch('vwBack');
            });
            ScCommonUtil.onEvent('scapp.viewRequestHome', () => {
                this.$store.commit('vwExit');  // poorly named, should be scapp.viewRequestExit
            });
            ScCommonUtil.onEvent('scapp.exitViewer', () => {
                //console.log('got exitViewer')
                this.viewerExit();
            });
            ScCommonUtil.onEvent('scapp.viewRequestBackToStart', () => {
                this.$store.dispatch('vwToStart');
            });
            ScCommonUtil.onEvent('scapp.viewRequestShare', () => {
                this.$store.commit('vwShareNow');
            });
            ScCommonUtil.onEvent('scapp.viewControlsShow', () => {
                //console.log('got event scapp.viewControlsShow');
                this.$store.commit('vwWidgetsToggle', {widgetsShown: true});
            });
            ScCommonUtil.onEvent('scapp.viewControlsHide', () => {
                //console.log('scapp.viewControlsHide');
                this.$store.commit('vwDrawerHide');
                this.$store.commit('vwWidgetsToggle', {widgetsShown: false});
            });

            ViewerLikes.initLikes(this.options.workspaceId);

            setTimeout(() => {
                //console.log('first nav');
                this.$store.dispatch('vwHome', { firstTimeHomeIsShown: true });
                if (!this.pauseShowcaseViewingReactive) this.triggerAnalyticsEvent('showcase_view');

            }, 1);  // do on next js loop to allow ui to catch up

            document.addEventListener('keydown', this.handleKeydown)

        },

        beforeUnmount() {
            //console.log('ShowcaseViewer.beforeUnmount');
            document.removeEventListener('keydown', this.handleKeydown)
            this.$store.commit('viewerTagsStoreReset');
            this.$store.commit('vwDestroy');
            this.$store.unregisterModule('vw');
        },

        methods: {
            triggerAnalyticsEvent(eventName) {
              // `mounted` or `pauseAllVideos` will cause this to get run to record the start of the viewing
              this.$store.dispatch('vwRecordScAnalytics', { alType: eventName,
                alObj: { showcase_id: this.scData.presentation.id, showcase_version: this.scData.presentation.version},
                alWsId: this.options.workspaceId, alUsId: this.options.userId, alShId: this.options.sharedId });
            },
            viewerExit() {  // tells our parent user interaction with the pres is done, parent can choose to unmount
                //console.log('viewerExit');
                this.triggerAnalyticsEvent('showcase_end_view');
                this.$emit('showcase-viewer-exit');
                this.$store.commit('viewerTagsSelectedTagIdUpdate', null);
            },
            handleKeydown(e) {
                // simple clicker/keyboard support
                if ( document.querySelector('body.modal-open')
                        || document.querySelector('input:focus')
                        || document.querySelector('textarea:focus') ) return;
                if (this.viewerState.shareModalShown || this.viewerState.openOrShareResourceId || this.viewerState.addTagModalShown ||
                    this.viewerState.manageTagsModalShown) return;
                if (this.viewerState.resourceId && !this.viewerState.resourceIsOpeningVideo) return;

                //console.log('keydown', e.keyCode);
                if (_.contains([80, 33, 72, 37], e.keyCode)) {  // p, page up, h, left
                    if (this.viewerState.prevPageId) this.$store.commit('vwPrev');
                }   else if (_.contains([78, 34, 76, 39, 32], e.keyCode)) {  // n, page down, l, right, space
                    if (this.viewerState.nextPageId) this.$store.commit('vwNext')
                }   else if (_.contains([75, 38], e.keyCode)) {  // k, up
                    this.$store.dispatch('vwBack');
                }   else if (_.contains([36, 27, 81], e.keyCode)) {  // home, esc, q
                    if (!this.options.kioskMode) {  // note, kiosk mode locks user into the presentation
                        this.$store.commit('vwExit');
                    }
                }   else if (_.contains([70], e.keyCode)) {  // f
                    if (this.viewerState.drawerFullscreenShown) this.$store.dispatch('vwFullscreen');
                }
            }
        },

        computed: {
            options() {
                return this.$store.state.vw.options;
            },
            viewerState() {
                return this.$store.state.vw.viewerState;
            },
            scData() {
                return this.$store.state.vw.scData;
            }
        },

        watch: {
            kioskMode() {
                //console.log('ShowcaseViewer.watch.kioskMode', this.kioskMode);
                if (this.kioskMode !== this.options.kioskMode) {
                    this.$store.commit('vwSetKioskMode', {kioskMode: this.kioskMode});
                }
                this.$store.dispatch('vwHighlightScMainButtons');
            },
            pauseShowcaseViewingReactive() {
              // Allow the user of this component to control when the intro video is played and the showcase view
              // analytics session begins (this function will not run on mounted)
              // console.log('pauseShowcaseViewingReactive change', this.pauseShowcaseViewingReactive);
              if (!this.pauseShowcaseViewingReactive) {
                this.triggerAnalyticsEvent('showcase_view');
              } else {
                this.$store.commit('vwClearBackHistory');
                this.$store.dispatch('vwHome', { firstTimeHomeIsShown: true });
              }
            }
        }

    }

</script>