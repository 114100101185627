<template>
    <ScLayoutNoAuth>
        <div v-if="!forgotEmailSent">

            <div class="text-center mb-3">
                We'll email you a code, valid for 24 hours. You can use this to login and change your password.
            </div>

            <form class="form" @submit.prevent="forgotRequest()">
                <div class="my-4 form-floating">
                    <input class="form-control auth_email w-100" id="auth_user_email" name="email" type="email"
                           placeholder="Email" v-model="email"
                           :disabled="forgotRequestLoading"
                           ref="forgotEmail" @keyup.enter.prevent="forgotRequest()"/>
                    <label for="auth_user_email" class="text-body">Email</label>
                </div><!-- @keyup.enter.prevent="$refs.forgotEmail.focus()" -->

                <div class="controls actions clearfix text-center">
                    <button class="btn btn-no-auth-submit col-sm-8 col-sm-offset-2 p-2 fw-bold"
                            :disabled="forgotRequestLoading" tabindex="2"
                            @click.prevent="forgotRequest()">
                        <span v-if="!forgotRequestLoading">Request reset code</span>
                        <ScIcon v-else name="spinnerFW" class="ms-2" />
                    </button>
                    <button @click.prevent="directToLoginConfirm" :disabled="forgotRequestLoading"
                            tabindex="3" class="btn btn-link btn-link-signin col-sm-8 col-sm-offset-2 mt-3 px-0 fw-normal text-white text-decoration-underline opacity-75">
                                    I have a code already</button>
                    <router-link :to="{name: 'workshop-default'}"
                                 :class="['btn btn-link btn-link-signin col-sm-8 col-sm-offset-2 mt-3',
                                 forgotRequestLoading ? 'disabled' : '']" tabindex="4">
                        <ScIcon name="chevronLeftFW"/>Back</router-link>
                </div>

            </form>
        </div>

        <div>
            <form v-if="forgotEmailSent" class="form" @submit.prevent="forgotConfirm()">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-white text-center">Reset password</h2>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="user_email" class="fw-bold">for email</label>
                    <div id="user_email" class="">{{email}}</div>
                </div>

                <div class="mb-3 form-floating">
                    <input class="form-control sc-reset-code w-100" type="number" id="auth_reset_code"
                           name="reset_code" pattern="\d*" placeholder="Enter your reset code"
                           tabindex="1" v-model="forgotConfirmCode"
                           autocomplete="off" :disabled="forgotConfirmLoading"
                           min="100000" max="999999" ref="forgotConfirmCode"
                           @keyup.enter.prevent="$refs.resetPwd.focus()"/>
                     <label for="auth_reset_code" class="text-body">Enter your reset code</label>
                </div>

                <div class="mb-3 form-floating">
                    <input class="password form-control auth_pwd w-100" name="pwd" type="password"
                           ref="resetPwd" autocomplete="password" id="user_pwd"
                           placeholder="Create new password (min. 6 chars)" tabindex="2"
                           v-model="pwd"
                           @keyup.enter.prevent="forgotConfirm()" :disabled="forgotConfirmLoading" />
                    <label for="user_pwd" class="text-body">Create new password (min. 6 chars)</label>
                </div>

                <div class="actions clearfix text-center mt-3">
                    <button class="btn btn-no-auth-submit col-sm-8 col-sm-offset-2 p-2 fw-bold"
                            :disabled="forgotConfirmLoading"
                            @click.prevent="forgotConfirm()">
                        <span v-if="!forgotConfirmLoading">Set new password</span>
                        <ScIcon v-else name="spinnerFW" class="ms-2" />
                    </button>
                    <button class="btn btn-link btn-link-signin col-sm-8 col-sm-offset-2 mt-3 text-center fw-normal text-white"
                            :disabled="forgotConfirmLoading" tabindex="3"
                            @click.prevent="forgotEmailSent=false"><ScIcon name="chevronLeftFW"/>Back</button>

                </div>

            </form>
        </div>
    </ScLayoutNoAuth>
</template>


<script>

    import $ from 'jquery';
    import ScLayoutNoAuth from '../global/ScLayoutNoAuth.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import ScIcon from '../../shared/common/ScIcon.vue';


    export default {
        name: 'ResetPasswordFormV2',
        components: {ScLayoutNoAuth, ScIcon},
        data () {
            return {
                forgotRequestLoading: false,
                forgotConfirmLoading: false,

                forgotLoading: false,
                email: null,
                pwd: null,
                forgotEmailSent: false,
                forgotConfirmCode: null
            }
        },
        mounted() {
            let lsEmail = null;
            try {
                if (window.localStorage) lsEmail = window.localStorage.getItem('scLastEmail');
            } catch(e) {
                // ignore
            }
            if (lsEmail) this.email = lsEmail;
        },
        methods: {
            directToLoginConfirm: function() {
                if (!this.email || !ScCommonUtil.validateEmail(this.email)) {
                    ScNotification.growlErrMsg('Please enter a valid email address');
                } else {
                    this.forgotEmailSent = true;
                }
            },
            forgotRequest: function() {
                ScNotification.growlClear();  // hide any pending toasts
                if (!ScCommonUtil.validateEmail(this.email)) {
                    ScNotification.growlErrMsg('Please enter a valid email address');
                } else {
                    this.forgotRequestLoading = true;
                    this.forgotEmailSent = false;
                    $.ajax({
                        method: 'post', url: '/api/devices_v2/reset_password_request_v2',
                        data: {email: this.email, client_name: 'browser', client_version: 'latest'}
                    }).then((data) => {

                        this.forgotRequestLoading = false;
                        if (data.status === 'ok') {
                            this.forgotEmailSent = true;
                            this.pwd = null;
                        } else if (data.status === 'error') {
                            ScNotification.growlErrMsg('Unable to reset password. ' + data.message);
                        } else {
                            ScNotification.growlErrMsg('Unable to reset password.  Please try again.');
                        }

                    }).fail((jqXHR) => {
                        this.forgotRequestLoading = false;
                        if (jqXHR.status === 0) {  // offline
                            ScNotification.growlErrMsg('You are not connected to the internet');
                        } else if (jqXHR.status === 500) {
                            ScNotification.growlErrMsg('Server error.  Please try again in one hour.');
                        }   else {
                            ScNotification.growlErrMsg('Could not contact app server (' + jqXHR.status + ')');
                        }
                    });

                }
            },
            forgotConfirm: function() {
                ScNotification.growlClear();  // hide any pending toasts
                if (!this.forgotConfirmCode || this.forgotConfirmCode.length === 0) {
                    ScNotification.growlErrMsg('Please enter the code from the email');
                } else if (!this.pwd || this.pwd.length === 0) {
                    ScNotification.growlErrMsg('Please enter a password');
                } else {
                    this.forgotConfirmLoading = true;
                    $.ajax({
                        method: 'post', url: '/api/devices_v2/reset_password_confirm_v2',
                        data: {email: this.email, code: this.forgotConfirmCode, new_password: this.pwd,
                            client_name: 'browser', client_version: 'latest' }
                    }).then((data) => {
                        this.forgotConfirmLoading = false;
                        if (data.status === 'ok') {
                            this.passwordChangedNowLogin();
                        } else if (data.status === 'error') {
                            ScNotification.growlErrMsg('Unable to change password. ' + data.message);
                        } else {
                            ScNotification.growlErrMsg('Unable to change password.  Please try again.');
                        }

                    }).fail((jqXHR) => {
                        this.forgotConfirmLoading = false;
                        if (jqXHR.status === 0) {  // offline
                            ScNotification.growlErrMsg('You are not connected to the internet');
                        } else if (jqXHR.status === 500) {
                            ScNotification.growlErrMsg('Server error.  Please try again in one hour.');
                        }   else {
                            ScNotification.growlErrMsg('Could not contact app server (' + jqXHR.status + ')');
                        }
                    });
                }
            },

            passwordChangedNowLogin: function() {
                this.forgotConfirmLoading = true;
                this.$store.dispatch('userLoginPost', {
                    email: this.email, pwd: this.pwd
                }).then(() => {
                    return this.$store.dispatch('userPrefsLoad');
                }).then(() => {
                    this.$router.push({name: 'workshop-default'});   // now try loading the default screen
                    setTimeout(() => {
                        this.forgotConfirmLoading = false;
                    }, 2000);
                }).catch(() => {
                    this.forgotConfirmLoading = false;
                });
            }

        },
        watch: {
            email() {
                try {
                    if (window.localStorage) window.localStorage.setItem('scLastEmail', this.email);
                } catch(e) {
                    // ignore
                }
            }
        }

    }

</script>


<style>

    input.sc-reset-code::-webkit-outer-spin-button,
    input.sc-reset-code::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }


</style>