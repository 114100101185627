/**
 *
 * Presentation Viewer Likes
 *
 *
 */

import _ from "underscore"; // isObject, isArray, findWhere, random
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';

/* public */
/* public */
/* public */
/* public */

export default {

    initLikes: function(workspaceId) {
        ViewerEmbedHandler.loadLikes(workspaceId).then((likes) => {
            // ensure we convert to an array if we get an object
            if (likes && _.isObject(likes) && !_.isArray(likes)) likes = Object.values(likes);
            _likes = likes ? likes : [];
        }, () => {});
    },

    togglePageLike: function(workspaceId, showcaseId, showcaseVersion, pageId, resourceId) {
        let like = _.findWhere(_likes, {showcase_id: showcaseId, page_id: pageId, resource_id: resourceId});
        let newLikeId = null;
        if (like) {
            const index = _likes.indexOf(like);
            if (index > -1) _likes.splice(index, 1);
        }   else {
            newLikeId = _.random(1000000, 9999999);
            _likes.push({id: newLikeId, showcase_id: showcaseId, page_id: pageId, resource_id: resourceId});
        }
        ViewerEmbedHandler.savePageLike({
            workspaceId: workspaceId, showcaseId: showcaseId, pageId: pageId, resourceId: resourceId,
            showcaseVersion: showcaseVersion, newLikeId: newLikeId });
    },

    isPageLiked: function(showcaseId, pageId) {
        return _.isObject(_.findWhere(_likes, {showcase_id: showcaseId, page_id: pageId, resource_id: null}));
    },
    isResLiked: function(showcaseId, resourceId) {
        return _.isObject(_.findWhere(_likes, {showcase_id: showcaseId, resource_id: resourceId}));
    }
};

/* private */
/* private */
/* private */
/* private */

let _likes = [];

