<template>
    <div>
        <div v-if="shareDetailsLoading">
            <div class="text-center" style="margin-top: 45vh;">
                <ScIcon name="spinnerMedium" class="text-muted"/>
            </div>
        </div>
        <div v-if="shareDetailsError">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center" style="min-height: 90vh;">
                <div class="text-center">
                    <div class="alert alert-danger">{{shareDetailsError}}</div>
                    <p class="text-muted"><small>
                        Powered by <a href="https://showcaseworkshop.com" target="_blank">Showcase Workshop</a>
                    </small></p>
                </div>
            </div>
        </div>
        <SharedComp
                v-if="sharedId && !shareDetailsLoading && !shareDetailsError"
                :workspaceId="workspaceId"
                :presentationId="presentationId"
                :presentationDataUrl="presentationDataUrl"
                :sharedId="sharedId"
                :sharedKey="$route.params.sharedKey"
                :sharerEmail="sharerEmail"
                :sharerDisplayName="sharerDisplayName"
                :daysRemaining="daysRemaining"
                :sharedType="sharedType"
                :subject="sharedSubject"
                :note="sharedNote"
                :resources="sharedResources"
                :recordAnalytics="recordAnalytics"
                :wsIsExpired="wsIsExpired"
                :openAtOptions="openAtOptions"
                :headerImg="headerImg"
        ></SharedComp>
    </div>
</template>

<script>

    // https://test.showcaseworkshop.com/share/01f9212f73064e2f974f4ef9d83ca3f8
    // or https://test.showcaseworkshop.com/share/01f9212f73064e2f974f4ef9d83ca3f8?copy=T (no analytics)


    import $ from "jquery";
    import MainAppMixin from "../MainAppMixin";
    import ScCommonUtil from "../../shared/common/ScCommonUtil";
    import ScIcon from "../../shared/common/ScIcon.vue";
    import SharedComp from "./SharedComp.vue";

    export default {
        name: "ViewShared",
        mixins: [ MainAppMixin ],
        components: {SharedComp, ScIcon},
        data () {
            return {
                workspaceId: null,
                sharedId: null,
                sharedErrorMessage: null,
                shareDetailsLoading: false,
                shareDetailsError: null,
                sharedResources: [],
                sharerEmail: null,
                sharerDisplayName: null,
                presentationId: null,
                presentationDataUrl: null,
                daysRemaining: null,
                headerImg: null,
                wsIsExpired: false,
                sharedType: null,
                sharedSubject: null,
                sharedNote: null,
                recordAnalytics: true,
                openAtOptions: null
            };
        },
        mounted () {
            if (this.$route && this.$route.query && this.$route.query.copy === 'T') {
                this.recordAnalytics = false;
            }

            let meta = document.createElement('meta');
            meta.name = "robots";
            meta.content = "noindex";
            document.getElementsByTagName('head')[0].appendChild(meta);

            this.loadSharingInfo();
        },
        methods: {
            loadSharingInfo: function() {
                this.shareDetailsLoading = true;
                this.shareDetailsError = null;
                    $.ajax({
                    type: 'GET', url: '/main/sharing/ajax_shared_info',
                    data: {shared_key: this.$route.params.sharedKey}
                }).done((data) => {
                    //console.log('got', data);
                    this.workspaceId = data.workspace_id;
                    this.headerImg = data.header_img;
                    this.daysRemaining = data.days_remaining;
                    this.sharedId = data.shared_id;
                    this.sharedErrorMessage = data.shared_error_message;
                    this.sharerEmail = data.sharer_email;
                    this.sharerDisplayName = data.sharer_display_name;
                    this.sharedResources = data.resources;
                    this.sharedType = data.shared_type;
                    this.sharedSubject = data.shared_subject;
                    this.sharedNote = data.shared_note;
                    this.presentationId = data.original_presentation_id;
                    this.presentationDataUrl = data.showcase_data_url;
                    this.wsIsExpired = data.ws_is_expired;
                    try {
                        this.openAtOptions = data.open_at_options ? JSON.parse(data.open_at_options) : null;
                    } catch {
                        this.openAtOptions = null;
                    }
                    this.recordSharingView();

                }).always(() => {
                    this.shareDetailsLoading = false;

                }).fail((jqXhr) => {
                    if (jqXhr && jqXhr.responseJSON && jqXhr.responseJSON.message) {
                        this.shareDetailsError = jqXhr.responseJSON.message
                    }   else {
                        this.shareDetailsError = ScCommonUtil.httpCodeToText(jqXhr.status) + " error while loading shared info"
                    }
                });
            },
            recordSharingView: function() {
                if (this.recordAnalytics) {
                    $.ajax({
                        type: 'POST', url: '/main/sharing/ajax_track_shared_view',
                        data: {shared_key: this.$route.params.sharedKey}
                    }).done(() => {
                        // nothing to do here
                    });
                }
            }
        },
    }
</script>
