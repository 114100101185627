
import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
import $ from "jquery";
import ScNotification from "../common/ScNotification.vue";
import ScCommonUtil from "../common/ScCommonUtil";

let _state = {
    shareType: 'email',  // email, link-basic, link-vip-pass
    shareStage: 'select-type', // select-type, edit, preview, save
    emailPreviewEnabled: true,

    currentUserDisplayName: null,
    currentUserEmail: null,
    workspaceId: null,
    presentationId: null,
    isPublished: true,
    selectedResourceIds: [],
    recipients: [],

    subject: null,
    note: null,
    linkUrlCode: null,

    emailPreviewLoading: false,
    emailSaveLoading: false,
    linkPreviewLoading: false,
    linkSaveLoading: false,

    linkPreviewSubject: null,
    linkPreviewNote: null,
    linkPreviewUrl: null,
    linkPreviewDaysRemaining: 0,
    validatedLinkUrlCode: null,

    generatedLinkTitle: null,
    generatedLinkUrl: null,
    generatedLinkShareHistoryName: null,
    generatedLinkExpiryDays: null,

    openAtOptions: null,

};


let validateEmailFields = (state) => {
    ScNotification.growlClear();
    let error = null;
    if (state.recipients.length === 0) {
        error = 'Whoa. Insert at least one recipient\'s email first.';
    } else {
        for (let rec of state.recipients) {
            if (!validateEmail(rec))
                error = "Yikes, it looks like \"" + rec + "\" is an invalid email.  Enter emails with the format " +
                    "\"jim@example.com\" or \"Jim Smith <jim@example.com>\".";
        }
    }
    if (!error) return true;
    ScNotification.growlErrMsg(error);
    return false;
};
let validateEmail = (email) => {
    let extractedEmail = extractEmail(email);
    return ScCommonUtil.validateEmail(extractedEmail);
};
let extractEmail = (email) => {
    if (email.includes('<') && email.includes('>'))
        email = email.slice(email.indexOf('<') + 1, email.indexOf('>'));
    return email;
};
let validateLinkFields = (state) => {
    ScNotification.growlClear();
    let error = null;
    if (state.linkUrlCode && state.linkUrlCode.length < 6)
        error ='URL code must be 6 or more characters long.';
    if (!error) return true;
    ScNotification.growlErrMsg(error);
    return false;
};



let _getters = {
    // should always start with "fonts" to avoid collisions with other modules


};


let _actions = {

    newShareFilesSelected({ dispatch, commit, state }) {
        ScNotification.growlClear();
        let error = null;
        if (!state.presentationId)
            error ='Please select a Presentation first.';
        else if (state.selectedResourceIds.length === 0)
            error = 'Almost there, select a shared file to continue.';
        else if (state.isPublished)
            error = 'Sorry - sharing is disabled in unpublished presentations.';
        if (error) {
            ScNotification.growlErrMsg(error);
            return;
        }

        if (state.shareType === 'link-basic') {
            dispatch('newShareLinkSave');
            return;
        }
        if (state.shareType === 'email') commit('newShareStageUpdate', 'edit');
        if (state.shareType === 'link-basic') commit('newShareStageUpdate', 'save');
        if (state.shareType === 'link-vip-pass') commit('newShareStageUpdate', 'edit');
    },

    newShareEmailPreview({ commit, state }) {
        if (!validateEmailFields(state)) return;
        if (!navigator.onLine) {
            ScNotification.growlErrMsg('You need to be online to preview an email.');
            return;
        }
        commit('newShareEmailPreviewLoadingUpdate', true);
        $.ajax({
            url: ViewerEmbedHandler.ajaxWrapUrl('/main/sharing/iframe_share_email_preview'),
            data: {
                workspace_id: state.workspaceId,
                to_list: state.recipients.join(','),
                note: state.note,
                resource_ids: state.selectedResourceIds.join(','),
                pid: state.presentationId,
                subject: state.subject
            }, beforeSend: ViewerEmbedHandler.ajaxBeforeSend
        }).done((data) => {
            commit('newShareStageUpdate', 'preview');
            setTimeout(() => {  // delay so that state is updated first
                $('#iframe-preview').contents().find('html').html(data);
            });
        }).always(() => {
            commit('newShareEmailPreviewLoadingUpdate', false);
        }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, "getting data");
        });
    },

    newShareEmailSave({commit, state}) {
        if (!validateEmailFields(state)) return;
        commit('newShareEmailSaveLoadingUpdate', true);
        ViewerEmbedHandler.saveSharingRequest({
                to_list: state.recipients.join(','), note: state.note, subject: state.subject,
                resource_ids: state.selectedResourceIds.join(','), pid: state.presentationId,
                workspace_id: state.workspaceId, shared_uuid: ScCommonUtil.guid(),
                open_at_options: state.openAtOptions ? state.openAtOptions : null

        }).then(() => {
            commit('newShareRecipientsUpdate', []);
            commit('newShareStageUpdate', 'save');
            commit('newShareNoteUpdate', null);
            commit('newShareSubjectUpdate', null);
            commit('newShareEmailSaveLoadingUpdate', false);
        }).catch((jqXhr) => {
            if (jqXhr) ScNotification.growlXhrError(jqXhr, "sending share request");
            commit('newShareEmailSaveLoadingUpdate', false);
        });
    },

    newShareLinkPreview({commit, state}) {
        if (!validateLinkFields(state)) return;
        if (!navigator.onLine) {
            ScNotification.growlErrMsg('You need to be online to preview a link.');
            return;
        }
        commit('newShareLinkPreviewLoadingUpdate', true);
        $.ajax({
            method: 'POST', url: ViewerEmbedHandler.ajaxWrapUrl('/main/sharing/ajax_link_preview'),
            data: {
                workspace_id: state.workspaceId, subject: state.subject, note: state.note,
                link_url_code: state.linkUrlCode
            }, beforeSend: ViewerEmbedHandler.ajaxBeforeSend
        }).done((data) => {
            commit('newShareLinkPreviewUpdate', data);
            commit('newShareStageUpdate', 'preview');
        }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading link preview');
        }).always(() => {
            commit('newShareLinkPreviewLoadingUpdate', false);
        });
    },

    newShareLinkSave({commit, state}) {
        if (!validateLinkFields(state)) return;
        if (!navigator.onLine) {
            ScNotification.growlErrMsg('You need to be online to generate a link.');
            return;
        }
        commit('newShareLinkSaveLoadingUpdate', true);
        if (!navigator.onLine) {
            ScNotification.growlErrMsg('You need to be online to generate a link.');
            commit('linkSaveCancel');
            commit('newShareLinkSaveLoadingUpdate', false);
            return;
        }
        let isVipPass = (state.shareType === 'link-vip-pass');
        $.ajax({method: 'POST', url: ViewerEmbedHandler.ajaxWrapUrl('/main/sharing/ajax_prepare_link_share'),
            data: {
                workspace_id: state.workspaceId, shared_uuid: ScCommonUtil.guid(),
                resource_ids: state.selectedResourceIds.join(','), pid: state.presentationId,
                link_share_type: isVipPass ? 'vip-pass' : 'basic',
                subject: isVipPass ? state.subject : null,
                note: isVipPass ? state.note : null,
                link_url_code: isVipPass ? state.validatedLinkUrlCode : null,
                open_at_options: state.openAtOptions ? JSON.stringify(state.openAtOptions) : null,
            }, beforeSend: ViewerEmbedHandler.ajaxBeforeSend
        }).done((data) => {
            commit('newShareLinkSaveUpdate', data);
            commit('newShareStageUpdate', 'save');
        }).always(() => {
            commit('newShareLinkSaveLoadingUpdate', false);
        }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'generating share link');
        });
    },

};


let _mutations = {
    //update
    newShareSetBaseData(state, baseData) {
        state.shareStage = 'select-type';
        state.shareType = 'email';
        state.workspaceId = baseData.workspaceId;
        state.currentUserDisplayName = baseData.currentUserDisplayName;
        state.currentUserEmail = baseData.currentUserEmail;
        state.emailPreviewEnabled = baseData.emailPreviewEnabled;
        state.selectedResourceIds.splice(0);
        state.presentationId = null;
        state.recipients.splice(0);
        state.subject = null;
        state.note = null;
        state.linkUrlCode = null;
        state.openAtOptions = baseData.openAtOptions;
    },
    newSharePresentationUpdate(state, args) {
        state.presentationId = args.presentationId;
        state.isPublished = args.isPublished;
        state.selectedResourceIds.splice(0);
    },
    newShareTypeSelected: (state, value) => state.shareType = value,
    newShareStageUpdate: (state, value) => {
        state.shareStage = value
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },

    newShareSelectedResourceIdsUpdate: (state, value) => {
        state.selectedResourceIds.splice(0);
        state.selectedResourceIds.push(...value);
    },
    newShareRecipientsUpdate: (state, value) => {
        state.recipients.splice(0);
        state.recipients.push(...value);
    },

    newShareSubjectUpdate: (state, value) => state.subject = value,
    newShareNoteUpdate: (state, value) => state.note = value,
    newShareLinkUrlCodeUpdate: (state, value) => state.linkUrlCode = value,
    newShareLinkPreviewUpdate: (state, data) => {
        state.linkPreviewSubject = data.preview_subject;
        state.linkPreviewNote = data.preview_note;
        state.linkPreviewUrl = data.preview_link_url;
        state.linkPreviewDaysRemaining = data.days_remaining;
        state.validatedLinkUrlCode = data.validated_link_url_code;
    },
    newShareLinkSaveUpdate: (state, data) => {
        state.generatedLinkUrl = data.share_url;
        state.generatedLinkTitle = data.share_title;
        state.generatedLinkExpiryDays = data.expiry_days;
        state.generatedLinkShareHistoryName = data.share_history_name;
        state.linkUrlCode = null;
        state.validatedLinkUrlCode = null;
        state.subject = null;
        state.note = null;
    },

    newShareEmailSaveLoadingUpdate: (state, value) => state.emailSaveLoading = value,
    newShareEmailPreviewLoadingUpdate: (state, value) => state.emailPreviewLoading = value,
    newShareLinkSaveLoadingUpdate: (state, value) => state.linkSaveLoading = value,
    newShareLinkPreviewLoadingUpdate: (state, value) => state.linkPreviewLoading = value,

};

export default {
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
};