<template>
    <div ref="videoCont" class="d-flex" style="min-width:100%;min-height:100%;position: absolute;background-color: black;">

        <video v-if="videoResUrl" @click.prevent="playPauseVideo"
               :loop="shouldVideoLoop"
               :autoplay="false"
               ref="videoEl" class="my-auto"
               playsinline
               :style="videoStyleObject"
               @loadedmetadata="videoMetaDataLoaded"
               @loadeddata="videoDataLoaded"
               @timeupdate="updateTime"
               @ended="videoEnded" @onerror="videoError" @error="videoError">
            <source type="video/mp4" :src="videoResUrl" @error="videoError" />
        </video>


        <div v-if="!viewerState.resourceIsOpeningVideo && !viewerState.isScreensaverVideoPlaying"
             class="controlPanel" ref="controlPanelEl" :style="{zIndex: zIndexVal}">

            <div class="panelShadow" :style="{zIndex: zIndexVal, boxShadow: '0 -5px 50px 25px black'}"></div>

            <div class="controls d-flex px-2 mb-2" :style="{zIndex: zIndexVal, width: 'calc(100vw - 70px)', marginLeft: '70px'}">
                <button v-if="!videoIsPlaying" class="btn text-white me-1" type="button"
                        @click="playVideo"><ScIcon name="play"/></button>
                <button v-if="videoIsPlaying" class="btn text-white me-1" type="button"
                        @click="pauseVideo"><ScIcon name="pause"/></button>

                <div id="timeTooltipContainer" class="w-100 my-auto d-flex">
                    <input class="w-100" type="range" min="0" step="0.1" :max="duration"
                           v-model="progress" @mousemove="showTimeTooltip" ref="progressEl"
                           :style="{ background: progressStyle}">
                    <span class="timeTooltip px-2 py-1 rounded" ref="tooltipEl"></span>
                </div>

                <div class="text-white text-end ms-2 me-1 my-auto" style="min-width: 42px; font-size: .9rem;"
                     @click.prevent="showTimeRemaining=!showTimeRemaining"> {{ timeLeft }}</div>

                <button v-if="muted" class="btn text-white" type="button"
                        @click="unmute"><ScIcon name="volumeMute"/></button>
                <button v-if="!muted" class="btn text-white" type="button"
                        @click="mute"><ScIcon name="volume"/></button>

                <input class="mx-1 my-auto volRangeSlider" type="range" v-model="volume"
                       min="0" step=".1" max="1" :style="{ background: volRangeSlider, width: '70px'}">
            </div>
        </div>
    </div>
</template>

<script>

    import _ from 'underscore'; // findWhere
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import ScVueTrackEvents from "@/components/global/ScVueTrackEvents";

    export default {
        name: "ViewerResourceVideo",
        components: {ScIcon},
        props: {
            pauseAllVideos: {type: Boolean, default: true}
        },
        data () {
            return {
                videoResUrl: null,
                videoMetaDataIsLoaded: false,
                videoWidth: null,
                videoHeight: null,
                videoIsPlaying: false,
                duration: null,
                timeNow: 0,
                volumeNow: .3,
                mutedVolume: 1,
                showTimeRemaining: true,
                controlPanelTimer: null,
                waitForRef: false,
                playStartTime: null,
                resourceId: null,
            }
        },
        mounted () {
            this.$refs.videoCont.addEventListener('touchstart', this.movementDetected);
            this.$refs.videoCont.addEventListener('click', this.movementDetected);
            this.$refs.videoCont.addEventListener('keydown', this.movementDetected);
            this.$refs.videoCont.addEventListener('mousemove', this.movementDetected);

            this.videoUrlPopulate();
            window.addEventListener('beforeunload', this.trackPlayTime);
            this.resourceId = this.resource.id;
        },
        beforeUnmount () {
            this.videoIsPlaying = false;
            this.trackPlayTime();
            window.removeEventListener('beforeunload', this.trackPlayTime);
        },
        methods: {
            playPauseVideo () {
                //console.log('playPauseVideo')
                if (!this.$refs.videoEl) return;
                if (this.$refs.videoEl.paused) this.playVideo();
                else this.pauseVideo();
            },
            playVideo () {
                //console.log('playVideo');
                if (!this.$refs.videoEl) return;
                this.videoIsPlaying = true;
                let playPromise = this.$refs.videoEl.play();
                this.playStartTime = Date.now();
                if (playPromise && playPromise.catch) {
                  // handle "DOMException: play() failed because the user didn't interact with the document"
                  playPromise.catch((e) => {
                    this.playStartTime = null;
                    console.log('play exception, skipping video ' + e);
                    if (String(e).indexOf('NotAllowedError') >= 0 && !this.viewerState.isScreensaverVideoPlaying) {
                      // in safari at times this is thrown, we can ignore and just show the first frame of video
                      // user can click to play it
                    } else {
                      this.videoIsPlaying = false;
                      if (this.viewerState.resourceIsOpeningVideo) this.videoEnded();  // trigger here also as we want to skip over failed intro videos
                    }
                  });
                }
            },
            pauseVideo () {
                if (!this.$refs.videoEl) return;
                //console.log('pauseVideo', this.$refs.videoEl.paused);
                this.videoIsPlaying = false;
                this.$refs.videoEl.pause();
                if (this.$refs.controlPanelEl) this.$refs.controlPanelEl.classList.add("showPanel");
                this.trackPlayTime();
            },
            mute (){
                if (!this.$refs.videoEl) return;
                this.$refs.videoEl.volume = 0;
                this.mutedVolume = this.volumeNow;
                this.volumeNow = 0;
            },
            unmute (){
                if (!this.$refs.videoEl) return;
                this.$refs.videoEl.volume = this.mutedVolume;
                this.volumeNow = this.mutedVolume;
            },

            videoUrlPopulate () {
                this.videoResUrl = null;
                //console.log('videoUrlPopulate', this.viewerState, this.resource.id)
                if (this.resource) {
                    ViewerEmbedHandler.getResourceUrlVideo(this.resource).then((url) => {
                        this.videoResUrl = url;
                    }, () => {});
                }
            },
            videoMetaDataLoaded () {
                //console.log('videoMetaDataLoaded')
                if (!this.$refs.videoEl) {
                    this.waitForRef = true;
                    return;
                }
                this.videoMetaDataIsLoaded = true;
                this.videoHeight = this.$refs.videoEl.clientHeight;
                this.videoWidth = this.$refs.videoEl.clientWidth;
                this.duration = this.$refs.videoEl.duration;
                this.timeNow = this.$refs.videoEl.currentTime;
                this.volumeNow = this.$refs.videoEl.volume;

                if (!this.pauseAllVideos) this.playVideo();
            },
            videoDataLoaded (){
                //console.log('videoDataLoaded');
                if (this.waitForRef && this.$refs.videoEl) {
                    this.waitForRef = false;
                    this.videoMetaDataLoaded();
                }
            },
            closeVideo() {
                this.$store.dispatch('vwBack');
            },
            videoEnded () {
                //console.log('got videoEnded');
                this.trackPlayTime();
                if (this.shouldVideoLoop) return;
                this.closeVideo();
            },
            videoError() {
                //console.log('got videoError', e);
                if (this.viewerState.resourceIsOpeningVideo) this.closeVideo();
            },
            updateTime () {
                this.timeNow = (this.$refs.videoEl) ? this.$refs.videoEl.currentTime : 0;
            },
            showTimeTooltip (e) {
                //console.log('showTime', e)
                let hoverTime = ((e.clientX - e.target.offsetLeft) / e.target.clientWidth * parseFloat(e.target.getAttribute('max'))).toFixed(2);
                let hoverMins = Math.floor(hoverTime / 60);
                let hoverSecs = Math.floor(hoverTime - hoverMins * 60);

                if(!hoverTime) return;
                let tooltip = this.$refs.tooltipEl;
                if (!tooltip) return;
                tooltip.style.top = '-' + (this.$refs.progressEl.offsetTop + 15) + "px";
                tooltip.style.left = (e.offsetX + 20) + "px";
                tooltip.innerHTML = ((hoverMins > 9) ? hoverMins : "0" + hoverMins) + ":" + ((hoverSecs > 9) ? hoverSecs : "0" + hoverSecs);

            },
            movementDetected () {
                if (this.videoIsPlaying && this.$refs.controlPanelEl) {
                    this.$refs.controlPanelEl.classList.add("showPanel");

                    if (this.controlPanelTimer) clearTimeout(this.controlPanelTimer);
                    this.controlPanelTimer = setTimeout(() => {
                        if (this.videoIsPlaying && this.$refs.controlPanelEl) this.$refs.controlPanelEl.classList.remove("showPanel");
                    }, 2000);
                }
            },
            trackPlayTime() {
              let href = window.location.href;
              if (this.playStartTime && !href.endsWith("/preview")) {
                let playTime = Date.now() - this.playStartTime;
                let workshopId = this.scData.presentation.workspace_id;
                let pageId = null;
                let sharedId = null;
                let durationSeconds = Math.round(playTime / 1000);

                //console.log("durationSeconds: ", durationSeconds);
                if (durationSeconds > 0) {
                  ScVueTrackEvents.trackVideoEvent(workshopId, this.resourceId, pageId, sharedId, durationSeconds);
                }
              }

              this.playStartTime = null;
            }
        },
        computed: {
            options() {
                return this.$store.state.vw.options;
            },
            viewerState() {
                return this.$store.state.vw.viewerState;
            },
            scData() {
                return this.$store.state.vw.scData;
            },
            resource() {
                return _.findWhere(this.scData.resource, {id: this.viewerState.resourceId});
            },
            zIndexVal() {
              return ((1000 * this.viewerState.backHistory.length) -1) + this.options.baseZindexOffset;
            },
            videoStyleObject() {
                return {
                    zIndex: this.zIndexVal,
                    visibility: this.videoMetaDataIsLoaded ? 'visible' : 'hidden',
                    width: '100%', height: 'auto', maxHeight: '100vh',
                }
            },
            shouldVideoLoop () {
                return !this.viewerState.resourceIsOpeningVideo && this.viewerState.resourceIsMovieLooping;
            },
            timeLeft () {
                let seconds = (this.showTimeRemaining) ? Math.floor(this.duration - this.timeNow) : Math.floor(this.timeNow);
                let minutes = 0;
                if (seconds > 60) {
                    minutes = Math.floor(seconds / 60);
                    seconds = Math.floor(((seconds / 60) - minutes) * 100);
                }
                let stringSec = (seconds > 9) ? seconds : '0' + seconds;
                let stringMin = (minutes > 0) ? ((minutes > 9) ? minutes : '0' + minutes) : '00';
                let sign = (this.showTimeRemaining) ? '-' : '';
                return sign + stringMin + ':' + stringSec;
            },
            progress: {
                get () {
                    return this.timeNow
                },
                set (val) {
                    if (this.$refs.videoEl) this.$refs.videoEl.currentTime = parseFloat(val);
                    return val;
                }
            },
            progressStyle () {
                if (!this.$refs.videoEl) return '';
                let videoEl = this.$refs.videoEl;
                let percent = (this.timeNow * 100) / this.duration;

                // get latest buffer end
                let bufferEnd = (videoEl && videoEl.buffered.length > 0) ? videoEl.buffered.end(videoEl.buffered.length-1) : 0;
                let bufferEndPercent = (bufferEnd > 0) ? (bufferEnd * 100) / this.duration : 0;

                if (bufferEnd < this.timeNow) {
                    return 'linear-gradient(to right, #008ab0 0%, #008ab0 20%, ' +
                        'rgba(255,255,255,.4) 45%, rgba(255,255,255,.4) 45%)';
                } else {
                    return 'linear-gradient(to right, #008ab0 0%, ' + '#008ab0 ' + percent + '%, ' +
                        'rgba(255,255,255,.6) ' + percent + '%, ' + 'rgba(255,255,255,.6) ' + bufferEndPercent + '%, ' +
                        'rgba(255,255,255,.4) ' + bufferEndPercent + '%, rgba(255,255,255,.4) 100%)';
                }
            },
            volume:{
                get () {
                    return this.volumeNow;
                },
                set (val) {
                    if (this.$refs.videoEl) this.$refs.videoEl.volume = parseFloat(val);
                    this.volumeNow = parseFloat(val);
                    return this.volumeNow;
                }
            },
            volRangeSlider () {
                let percent = this.volume * 100;
                return 'linear-gradient(to right, #008ab0 0%, #008ab0 ' + percent +'%, #404040 ' + percent + '%, #404040 100%)';
            },
            muted () {
                return !this.volumeNow > 0;
            },


        },
        watch: {
            pauseAllVideos () {
                //console.log('pauseAllVideos watch');
                if (!this.$refs.videoEl) return;
                if (this.pauseAllVideos && !this.$refs.videoEl.paused) this.pauseVideo();
                if (!this.pauseAllVideos && this.$refs.videoEl.paused) this.playVideo();
            },


        },

    }
</script>

<style scoped>

    .controlPanel, .panelShadow, .controls {
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    /* fade panel in and out */
    .controlPanel.showPanel {
        animation: fadeIn 500ms;
        animation-fill-mode: forwards;
    }
    @keyframes fadeIn {
        from { bottom: -50px; opacity: 0 }
        to { bottom: 0px; opacitiy: 1 }
    }
    .controlPanel {
        animation: fadeOut 500ms;
        animation-fill-mode: forwards;
    }
    @keyframes fadeOut {
        from { bottom: 0; opacity: 1}
        to { bottom: -50px; opacitiy: 0}
    }


    input[type="range"] {
        border: none;
        border-radius: 8px;
        height: 4px;

        outline: none;
        transition: background 450ms ease-in;

        -webkit-appearance: none;
    }
    input[type="range"]::-webkit-slider-thumb {
        background-color: #fff;
        border-radius: 0;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        -webkit-appearance: none;
    }

    button {
        min-width: 2.3rem;
        min-height: 2.3rem;
        font-size:1.2rem;
        padding: 1px 6px;
        border: none
    }
    button:hover {
        background: #008ab0;
    }
    button:active ,button:focus {
        box-shadow: none
    }


    .timeTooltip {
        position: absolute;
        display: none;
        background: #fff;
        opacity: 0;
        transition: 0.3s;
    }
    #timeTooltipContainer:hover .timeTooltip {
        display: inline;
        opacity: .8;
        transition:opacity 500ms;
    }
    .timeTooltip:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 5px;
        margin-left: -5px;
    }

</style>
