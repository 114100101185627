<template>
  <div>
    <slot name="shareContentIntroText" v-bind="newShareState"></slot>
      <div v-if="newShareState.shareStage === 'select-type'">
        <div class="mb-3">
          <label for="share-type" class="pb-1">Share via</label>
          <ScShareTypeChooser :shareType="newShareState.shareType"
                              @share-type-selected="$store.commit('newShareTypeSelected', $event)"
                              id="share-type"></ScShareTypeChooser>
        </div>
      </div>

      <slot name="default" v-bind="newShareState"></slot>

      <div v-if="newShareState.shareStage === 'select-type'">
        <div class="mb-3">
          <div v-if="!newShareState.presentationId" class="text-center p-3">
            <small class="text-muted">Select a presentation, then you'll be able to select files.</small>
          </div>
          <div v-if="newShareState.presentationId && scDataLoading">
            <div class="text-center p-4">
              <ScIcon name="spinnerFW" class="text-muted"/>
            </div>
          </div>
          <div v-if="newShareState.presentationId && scData && !scDataLoading">
            <ScSharedFilesChooser :scData="scData" :pageId="pageId" :resourceId="resourceId"
                                  :openAtOptions="openAtOptions"
                                  :selectedResourceIds="newShareState.selectedResourceIds"
                                  @selected-resource-ids-update="$store.commit('newShareSelectedResourceIdsUpdate', $event)"></ScSharedFilesChooser>
            <slot name="sharedFilesFootnote"></slot>
          </div>
        </div>

      </div>

      <div v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'edit'">
        <div class="mt-2">
          <label for="recipientLink" class="pb-1">Recipient(s)</label>
          <ScSelectEmailWithTagsAndSearch id="recipientLink" :defaultSelectedItems="newShareState.recipients"
                                          :items="recipientSuggestions"
                                          :disabled="(newShareState.recipients.length >= maxEmailRecipients)"
                                          :placeholder="(newShareState.recipients.length < maxEmailRecipients) ? 'Type emails here, comma to separate addresses' : 'Only ' + maxEmailRecipients + ' recipients allowed'"
                                          :maxItems="maxEmailRecipients"
                                          @update-selected-items="updateRecipients">
            <div class="d-flex justify-content-end mt-2">
              <small class="text-muted">
                <ScIcon name="questionCircle" />
                You can paste emails into this box separated by commas.  Max recipients 50.
              </small>
            </div>
          </ScSelectEmailWithTagsAndSearch>
        </div>
        <div class="mb-3">
          <label for="email-subject" class="pb-1">Subject <small class="text-muted">(optional)</small></label>
          <input type="text" id="email-subject" name="email-subject" class="form-control"
                 autocomplete="subject" @keyup.enter="$refs.emailDetailsNote.focus()"
                 :placeholder="defaultEmailSubject" maxlength="128"
                 :value="newShareState.subject" @input="$store.commit('newShareSubjectUpdate', $event.target.value)" />
        </div>
        <div class="my-3">
          <label for="email-details-note" class="pb-1">Note <small class="text-muted">(optional)</small></label>
          <textarea id="email-details-note" name="email-details-note" class="form-control"
                    :placeholder="defaultNote" style="min-height: 150px;" ref="emailDetailsNote"
                    :value="newShareState.note" @input="$store.commit('newShareNoteUpdate', $event.target.value)"></textarea>
          <div class="d-flex justify-content-end mt-2">
            <small class="text-muted"><ScIcon name="questionCircle" /> You can use <a
                href="https://support.showcaseworkshop.com/help/how-to-use-markdown"
                @click.prevent="openExternally('https://support.showcaseworkshop.com/help/how-to-use-markdown')">markdown</a> in the note
            </small>
          </div>
        </div>
      </div>

      <div v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'preview'">
        <div>
          <strong class="d-inline-block me-3">To</strong>
          <h5 v-for="(recipientEmail, idx) in newShareState.recipients" :key="'rc-'+idx"
              class="preview-recipient p-1 m-1 d-inline-block">
            <span class="badge bg-light text-dark border rounded p-2 px-3 text-wrap">{{recipientEmail}}</span>
          </h5>
        </div>
        <div class="mt-2 mb-3">
          <strong class="d-inline-block me-3">Subject</strong>
          <span>{{ defaultedEmailPreviewSubject }}</span>
        </div>
        <div class="px-1 py-3 p-sm-3">
          <iframe id="iframe-preview" class="shadow-lg me-auto ms-auto w-100 border-0 rounded bg-white"
                  style="height: 400px;"></iframe>
        </div>
      </div>

      <div v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'save'">
        <div class="mb-3">
          <p class="alert alert-success text-center">
            <strong>Great!</strong>
            Your email has been queued and will be sent during the next 15 minutes.
          </p>
        </div>
      </div>

      <div v-if="newShareState.shareType === 'link-vip-pass' && newShareState.shareStage === 'edit'">
        <div class="my-3">
          <label for="link-name" class="pb-1">VIP Pass name <small class="text-muted">(optional)</small></label>
          <input type="text" id="link-name" name="link-name" class="form-control"
                 autocomplete="link-name" placeholder="VIP Pass" @keyup.enter="$refs.linkNote.focus()"
                 :value="newShareState.subject" @input="$store.commit('newShareSubjectUpdate', $event.target.value)"
                 maxlength="128" />
        </div>
        <div class="mt-3">
          <label for="link-note" class="pb-1">Note <small class="text-muted">(optional)</small></label>
          <textarea id="link-note" name="link-note" class="form-control" :placeholder="defaultNote"
                    :value="newShareState.note" @input="$store.commit('newShareNoteUpdate', $event.target.value)"
                    style="min-height: 150px;" ref="linkNote"></textarea>
          <div class="d-flex justify-content-end mt-2">
            <small class="text-muted">
              <ScIcon name="questionCircle" /> You can use <a
                href="https://support.showcaseworkshop.com/help/how-to-use-markdown"
                @click.prevent="openExternally('https://support.showcaseworkshop.com/help/how-to-use-markdown')">markdown</a> in the note
            </small>
          </div>
        </div>
        <div class="mb-3">
          <label for="link-url-code" class="pb-1">URL Code <small class="text-muted">(optional, at least 6 letters and numbers)</small></label>
          <input type="text" id="link-url-code" name="link-url-code" class="form-control"
                 autocomplete="link-url-code" maxlength="128" :value="newShareState.linkUrlCode"
                 @input="$store.commit('newShareLinkUrlCodeUpdate', $event.target.value)" />
        </div>
      </div>

      <div v-if="newShareState.shareType === 'link-vip-pass' && newShareState.shareStage === 'preview'">
        <div class="mb-3">
          <div class="text-center">
            <h2>{{defaultedLinkPreviewSubject}}</h2>
            <small class="text-center text-break">
              <span>{{newShareState.linkPreviewUrl}}</span>
              <span class="mx-2 text-muted">(draft)</span>
            </small>
          </div>
          <div class="px-2 py-3">
            <SharingSummaryForRecipient
                class="shadow-lg mx-auto"
                :previewOnly="true"
                :workspaceId="workspaceId" :sharedId="null" :sharedType="newShareState.shareType"
                :note="newShareState.linkPreviewNote" :resources="selectedSharedResourcesList"
                :sharerEmail="newShareState.currentUserEmail"
                :sharerDisplayName="newShareState.currentUserDisplayName"
                :daysRemaining="newShareState.linkPreviewDaysRemaining"
                :headerImg="headerImg"
                :presentationId="scData.presentation.id"
                :presentationTitle="scData.presentation.title"
                :presentationThumbUrl="presentationThumbUrl"
                :closeBtnShown="false"
                :openAtOptions="openAtOptions"
            ></SharingSummaryForRecipient>
          </div>
        </div>
      </div>

      <div v-if="(newShareState.shareType === 'link-vip-pass'||newShareState.shareType === 'link-basic') && newShareState.shareStage === 'save'">
        <div class="my-3" id="link-details" action="#">
          <textarea id="share-link-copy" class="form-control d-block my-2"
                    readonly ref="share-link-input"
                    style="width:99%; min-height: 2rem;"
                    :value="newShareState.generatedLinkUrl"></textarea>
          <div class="d-flex justify-content-around">
            <button v-if="canOsShareText" @click.prevent="osShareTextIfPossible" class="btn btn-primary-orange fw-bold">
              <ScIcon name="externalLink" class="me-1"/> System Share</button>
            <button @click.prevent="copyShareLinkToClipboard()" class="btn btn-primary-orange fw-bold">
              <ScIcon name="copy" class="me-1"/> Copy</button>
          </div>
        </div>
        <div class="mt-2 text-muted"><small><ScIcon name="questionCircle" class="me-1"/>
          <span v-if="newShareState.generatedLinkShareHistoryName" class="me-1">Shown in Sharing History as
            "{{newShareState.generatedLinkShareHistoryName}}".</span>
          <span v-if="newShareState.generatedLinkExpiryDays" class="me-1">Link is valid for
            {{newShareState.generatedLinkExpiryDays}} days.</span>
        </small></div>
      </div>
  </div>
</template>

<script>

    import _ from 'underscore'; // findWhere
    import ScSelectEmailWithTagsAndSearch from '../common/ScSelectEmailWithTagsAndSearch.vue';
    import ScSharedFilesChooser from './ScSharedFilesChooser.vue';
    import ScShareTypeChooser from './ScShareTypeChooser.vue';
    import NewShareStore from './NewShareStore';
    import store from '../../store';
    import ScNotification from '../common/ScNotification.vue';
    import ScIcon from '../common/ScIcon.vue';
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import SharingSummaryForRecipient from './SharingSummaryForRecipient.vue';
    import ViewerUtil from '../viewer/ViewerUtil';
    import ScNativeExts from 'ScVueAliasScNativeExts';

    if (!store.state.newShare) store.registerModule('newShare', NewShareStore);

    export default {
      name: 'NewShareForm',
      emits: ['warn-before-leaving-update'],
      components: {ScSharedFilesChooser, ScShareTypeChooser,
            SharingSummaryForRecipient, ScSelectEmailWithTagsAndSearch, ScIcon},
      props: {
        workspaceId: {type: Number, default: null},
        headerImg: {type: String, default: null},
        currentUserDisplayName: {type: String, default: null},
        currentUserEmail: {type: String, default: null},
        presentationId: {type: Number, default: null},
        pageId: {type: Number, default: null},
        resourceId: {type: Number, default: null},
        adHocPagelist: {type: Object, default: null},
        scData: {type: Object, default: null},
        scDataLoading: {type: Boolean, default: false},
        emailPreviewEnabled: {type: Boolean, default: true}
      },
      data() {
        return {
          maxEmailRecipients: 50,
          recipientSuggestions: [],
          canOsShareText: false
        }
      },
      mounted() {
        //console.log('mounted');
        setTimeout(() => {
          this.canOsShareText = ViewerEmbedHandler.canSystemOpenWithForUrl &&
              ViewerEmbedHandler.canSystemOpenWithForUrl();
          this.setBaseData();
          this.setPresData();
        });
      },
      beforeUnmount () {
        this.$store.commit('newShareSetBaseData', { workspaceId: null, currentUserDisplayName: null,
          currentUserEmail: null, emailPreviewEnabled: null, openAtOptions: null});
        this.$store.commit('newSharePresentationUpdate', {presentationId: null, isPublished: true});
      },
      methods: {
        setBaseData() {
          this.$store.commit('newShareSetBaseData', { workspaceId: this.workspaceId,
            currentUserDisplayName: this.currentUserDisplayName, currentUserEmail: this.currentUserEmail,
            emailPreviewEnabled: this.emailPreviewEnabled,
            openAtOptions: this.openAtOptions
          });
        },
        setPresData() {
          this.$store.commit('newSharePresentationUpdate', { presentationId: this.presentationId,
            isPublished: this.scData && this.scData.presentation.publish_status !== "published"});
        },
        updateRecipients(recipients) {
          this.$store.commit('newShareRecipientsUpdate', recipients);
        },
        loadRecipientSuggestions () {
          //console.log('loadRecipientSuggestions');
          ViewerEmbedHandler.contactSuggestionsSearch({workspaceId: this.workspaceId}).then((suggestions) => {
            //console.log('loadRecipientSuggestions.contactSuggestionsSearch.then', suggestions);
            if (!suggestions) suggestions = [];
            this.recipientSuggestions.splice(0);
            this.recipientSuggestions.push(...suggestions);
          });
        },
        osShareTextIfPossible() {
          if (ViewerEmbedHandler.systemOpenWithForUrl) {
            ViewerEmbedHandler.systemOpenWithForUrl(this.newShareState.generatedLinkUrl,
                this.newShareState.generatedLinkTitle);
          }
        },
        copyShareLinkToClipboard() {
          this.$refs['share-link-input'].select();
          var successful = false;
          try {
            successful = document.execCommand('copy');
            //console.log(successful)
          } catch (err) {
            // ignore
          }
          if (successful) ScNotification.growlSuccessMsg('Copied to your clipboard');
          else ScNotification.growlErrMsg('Unable to copy');
        },
        openExternally(url) {
          ScNativeExts.openExternally(url, null);
        }
      },
      computed: {
        newShareState() {
          return this.$store.state.newShare;
        },
        selectedSharedResourcesList() {
          if (!this.scData) return [];
          let selectedSharedResources = [];
          for (let resId of this.newShareState.selectedResourceIds) {
            if (this.scData.resource[resId]) selectedSharedResources.push(this.scData.resource[resId]);
          }
          return selectedSharedResources;
        },
        defaultEmailSubject() {
          return this.newShareState.currentUserDisplayName + " shared files with you via Showcase Workshop";
        },
        defaultedEmailPreviewSubject() {
          if (this.newShareState.subject) return this.newShareState.subject;
          return this.defaultEmailSubject;
        },
        defaultedLinkPreviewSubject() {
          if (this.newShareState.linkPreviewSubject) return this.newShareState.linkPreviewSubject;
          return "VIP Pass"
        },
        defaultNote() {
          return 'The following has been shared with you:'
        },
        presentationThumbUrl() {
          if (!this.scData) return null;
          return ViewerUtil.getPresThumbnailUrl(this.scData);
        },
        shouldWarnBeforeLeaving() {
          return !!(this.newShareState && (this.newShareState.note || this.newShareState.subject ||
              this.newShareState.recipients.length > 0));
        },
        getShareStage () {
          return this.newShareState.shareStage
        },
        currentPageName() {
          if (!this.scData || !this.pageId) return 'A1';
          let page = _.findWhere(this.scData.page, {id: this.pageId});
          if (!page) return null;
          return page.alpha_num_name;
        },
        openAtOptions() {
          return {
            open_at_page_id: this.pageId,
            open_at_ad_hoc_pagelist_page_ids: this.adHocPagelist && this.adHocPagelist.adHocPageIds ?
                this.adHocPagelist.adHocPageIds : null,
            open_at_name: (this.adHocPagelist && this.adHocPagelist.adHocPageIds.length > 0) ?
                this.currentPageName + ' in custom slideshow' : this.currentPageName
          }
        }
      },
      watch: {
        workspaceId() {
          this.setBaseData();
        },
        scData() {
          this.setPresData();
        },
        presentationId() {
          this.setPresData();
        },
        shouldWarnBeforeLeaving() {
          this.$emit('warn-before-leaving-update', this.shouldWarnBeforeLeaving);
        },
        getShareStage (nv) {
          if (nv === 'edit' && this.newShareState.shareType === 'email' && this.recipientSuggestions.length === 0) {
            setTimeout(() => {
              this.loadRecipientSuggestions();
            }, 500);
          }
        }
      }
    }
</script>

<style scoped>
  #email-subject::placeholder {
    overflow: hidden;
  }
</style>