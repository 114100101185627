<template>
    <span>
        <span v-if="newShareState.shareStage === 'select-type'">
            <slot name="prevButtonSelectFiles"></slot>
        </span>
        <span v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'edit'">
            <button @click.prevent="$store.commit('newShareStageUpdate', 'select-type')" class="btn btn-link"
                    :disabled="newShareState.emailPreviewLoading" type="button">Back</button>
        </span>
        <span v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'preview'">
            <button @click.prevent="$store.commit('newShareStageUpdate', 'edit')" class="btn btn-link"
                    :disabled="newShareState.emailSaveLoading" type="button">Back</button>
        </span>
        <span v-if="newShareState.shareType === 'email' && newShareState.shareStage === 'save'">
            <button v-if="showShareAgain" @click="$store.commit('newShareStageUpdate', 'select-type')"
                    class="btn btn-link"  type="button">Share more</button>
        </span>
        <span v-if="newShareState.shareType === 'link-basic' && newShareState.shareStage === 'save'">
            <button v-if="showShareAgain" @click="$store.commit('newShareStageUpdate', 'select-type')"
                    class="btn btn-link" :disabled="newShareState.linkSaveLoading" type="button">Share more</button>
        </span>
        <span v-if="newShareState.shareType === 'link-vip-pass' && newShareState.shareStage === 'edit'">
            <button @click="$store.commit('newShareStageUpdate', 'select-type')"
                    :disabled="newShareState.linkPreviewLoading"
                    class="btn btn-link" type="button">Back</button>
        </span>
        <span v-if="newShareState.shareType === 'link-vip-pass' && newShareState.shareStage === 'preview'">
            <button @click="$store.commit('newShareStageUpdate', 'edit')" class="btn btn-link"
                    :disabled="newShareState.linkSaveLoading" type="button">Back</button>
        </span>
        <span v-if="newShareState.shareType === 'link-vip-pass' && newShareState.shareStage === 'save'">
            <button v-if="showShareAgain" @click="$store.commit('newShareStageUpdate', 'select-type')"
                    class="btn btn-link" type="button">Share more</button>
        </span>
    </span>
</template>

<script>
    export default {
        name: "NewSharePrevButton",
        props: {
            showShareAgain: {type: Boolean, default: true}
        },
        computed: {
            newShareState() {
                return this.$store.state.newShare;
            },
        },
    }
</script>
