<template>
    <a v-if="hasTarget" href="#" :title="targetTitle" @click.prevent="targetClick"
        style="outline: none;" draggable="false">
        <img v-if="clientRes" alt="Hotspot"
             :style="hotspotStyleObj" :id="'hotspot-' + hotspot.id" class="hotspot"
             :src="clientResUrl"/>
        <div v-else
             :style="hotspotStyleObj" :id="'hotspot-' + hotspot.id" class="hotspot"></div>
    </a>
    <span v-else>
        <img v-if="clientRes" alt="Hotspot"
             :style="hotspotStyleObj" :id="'hotspot-' + hotspot.id" class="hotspot"
             :src="clientResUrl"/>
        <div v-else
             :style="hotspotStyleObj" :id="'hotspot-' + hotspot.id" class="hotspot"></div>
    </span>
</template>

<script>

    import _ from 'underscore'; // findWhere, object, isNumber, isEmpty
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';

    export default {
        name: 'ViewerHotspot',
        props: {
            cleanZindex: {type: Number, default: 0},
            page: {type: Object, default: null},
            hotspot: {type: Object, default: null},
            topOffset: {type: Number, default: 0}
        },
        data() {
            return {
                clickEventDisabled: false,
                clickEventTimeout: null,
                backHistoryLengthAtMount: 0
            }
        },
        mounted() {
            this.backHistoryLengthAtMount = this.viewerState.backHistory.length;
        },
        beforeUnmount() {
            if (this.clickEventTimeout) clearTimeout(this.clickEventTimeout);
        },
        methods: {
            // loadHotspotTarget can take a little while so ignore repeated clicks for up to 1sec
            targetClick() {
                if (this.clickEventDisabled) return;  // prevent second click if it's too quick
                this.clickEventDisabled = true
                if (this.clickEventTimeout) clearTimeout(this.clickEventTimeout);
                this.clickEventTimeout = setTimeout(() => {
                    this.clickEventDisabled = false
                }, 1000);  // re-enable after 1 seconds

                this.$store.dispatch('vwLoadHotspotTarget', {hotspotId: this.hotspot.id});
            },
        },
        computed: {
            options() {
                return this.$store.state.vw.options;
            },
            viewerState() {
                return this.$store.state.vw.viewerState;
            },
            scData() {
                return this.$store.state.vw.scData;
            },
            clientRes() {
                let clientRes = null;
                if (this.scData && this.hotspot && this.hotspot.client_resource_id) {
                    clientRes = _.findWhere(this.scData.resource, {id: this.hotspot.client_resource_id});
                }
                return clientRes;
            },
            clientResUrl() {
                let url = null;
                if (this.clientRes) url = ViewerEmbedHandler.getResourceUrl(this.clientRes);
                return url;
            },
            hotspotStyleObj() {
                let scalePx = (d) => {
                    return (d * this.viewerState.presScaleFactor) + 'px';
                };
                let hsZindex = this.cleanZindex + (this.backHistoryLengthAtMount*1000) + this.options.baseZindexOffset;  // grab the zindex and check it's allowed
                let bounds = _.object(['x', 'y', 'width', 'height'], this.hotspot.bounds.split(','));
                return {
                    position: 'absolute',
                    width: scalePx(bounds.width), height: scalePx(bounds.height),
                    top: scalePx((bounds.y*1) + this.topOffset), left: scalePx(bounds.x),
                    zIndex: hsZindex
                };
            },
            hasTarget() {
                let hasTarget = false;
                if (this.hotspot) {
                    hasTarget = _.isNumber(this.hotspot.target_resource_id)
                        || _.isNumber(this.hotspot.target_pagelist_id)
                        || _.isNumber(this.hotspot.target_page_id);

                }
                return hasTarget;
            },
            targetTitle() {
                let targetTitle = null;
                if (this.hotspot && !_.isEmpty(this.hotspot.passcode)) {
                    targetTitle = 'Passcode protected';
                }
                return targetTitle;
            }
        }
    }

</script>