/**
 *
 *   Takes a showcase_data object and render it in the div supplied.
 *
 *   included: navigation, pages, pagelists, resources
 *
 *   not included: disclaimers, intro dialog etc
 */

import _ from "underscore"; // each, findWhere, sortBy, where


let ViewerUtil = {

    updateForAltVideoVersions: function(data) {
        if (data.updateForAltVideoVersionsRan) return;  // avoid being run twice
        data.updateForAltVideoVersionsRan = true;

        // update movies to be converted versions
        _.each(data.resource, function(r) {
            if (r.content_type === 'movie') {  // look for lower res version to play if there is one
                let rmd = _.findWhere(data.resourcemetadata, {resource_id: r.id, name: 'video_2048_url'});
                let rmdSize = _.findWhere(data.resourcemetadata, {resource_id: r.id, name: 'video_2048_size'});
                if ( ! rmd || ViewerUtil.isScreenSD()) {
                    rmd = _.findWhere(data.resourcemetadata, {resource_id: r.id, name: 'video_1024_url'});
                    rmdSize = _.findWhere(data.resourcemetadata, {resource_id: r.id, name: 'video_1024_size'});
                }
                if (rmd && rmdSize) { // if we found one override the resource
                    r.suffix = 'mp4';
                    r.url = rmd.value;
                    r.bytesize = Number(rmdSize.value);
                }
            }
        });
    },

    isScreenSD: function() {
        let dpRatio = 1;
        if (window.devicePixelRatio && window.devicePixelRatio > 1) dpRatio = window.devicePixelRatio;
        let ah = window.screen.availHeight * dpRatio;
        let aw = window.screen.availWidth * dpRatio;
        return ah < 1025 && aw < 1025;
    },

    getPresThumbnailUrl: function(data) {
        let rootPages = _.sortBy(_.where(data.page, {pagelist_id: data.presentationmetadata.root_pagelist_id}), 'sort_order');
        if (!rootPages || !rootPages[0] || !rootPages[0].id) return null;
        let page = data.page[String(rootPages[0].id)];
        if (!page || !page.resource_pagethumb_id) return null
        let thumbRes = data.resource[String(page.resource_pagethumb_id)];
        return thumbRes && thumbRes.thumb_url ? thumbRes.thumb_url : null;
    },

    guessPageIdForResource: function(data, resId, defaultPgId) {
        // when we open a res directly we don't know the parent page, attempt to find one (may be multiple)
        let hotspotsThatLinkToRes = _.where(data.hotspot, {target_resource_id: resId});
        //console.log('pagesThatLinkToRes', hotspotsThatLinkToRes);
        let guessedParentPgId = defaultPgId;
        if (hotspotsThatLinkToRes && hotspotsThatLinkToRes.length > 0) {
            guessedParentPgId = hotspotsThatLinkToRes[0].parent_page_id;
        }
        return guessedParentPgId;
    }

};


export default ViewerUtil;


