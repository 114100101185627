<template>
    <div>
        <form @submit.prevent="" autocomplete="off">
            <div v-if="!showAllPresId" class="d-flex justify-content-center">
                <div class="d-flex mb-3">
                    <input type="search" v-model="searchAllStr" class="form-control"
                                  maxlength="100" minlength="3"
                                  @keyup.enter.prevent="performSearch" :disabled="searchResultsLoading || !hasSearchableContent">

                    <button type="submit" class="btn btn-outline-secondary ms-2" @click.prevent="performSearch"
                            :disabled="searchPrefsLoading || searchResultsLoading || !hasSearchableContent"
                            aria-label="Search">
                        <span v-if="!searchResultsLoading && !searchPrefsLoading">Search</span>
                        <ScIcon v-else name="spinnerFW" class="mx-3 text-muted"/>
                    </button>
                    <button v-if="searchedStr && !searchResultsLoading" type="button" class="btn btn-link"
                            :disabled="searchResultsLoading" @click.prevent="clearSearch">Clear</button>
                </div>
            </div>
        </form>

        <div v-if="!hasSearchableContent" class="text-muted text-center mt-5">
            <em>No published presentations to search</em>
        </div>

        <div v-if="hasSearchableContent && !searchResultsLoading && !searchedStr && recentSearchesArr.length > 0"
             class="d-flex justify-content-center">
            <div style="width: 320px;" class="d-block sc-suggestions border-top p-3 mt-3">
                <button type="button" class="btn btn-link btn-sm float-end"
                        @click.prevent="clearRecent" v-sc-tooltip="'Clear recent searches'"><ScIcon name="trashAltFW" class="text-muted"/></button>
                <h5 class="mt-1 mb-3">Recent Searches</h5>
                <div v-for="(recentSearchText, idx) in recentSearchesArr" :key="'rc-'+idx" class="my-1">
                    <a href="#" @click.prevent="recentSearchPerform(recentSearchText)">{{recentSearchText}}</a>
                </div>
            </div>
        </div>

        <div v-if="!searchResultsLoading && searchedStr && presentationGroups.length === 0"
             class="text-muted text-center mt-5">
            <em v-if="noResultsMsg">{{noResultsMsg}}</em>
            <em v-else>No results</em>
        </div>

        <div v-if="showAllPresId" class="my-3">
            <button type="button" class="btn btn-link" @click.prevent="selectShowcase(null)">
                <ScIcon name="chevronLeftFW"/> Back</button>
        </div>

        <div v-if="presentationGroups.length > 0">
            <div v-for="pg in presentationGroups" :key="'p'+pg.presentation.id">

                <div v-if="(!showAllPresId || showAllPresId === pg.presentation.id)" class="mb-4 p-2 pb-5 sc-pres-group border-bottom">
                    <h4 v-if="pg && pg.presentation" class="mb-0">
                        {{pg.presentation.title}}
                        <button type="button" @click.prevent="$emit('pres-click', pg.presentation.id)"
                                class="btn btn-link ms-sm-4">View presentation</button>
                        <button v-if="!showAllPresId && pg.results.length > 5" @click.prevent="selectShowcase(pg.presentation.id)"
                                class="btn btn-link ms-sm-4" type="button">All results <ScIcon name="chevronRightFW" class="ms-1"/></button>
                    </h4>

                    <div v-if="pg && pg.results" class="d-flex flex-wrap">
                        <div v-for="(pageMatch, pIdx) in pg.results" :key="'srp-'+pIdx">
                            <ViewerSearchResultPage
                                    v-if="pageMatch && pageMatch.presentation && pageMatch.page && !pageMatch.resource && (pIdx < 5 || showAllPresId === pg.presentation.id)"
                                    :pageMatch="pageMatch"
                                    :thumbUrl="pageMatch.page.url_thumb"
                                    :thumbWidth="pageMatch.presentation.thumb_width"
                                    :thumbHeight="pageMatch.presentation.thumb_height"
                                    @page-click="$emit('page-click', pg.presentation.id, pageMatch.page.id)"></ViewerSearchResultPage>
                        </div>
                    </div>

                    <div v-if="pg && pg.results" class="d-flex flex-wrap">
                        <div v-for="(resMatch, rIdx) in pg.results" :key="'srr-'+rIdx">
                            <ViewerSearchResultResource
                                    v-if="resMatch && resMatch.resource && (rIdx < 5 || showAllPresId === pg.presentation.id)"
                                    :resMatch="resMatch"
                                    @res-click="$emit('res-click', pg.presentation.id, resMatch.resource.id)"></ViewerSearchResultResource>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    import _ from 'underscore'; // sortBy
    import $ from 'jquery';
    import ScNotification from '../common/ScNotification.vue';
    import ScIcon from '../common/ScIcon.vue';
    import ViewerEmbedHandler from 'ScVueAliasViewerEmbedHandler';
    import ViewerSearchResultPage from './ViewerSearchResultPage.vue';
    import ViewerSearchResultResource from './ViewerSearchResultResource.vue';
    import ViewerLikes from './ViewerLikes';

    let _state = {  // global state so results are preserved during nav
        showAllPresId: null,
        searchAllStr: null,
        lastWorkspaceId: null,
        searchedStr: null,
        lastSearchTimeMs: 0,
        searchResultsLoading: false,
        allResults: [],
        recentSearchesArr: [],
        hasSearchableContent: true,
        searchPrefsLoading: false,
    };

    export default {
        name: 'SearchAllComp',
        emits: ['res-click', 'pres-click', 'page-click'],
        components: {ViewerSearchResultPage, ViewerSearchResultResource, ScIcon},
        props: {
            workshopId: {type: Number, default: null},
            noResultsMsg: {type: String, default: null}
        },
        data () {
            return _state;
        },
        mounted () {
            let resetSearchAfterMs = new Date().getTime() - (5 * 60 * 1000);
            if (this.lastWorkspaceId !== this.workshopId || this.lastSearchTimeMs < resetSearchAfterMs) {
                this.clearSearch();
                this.fetchSearchPrefs();
            }
            this.lastWorkspaceId = this.workshopId;
            ViewerLikes.initLikes(this.workshopId);
        },

        methods: {
            selectShowcase(presentationId) {
                this.showAllPresId = presentationId;
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            },
            performSearch() {
                if (!this.searchAllStr || this.searchAllStr.length < 3) return;
                this.searchAtServer();
            },

            clearSearch() {
                this.searchAllStr = null;
                this.searchedStr = null;
                this.allResults.splice(0);
            },

            searchAtServer: function() {
                ScNotification.growlClear();
                if (!navigator.onLine) {
                    ScNotification.growlErrMsg("You need to be online to search.");
                    return;
                }
                this.allResults.splice(0);
                this.searchResultsLoading = true;
                $.ajax({
                    type: "GET",
                    url: ViewerEmbedHandler.ajaxWrapUrl("/main/search_all/ajax_all_pres_text_search"),
                    data: {
                        workspace_id: this.workshopId, search_term: this.searchAllStr
                    },
                    beforeSend: ViewerEmbedHandler.ajaxBeforeSend
                }).done((data) => {
                    this.searchedStr = this.searchAllStr;
                    this.lastSearchTimeMs = new Date().getTime();
                    this.saveRecentSearch();
                    if (data.search_results && data.search_results.length > 0) {
                        this.allResults.push(...data.search_results);
                    }
                }).always(() => {
                    this.searchResultsLoading = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, "performing search");
                });
            },

            fetchSearchPrefs: function() {
                if (!navigator.onLine) return;  // ignore
                this.searchPrefsLoading = true;
                $.ajax({
                    type: "GET",
                    url: ViewerEmbedHandler.ajaxWrapUrl("/main/search_all/ajax_get_search_prefs"),
                    data: { workspace_id: this.workshopId },
                    beforeSend: ViewerEmbedHandler.ajaxBeforeSend
                }).done((data) => {
                    if (data.search_prefs) {
                        this.recentSearchesArr.splice(0);
                        if (data.search_prefs.recent_searches && data.search_prefs.recent_searches.length > 0) {
                            let recentSearchesArr = JSON.parse(data.search_prefs.recent_searches)
                            this.recentSearchesArr.push(...recentSearchesArr);
                        }
                    }
                    this.hasSearchableContent = data.has_searchable_content;
                }).always(() => {
                    this.searchPrefsLoading = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, "loading search preferences");
                });
            },
            saveSearchPrefs: function() {
                if (!navigator.onLine) return;  // ignore
                let recentSearchesStr = JSON.stringify(this.recentSearchesArr);
                $.ajax({
                    type: "POST",
                    url: ViewerEmbedHandler.ajaxWrapUrl("/main/search_all/ajax_save_search_prefs"),
                    data: { workspace_id: this.workshopId, recent_searches: recentSearchesStr },
                    beforeSend: ViewerEmbedHandler.ajaxBeforeSend
                }).done(() => {
                    //
                }).always(() => {
                    //
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, "saving search preferences");
                });
            },
            clearRecent() {
                this.recentSearchesArr.splice(0);
                this.saveSearchPrefs();
            },
            saveRecentSearch() {
                if (this.recentSearchesArr.includes(this.searchedStr)) return;
                this.recentSearchesArr.unshift(this.searchAllStr);
                while (this.recentSearchesArr.length > 5) this.recentSearchesArr.pop();
                this.saveSearchPrefs();
            },
            recentSearchPerform(searchText) {
                this.searchAllStr = searchText;
                this.performSearch();
            },
        },
        computed: {
            presentationGroups() {
                let matchesByPresId = {};
                this.allResults.forEach((sr) => {
                    if (sr && sr.presentation) {
                        if (!matchesByPresId[String(sr.presentation.id)]) {
                            matchesByPresId[String(sr.presentation.id)] = {
                                results: [], presentation: sr.presentation, highest_score: 0};
                        }
                        let presGroup = matchesByPresId[String(sr.presentation.id)];
                        if (sr.doc_score > presGroup.highest_score) {  // find the highest score for any item for this presentation
                            presGroup.highest_score = sr.doc_score;
                        }
                        //console.log('sr', sr);
                        if (sr.page && !sr.resource) {
                            sr.pageIsLiked = ViewerLikes.isPageLiked(sr.presentation.id, sr.page.id);
                        }   else if (sr.resource) {
                            sr.resIsLiked = ViewerLikes.isResLiked(sr.presentation.id, sr.resource.id);
                        }
                        presGroup.results.push(sr);
                    }
                });
                let matchesArr = Object.values(matchesByPresId);
                return _.sortBy(matchesArr, 'highest_score').reverse();  // order by score descending
            }

        },
    }
</script>
