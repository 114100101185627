<template>
    <transition :name="transitionName" @after-enter="transitionDone" appear>
        <div :id="'resource-' + viewerState.resourceId" class="resource" ref="resRef"
             :style="{ width: '100%', height: '100%', overflow: 'hidden', left: 0, top: 0, position: 'absolute',
                       zIndex: (1000 * 200) + options.baseZindexOffset, backgroundColor: 'black' }">

            <ViewerResourceVideo v-if="showVideoPlayer"
                :pauseAllVideos="pauseAllVideos"></ViewerResourceVideo>

            <component v-if="resourceHandlerComponent"
                       :is="resourceHandlerComponent"></component>
        </div>
    </transition>
</template>


<script>

    import _ from 'underscore'; // findWhere
    import ViewerResourceHandler from "./ViewerResourceHandler";
    import ViewerResourceVideo from "./ViewerResourceVideo.vue";
    import { shallowRef } from "vue"


    export default {
        name: 'ViewerResource',
        components: {ViewerResourceVideo},
        props: {
            pauseAllVideos: {type: Boolean, default: null},
        },
        data() {
            return {
                resourceHandlerComponent: null
            }
        },
        mounted() {
            this.$resourceHandler = ViewerResourceHandler.getCurrentHandler(this.$store, this.options, this.viewerState,
                this.resource, this.scData);

            //console.log('resource handler', this.$resourceHandler, this.viewerState.resourceOpenedInternally);
            if (this.$resourceHandler && this.viewerState.resourceOpenedInternally) {
                if (this.$resourceHandler.resourceHandlerComponent) {
                  this.resourceHandlerComponent = shallowRef(this.$resourceHandler.resourceHandlerComponent);
                }
                this.$resourceHandler.displayResource(this.$refs.resRef);
            }

        },
        beforeUnmount() {
            if (this.$resourceHandler && this.$resourceHandler.closeResource) {
                this.$resourceHandler.closeResource();
            }
            this.resourceHandlerComponent = null;
            this.$resourceHandler = null;
        },
        methods: {
            transitionDone: function() {
                if (this.$resourceHandler && this.$resourceHandler.doneAnimation) {
                    this.$resourceHandler.doneAnimation(this.$refs.resRef, this.pauseAllVideos);
                }
            }
        },
        computed: {
            options() {
                return this.$store.state.vw.options;
            },
            viewerState() {
                return this.$store.state.vw.viewerState;
            },
            scData() {
                return this.$store.state.vw.scData;
            },
            transitionName() {
                // disable for IE11 it causes problems for videos
                let animationsDisabled = navigator.userAgent.indexOf('Trident/7') > -1;
                if (animationsDisabled || this.viewerState.resourceIsOpeningVideo) return 'sc-trans-none';
                // disable when off for the presentation
                if (!this.viewerState.transitionsEnabled) return 'sc-trans-none';
                return 'sc-trans-grow-shrink';
            },
            resource() {
                if (!this.scData) return null;
                return _.findWhere(this.scData.resource, {id: this.viewerState.resourceId});
            },
            showVideoPlayer() {
                return this.resource && this.resource.content_type === 'movie';
            },
        },
        watch: {
            pauseAllVideos() {
                if (!this.pauseAllVideos && this.scData && this.scData.presentationmetadata.opening_scene_resource_id
                        && this.$resourceHandler && this.$resourceHandler.doneAnimation) {
                    this.$resourceHandler.doneAnimation(this.$refs.resRef, this.pauseAllVideos);
                }
            }
        },

    }



</script>
