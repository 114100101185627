<template>
    <div class="row">
        <div class="col-12">
            <div class="list-group list-group-horizontal-sm">
                <a href="#" :class="['list-group-item list-group-item-action text-center', shareType === 'email' ? 'active':'']"
                        @click.prevent="selectShareType('email')">
                    <ScIcon name="envelope"/> Email
                </a>
                <a href="#" :class="['list-group-item list-group-item-action text-center', shareType === 'link-basic' ? 'active':'']"
                        @click.prevent="selectShareType('link-basic')">
                    <ScIcon name="link"/> Link
                </a>
                <a href="#" :class="['list-group-item list-group-item-action text-center', shareType === 'link-vip-pass' ? 'active':'']"
                        @click.prevent="selectShareType('link-vip-pass')">
                    <ScIcon name="idBadge"/> VIP Pass
                </a>
            </div>
        </div>
        <div class="col-12 mt-2">
            <div v-if="shareType === 'email'">
                <small class="text-muted"><ScIcon name="questionCircle" />
                    The recipient will be sent an email with a link to view your selected
                    shared content.</small>
            </div>
            <div v-if="shareType === 'link-basic'">
                <small class="text-muted"><ScIcon name="questionCircle" />
                    We'll generate a link that you can share by email, instant message etc.
                    You'll need to be online to use this feature.</small>
            </div>
            <div v-if="shareType === 'link-vip-pass'">
                <small class="text-muted"><ScIcon name="questionCircle" />
                    A link with customizable details that you can share by email, instant message etc.
                    You'll need to be online to use this feature.</small>
            </div>
        </div>
    </div>
</template>

<script>
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
      name: 'ScShareTypeChooser',
      emits: ['share-type-selected'],
      components: {ScIcon},
      props: {
        shareType: {type: String, default: 'email'},
      },
      methods: {
        selectShareType(type) {
          this.$emit('share-type-selected', type);
        }
      }
    }

</script>
