<template>
  <div>
    <div v-if="!showcaseShareRes && shareableResources.length === 0 && shareablePageResources.length === 0" class="my-3">
      <div><label class="pb-1">Files</label></div>
      <div class="text-center py-4 pb-5"><em class="small text-muted">Sorry! There are no shareable files in this particular presentation.
        Try a different presentation.</em></div>
    </div>
    <div v-else>
      <div v-if="currentlyViewingRes" class="my-3">
        <div><label class="pb-1">Current File</label></div>
        <div class="list-group">
          <a :key="'ssrc'+currentlyViewingRes.id"
             href="#" @click.prevent="toggleResourceSelected(currentlyViewingRes)"
             :class="['list-group-item', {active: isActiveShare(currentlyViewingRes) }]">
            <p class="list-group-item-text mb-0 d-flex">
              <img :src="resourceIconUrl(currentlyViewingRes)" alt="Thumbnail" style="width: 40px;height: 40px;" class="me-2" />
              <span class="text-break my-auto">{{currentlyViewingRes.name}}</span>
            </p>
          </a>
        </div>
      </div>

      <div v-if="showcaseShareRes" class="my-3">
        <div><label class="pb-1">Full presentation</label></div>
        <div class="list-group">
          <a :key="'ssr'+showcaseShareRes.id"
             href="#" @click.prevent="toggleResourceSelected(showcaseShareRes)"
             :class="['list-group-item', {active: isActiveShare(showcaseShareRes) }]">
            <div class="list-group-item-text mb-0 d-flex">
              <img :src="resourceIconUrl(showcaseShareRes)" alt="Thumbnail" style="width: 30px;height: 30px;"
                   class="my-auto ms-1 me-2 d-inline-block align-baseline" />
              <div class="d-inline-block">
                <span class="d-block">{{showcaseShareRes.name}} </span>
                <small class="d-block" v-if="openAtOptions && openAtOptions.open_at_name">
                  (starting at {{openAtOptions.open_at_name}})</small>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div v-if="shareablePageResources.length > 0" class="my-3">
        <div class="d-flex justify-content-between">
          <label class="pb-1">Files from {{ pageAlphaNumName }}</label>
          <button v-if="shareablePageResources.length > 1" @click.prevent="selectAllOnThisSlide"
                  class="pt-0 pe-0 pb-1 btn-link btn"><ScIcon name="checkSquareFW" class="text-muted"/></button>
        </div>
        <div class="list-group">
          <a v-for="r in shareablePageResources" :key="'spr'+r.id"
             href="#" @click.prevent="toggleResourceSelected(r)"
             :class="['list-group-item', { active: isActiveShare(r)}]">
            <p class="list-group-item-text mb-0 d-flex">
              <img :src="resourceIconUrl(r)" alt="Thumbnail" style="width: 40px;height: 40px;" class="me-2"/>
              <span class="text-break my-auto">{{ r.name }}</span></p>
          </a>
        </div>
      </div>

      <div v-if="shareableResources.length > 0" class="my-3">
        <div class="d-flex justify-content-between">
          <label class="pb-1">Files</label>
          <button @click.prevent="selectAllOnAllSlides" class="pt-0 pe-0 pb-1 btn-link btn">
            <ScIcon name="checkSquareFW" class="text-muted"/></button>
        </div>
        <div class="list-group">
          <a v-for="r in shareableResources" href="#" @click.prevent="toggleResourceSelected(r)"
             :data-id="r.id" :key="'sr'+r.id"
             :class="['list-group-item', { active: isActiveShare(r) }]">
            <p class="list-group-item-text mb-0 d-flex">
              <img :src="resourceIconUrl(r)" alt="Thumbnail" style="width: 40px;height: 40px;" class="me-2" />
              <span class="text-break my-auto">{{ r.name }}</span></p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import _ from 'underscore'; // isNumber, findWhere, without, close, sortBy
    import ViewerShareUtil from './ViewerShareUtil';
    import ScNativeExts from 'ScVueAliasScNativeExts';
    import ScIcon from '../../shared/common/ScIcon.vue';

    export default {
      name: 'ScSharedFilesChooser',
      emits: ['selected-resource-ids-update'],
      components: {ScIcon},
      props: {
        title: {type: String, default: null},
        selectedResourceIds: {type: Array, default: null},
        scData: {type: Object, default: null},
        pageId: {type: Number, default: null},
        resourceId: {type: Number, default: null},
        openAtOptions: {type: Object, default: null},
      },
      mounted() {
        setTimeout(() => this.selectDefaultResource());
      },
      methods: {
        selectDefaultResource() {
          //console.log('selectDefaultResource', this.selectedResourceIds);
          if (this.selectedResourceIds.length === 0) {
            if (this.currentlyViewingRes) {
              this.toggleResourceSelected(this.currentlyViewingRes);
            } else if (this.showcaseShareRes) {
              this.toggleResourceSelected(this.showcaseShareRes);
            } else if (this.shareablePageResources.length > 0) {
              this.toggleResourceSelected(this.shareablePageResources[0]);
            } else if (this.shareableResources.length > 0) {
              this.toggleResourceSelected(this.shareableResources[0]);
            }
          }
        },
        isActiveShare (item) {
          //console.log('isActiveShare', item, this.selectedResourceIds);
          return this.selectedResourceIds.includes(item.id);
        },
        toggleResourceSelected (item) {
          //console.log('toggleResourceSelected', item);
          this.toggleSelectAllForResources([item]);
        },
        selectAllOnThisSlide(){
          this.toggleSelectAllForResources(this.shareablePageResources);
        },
        selectAllOnAllSlides(){
          this.toggleSelectAllForResources(this.shareableResources);
        },
        toggleSelectAllForResources(resources) {
          let updatedSelectedResourceIds = _.clone(this.selectedResourceIds);
          let numSelected = 0;
          for (let res of resources) {
            if (this.isActiveShare(res)) numSelected ++;
          }
          let activeShouldBe = resources.length !== numSelected;
          for (let res of resources) {
            let activeCurrently = updatedSelectedResourceIds.includes(res.id);
            if (activeShouldBe && !activeCurrently) {
              updatedSelectedResourceIds.push(res.id);
            } else if (!activeShouldBe && activeCurrently) {
              updatedSelectedResourceIds = _.without(updatedSelectedResourceIds, res.id);
            }
          }
          this.$emit('selected-resource-ids-update', updatedSelectedResourceIds);
        },
        resourceIconUrl (item) {
          if (!this.displayGenericThumbnails && item.thumb_url) return item.thumb_url;
          if (!item) return null;
          let custom_suffix_map = {
            'docx': 'doc',
            'xlsx': 'xls',
            'rar': 'zip',
            'pptx': 'ppt',
            'html.zip': 'zip',
            'showcase': 'scw'
          };
          let shareIconImg = 'all';
          if (!item.suffix)
            shareIconImg = 'all';
          else if (item.suffix.toLowerCase() in custom_suffix_map)
            shareIconImg = custom_suffix_map[item.suffix.toLowerCase()];
          else if (['mov', 'mp4', 'm4v', 'avi', 'wmv', 'flv', 'mpg', 'mpeg', 'webm', 'f4v'].includes(item.suffix.toLowerCase()))
            shareIconImg = 'video';
          else if (!['pdf', 'doc', 'xls', 'zip', 'ppt'].includes(item.suffix.toLowerCase()))
            shareIconImg = 'all';
          return ScNativeExts.getStaticRootUrl() + '/showcase-icons/7.1.1/200x200_' + shareIconImg + '.png';
        },
      },
      computed: {
        shareablePageResources() {
          if (!this.scData || !this.pageId) return [];
          return ViewerShareUtil.getPageLevelShareableItems(this.scData, this.pageId, this.resourceId);
        },
        shareableResources() {
          if (!this.scData) return [];
          return ViewerShareUtil.getAllPagesShareableItems(this.scData, this.shareablePageResources, this.resourceId);
        },
        currentlyViewingRes() {
          return ViewerShareUtil.getCurrentResShareableRes(this.scData, this.pageId, this.resourceId);
        },
        showcaseShareRes() {
          return ViewerShareUtil.getShowcaseShareItem(this.scData);
        },
        pageAlphaNumName() {
          if (!this.scData || !this.pageId) return [];
          let page = _.findWhere(this.scData.page, {id: this.pageId});
          if (!page) return null;
          return page.alpha_num_name;
        },
        displayGenericThumbnails() {
          return !navigator.onLine;
        },
      },
      watch: {
        scData() {
          this.selectDefaultResource();
        }
      },
    }
</script>
