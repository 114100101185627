<template>
  <ScNormalPageLayout headingPart1="Present">
    <div class="container mt-4 mb-5">

      <div class="d-block mx-md-3 mx-1">
        <LabelSearchSortBar
                    :loadingPresentations="loadingPresentations"
                    :labels="sortedLabels"
                    :selectedLabelIds="selectedLabelIds"
                    :presentations="presentations"
                    :presListOrder="userPrefs.presentation_order_by"
                    :foundPresentations="searchedPresentationsByTextAndLabels.length !== 0"
                    @toggle-label="toggleLabel"
                    @set-sort-type="setSortType"
                    @update-search-val="updateSearchVal"
                    @refresh-showcases-list="refreshShowcases(true)" />

        <div v-if="loadingPresentations">
          <div class="text-center" style="margin-top: 20vh; margin-bottom: 20vh;">
            <ScIcon name="spinnerMedium" class="text-muted"/>
          </div>
        </div>

        <div v-if="!loadingPresentations" class="presentation-grid mx-auto"
             ref="portfolioColumns"
             @vnode-mounted="portfolioColsMounted"
             @vnode-updated="portfolioColsUpdated"
             @vnode-unmounted="portfolioColsUnmounted">

          <BrowserViewerPresentationListItem
                        v-for="pres in sortedPresentations" :key="pres.id"
                        :pres="pres"
                        :isSingleColumnLayout="isSingleColumnLayout"
                        @toggle-kiosk-mode-on-off="toggleKioskModeOnOff"
                        @toggle-intro-video-on-off="toggleIntroVideoOnOff">
          </BrowserViewerPresentationListItem>

        </div>

          <div v-if="presentations && presentations.length > 0 && searchedPresentationsByTextAndLabels.length === 0"
               style="min-height: 100vh;">
              <div class="col-md-5 text-center mt-5 ms-auto me-auto">
                  <em class="text-muted">No presentations</em>
                  <p class="mt-4 text-muted">
                      <ScIcon name="questionCircle" class="text-muted"/>
                      Try simplifying the search text, changing<br/>
                      the filter, or deselecting labels.
                  </p>
              </div>
          </div>

          <div v-if="!loadingPresentations && presentations.length === 0" class="text-center my-5" >
            <h1>Welcome to your Workshop</h1>
            <p>This is your personal space to view published presentations.</p>

            <div class="text-muted"><em>There are currently no published presentations</em></div>
          </div>
      </div>

      <div v-if="!loadingPresentations && presentations.length > 0" class="row text-muted my-4">
        <div v-if="mainMxUwCanPerform('a:list_pres_for_edit')"
             class="col text-center">
          <ScIcon name="questionCircle" /> Only published versions are shown in the viewer
        </div>
      </div>

      <div id="presentation-warning" class="clearfix text-center mt-1 mb-4">

        <div class="row justify-content-center">
          <div class="h5 mb-0 col-11 fw-normal text-muted">
            <p>Download the Showcase Workshop app for your phone, tablet, or computer to view presentations offline, anywhere.</p>
          </div>
        </div>

        <div class="device-app-buttons clearfix d-flex justify-content-center p-1">
          <span class="row g-0 justify-content-center" v-if="mainMxScLang === 'en-vg'">
            <!-- no apps for vge any longer -->
          </span>
          <span class="row g-0 justify-content-center" v-if="mainMxScLang === 'en-en'">
            <a href="https://apps.apple.com/app/showcase-workshop/id460799429" target="_blank"
               class="col-auto btn-market btn-apple mx-1 mb-2" role="button">
              <span class="btn-market-subtitle">Download on the</span>
              <span class="btn-market-title">App Store</span>
            </a>

<!--            <a href="https://play.google.com/store/apps/details?id=showcase.android" target="_blank"-->
<!--               class="col-auto btn-market btn-google mx-1 mb-2" role="button">-->
<!--              <span class="btn-market-subtitle">Download on</span>-->
<!--              <span class="btn-market-title">Google Play</span>-->
<!--            </a>-->

            <a href="https://showcaseworkshop.com/download/#showcase-xe" target="_blank"
               class="col-auto btn-market btn-windows mx-1 mb-2" role="button">
              <span class="btn-market-subtitle">Download for</span>
              <span class="btn-market-title">Windows</span>
            </a>

<!--            <a href="https://showcaseworkshop.com/download/#download-mac" target="_blank"-->
<!--               class="col-auto btn-market btn-apple mx-1 mb-2" role="button">-->
<!--              <span class="btn-market-subtitle">Download for</span>-->
<!--              <span class="btn-market-title">Mac</span>-->
<!--            </a>-->
          </span>
        </div>

      </div>
    </div>

    <!-- for child routes -->
    <router-view></router-view>

  </ScNormalPageLayout>
</template>

<script>

    import $ from 'jquery';
    import ScNormalPageLayout from '../global/ScNormalPageLayout.vue';
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import MainAppMixin from '../MainAppMixin';
    import BrowserViewerPresentationListItem from './BrowserViewerPresentationListItem.vue';
    import BrowserViewerEmbedHandler from './BrowserViewerEmbedHandler';
    import ScVueTrackEvents from '../global/ScVueTrackEvents';
    import LabelSearchSortBar from "../presentation/labels/LabelSearchSortBar.vue";
    import Masonry from 'masonry-layout';

    let DataManager = {
      fetchShowcases: function(vm, forceRefresh) {
        return new Promise((resolve, reject) => {
          if (vm.loadingPresentations)
            resolve();

          if (!forceRefresh && vm.loadedWsId === vm.mainMxCurrentWorkshopId) {
            resolve();
            return;
          }
          vm.presentations.splice(0);
          vm.labels.splice(0);
          vm.loadingPresentations = true;
          $.ajax({
            method: 'GET', url: '/api/v1/showcases_v2',
            data: {workspace_id: vm.mainMxCurrentWorkshopId}
          }).then((data) => {
            vm.loadedWsId = vm.mainMxCurrentWorkshopId;
            data.presentations.forEach((pres) => {
              vm.presentations.push(pres);
            });
            Object.values(data.labels).forEach((label) => {
              vm.labels.push(label);
            });
            resolve();

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading showcases');
            reject();

          }).always(() => {
            vm.loadingPresentations = false;
          });
        });
      },
    };




    export default {
      name: "BrowserViewerList",
      mixins: [ MainAppMixin ],
      components: { BrowserViewerPresentationListItem, ScNormalPageLayout, ScIcon, LabelSearchSortBar },
      data () {
        return {
          loadedWsId: null,
          presentations: [],
          labels: [],
          fetchedData: {},
          selectedLabelIds: [],
          loadingPresentations: false,

          searchVal: null,
          isSingleColumnLayout: false
        };
      },
      mounted () {
        //console.log('mounted');
        this.$store.commit('userViewerKioskModeToggle', false);  // always disable on load
        window.addEventListener('resize', this.windowResized);
        this.refreshShowcases();
      },
      beforeUnmount () {
        window.removeEventListener('resize', this.windowResized);
      },
      methods: {
        refreshShowcases (forceRefresh) {
          //console.log('refresh', forceRefresh)
          DataManager.fetchShowcases(this, forceRefresh);
          BrowserViewerEmbedHandler.recordScAnalyticsEvent('workshop_view', null, this.mainMxCurrentWorkshopId,
              this.$store.state.user.user_id);
        },
        setSortType (type) {
          this.$store.dispatch('userSavePresOrderPrefs', {sortOrder: type})
        },
        toggleLabel (labelId) {
          let labelIdx = this.selectedLabelIds.indexOf(labelId);
          if (labelIdx !== -1) {
            this.selectedLabelIds.splice(labelIdx, 1);
            ScVueTrackEvents.trackViewerEvent('list', 'label-select');
          }
          else
            this.selectedLabelIds.push(labelId);
        },
        toggleIntroVideoOnOff (presId) {
          if (!this.$store.state.user.viewer_intro_video_disabled.includes(presId))
            ScVueTrackEvents.trackViewerEvent('list', 'opening-video-off');
          else
            ScVueTrackEvents.trackViewerEvent('list', 'opening-video-on');

          this.$store.commit('userViewerIntroVideoToggle', presId)
        },
        toggleKioskModeOnOff () {
          if (!this.$store.state.user.viewer_kiosk_mode)
            ScVueTrackEvents.trackViewerEvent('list', 'kiosk-mode-on');
          else
            ScVueTrackEvents.trackViewerEvent('list', 'kiosk-mode-off');

          this.$store.commit('userViewerKioskModeToggle', !this.$store.state.user.viewer_kiosk_mode)
        },
        updateSearchVal(value) {
          this.searchVal = value;
        },
        windowResized() {
          let frameEleWidth = document.documentElement.clientWidth;
          this.isSingleColumnLayout = frameEleWidth < 500;
          //console.log('windowResized', this.isSingleColumnLayout);
        },
        portfolioColsMounted() {
          this.$msnry = new Masonry( this.$refs.portfolioColumns, {
            itemSelector: '.presentation-list-item', columnWidth: 220, gutter: 20, fitWidth: true,
            horizontalOrder: true,
          });
          this.windowResized();
        },
        portfolioColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        portfolioColsUnmounted() {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        }
      },
      computed: {
        sortedLabels () {
          return this.labels.slice().sort((a,b) => a.sort_key - b.sort_key);
        },
        userPrefs() {
          return this.$store.state.user;
        },
        searchedPresentationsByText () {
          //console.log('searchedPresentationsByText', this.searchVal);
          return this.presentations.filter((p) => {
            return !this.searchVal
                || p.title.toLocaleLowerCase().indexOf(this.searchVal.toLocaleLowerCase()) > -1;
          });
        },
        searchedPresentationsByTextAndLabels () {
          return this.searchedPresentationsByText.filter((pres) => {
            if (this.selectedLabelIds.length === 0) return true;
            let numLabelsWithPresId = 0;
            this.labels.forEach((label) => {
              if (this.selectedLabelIds.indexOf(label.id) === -1) return;
              if (label.presentation_ids.indexOf(pres.id) !== -1) {
                numLabelsWithPresId++;
              }
            });
            return numLabelsWithPresId === this.selectedLabelIds.length;
          });
        },
        sortedPresentations () {
          //console.log('sortedPresentations');
          return this.searchedPresentationsByTextAndLabels.slice(0).sort((a, b) => {
            if (this.userPrefs.presentation_order_by === 'name_asc' && a.title && b.title) {
              return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
            } else if (this.userPrefs.presentation_order_by === 'id_desc') {
              return a.id - b.id;
            } else {
              return b.id - a.id;
            }
          });
        }
      },
      watch: {
        $route () {
          this.refreshShowcases();
        },
      }
    }


</script>

<style>

  .device-app-buttons a {
    opacity: 0.6;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: opacity .25s ease;
  }
  .device-app-buttons a:hover {
    opacity: 1;
    filter: grayscale(0%);
    -webkit-filter: grayscale(0%);
  }
  .presentation-grid:after {
    content: '';
    display: block;
    clear: both;
  }

  @media only screen and (max-width: 499px) {
    .presentation-grid {
      width: 100% !important;
    }
  }

</style>